import React, { useContext } from "react"
import { Outlet, Route, Routes as DomRoutes } from "react-router-dom"
import User from "./models/User"
import UserContext from "./UserContext"
import { SetPassword } from "./user/SetPassword"
import LoginForm from "./LoginForm"
import routes from "./routes"
import Dashboard from "./Dashboard"
import ConnectorStatus from "./owner/server/ConnectorStatus"
import Queue from "./owner/server/Queue"
import TrafficOverview from "./admin/TrafficOverview"
import TrafficDetails from "./admin/TrafficDetails"
import TrafficPerConnector from "./admin/TrafficPerConnector"

import Connectors from "./owner/config/Connectors"
import ConnectorEdit from "./owner/config/ConnectorEdit"
import Plugins from "./owner/config/Plugins"
import PluginEdit from "./owner/config/PluginEdit"
import Satpools from "./owner/config/Satpools"
import SatpoolEdit from "./owner/config/SatpoolEdit"

import EmgUsers from "./admin/EmgUsers"
import EmgUserEdit from "./admin/EmgUserEdit"
import Customers from "./owner/Customers"
import CustomerEdit from "./owner/CustomerEdit"

import Users from "./admin/Users"
import UserEdit from "./admin/UserEdit"
import RouteEdit from "./owner/RouteEdit"
import Prices from "./owner/Prices"
import PriceEdit from "./owner/PriceEdit"
import { Phonebooks } from "./user/PhoneBooks"
import { PhoneBookEdit } from "./user/PhoneBookEdit"
import { PBEntryEdit } from "./user/PBEntryEdit"

import { SettingsView } from "./user/Settings"
import { SendSMS } from "./user/SendSMS"
import { SetEmgPassword } from "./user/SetEmgPassword"
import MailDomains from "./admin/MailDomains"
import MailDomainEdit from "./admin/MailDomainEdit"
import Licenses from "./admin/Licenses"
import LicenseEdit from "./admin/LicenseEdit"
import TrackingList from "./admin/TrackingList"
import MORoutes from "./owner/MO_Routes"
import MORouteEdit from "./owner/MO_RouteEdit"
import Routes from "./owner/Routes"
import Payments from "./owner/Payments"
import { HLRLookup } from "./user/HLRLookup"

interface ContentProps {
  setUser: (user: User) => void
}

const Content = (props: ContentProps) => {
  const user = useContext(UserContext)

  if (user.username.length === 0) {
    return (
      <section>
        <DomRoutes>
          <Route
            path={routes.site.root + routes.site.SetPassword}
            element={<SetPassword />}
          />
          <Route path={"*"} element={<LoginForm setUser={props.setUser} />} />
        </DomRoutes>
      </section>
    )
  }

  return (
    <section>
      <DomRoutes>
        <Route path={routes.root.path} element={<Dashboard />} />

        <Route path={"/owner"} element={<Outlet />}>
          <Route path={routes.owner.Customers} element={<Outlet />}>
            <Route path={""} element={<Customers />} />
            <Route path={":id"} element={<CustomerEdit />} />
          </Route>
          <Route path={routes.owner.Routes} element={<Outlet />}>
            <Route path={""} element={<Routes />} />
            <Route path={":id"} element={<RouteEdit />} />
          </Route>
          <Route path={routes.owner.Prices} element={<Outlet />}>
            <Route path={""} element={<Prices />} />
            <Route path={":id"} element={<PriceEdit />} />
          </Route>
          <Route path={routes.owner.MO_Routes} element={<Outlet />}>
            <Route path={""} element={<MORoutes />} />
            <Route path={":id"} element={<MORouteEdit />} />
          </Route>
          <Route path={routes.owner.Payments} element={<Outlet />}>
            <Route path={""} element={<Payments />} />
          </Route>
          <Route path={routes.owner.server.root} element={<Outlet />}>
            <Route
              path={routes.owner.server.ConnectorStatus}
              element={<ConnectorStatus />}
            />
            <Route path={routes.owner.server.Queue} element={<Queue />} />
          </Route>
          <Route path={routes.owner.config.root} element={<Outlet />}>
            <Route path={routes.owner.config.Connectors} element={<Outlet />}>
              <Route path={""} element={<Connectors />} />
              <Route path={":id"} element={<ConnectorEdit />} />
            </Route>
            <Route path={routes.owner.config.Plugins} element={<Outlet />}>
              <Route path={""} element={<Plugins />} />
              <Route path={":id"} element={<PluginEdit />} />
            </Route>
            <Route path={routes.owner.config.Satpools} element={<Outlet />}>
              <Route path={""} element={<Satpools />} />
              <Route path={":id"} element={<SatpoolEdit />} />
            </Route>
          </Route>
        </Route>

        <Route path={"/admin"} element={<Outlet />}>
          <Route path={routes.admin.TrackingList} element={<TrackingList />} />
          <Route
            path={routes.admin.TrafficOverview}
            element={<TrafficOverview />}
          />
          <Route
            path={routes.admin.TrafficDetails}
            element={<TrafficDetails />}
          />
          <Route
            path={routes.admin.TrafficPerConnector}
            element={<TrafficPerConnector />}
          />
          <Route path={routes.admin.Users} element={<Outlet />}>
            <Route path={""} element={<Users />} />
            <Route path={":id"} element={<UserEdit />} />
          </Route>
          <Route path={routes.admin.EmgUsers} element={<Outlet />}>
            <Route path={""} element={<EmgUsers />} />
            <Route path={":id"} element={<EmgUserEdit />} />
          </Route>
          <Route path={routes.admin.MailDomains} element={<Outlet />}>
            <Route path={""} element={<MailDomains />} />
            <Route path={":id"} element={<MailDomainEdit />} />
          </Route>
          <Route path={routes.admin.Licenses} element={<Outlet />}>
            <Route path={""} element={<Licenses />} />
            <Route path={":id"} element={<LicenseEdit />} />
          </Route>
        </Route>

        <Route path={"/user"} element={<Outlet />}>
          <Route path={routes.user.Phonebooks} element={<Phonebooks />} />
          <Route path={routes.user.PhonebookEdit} element={<PhoneBookEdit />} />
          <Route path={routes.user.PBEntryEdit} element={<PBEntryEdit />} />
          <Route path={routes.user.SendSMS} element={<SendSMS />} />
          <Route path={routes.user.HLRLookup} element={<HLRLookup />} />
          <Route path={routes.user.Settings} element={<SettingsView />} />
        </Route>

        <Route path={"/site"} element={<Outlet />}>
          <Route path={routes.site.SetPassword} element={<SetPassword />} />
          <Route
            path={routes.site.SetEmgPassword}
            element={<SetEmgPassword />}
          />
        </Route>
      </DomRoutes>
    </section>
  )
}

export default Content
