export class LicenseHost {
  id: number = 0
  hostid: string = ""
  valid_until2: Date = new Date()
  support_until2: Date = new Date()
  valid: boolean = false
}

export enum LicenseType {
  Perpetual = "Perpetual",
  RentToBuy = "Rent-To-Buy",
  PureRental = "Pure-Rental",
  OnTap = "On-Tap",
  // + something more floating for Docker?
}

export class ProductLicense {
  id: number = 0
  name: string = ""
  version: string = ""
  licno: string = ""
  mps: number = 0
  company: string = ""
  valid_until: Date = new Date()
  support_until: Date = new Date()
  valid: boolean = false
  valid_support: boolean = false
  license_type: LicenseType = LicenseType.RentToBuy
  note: string = ""

  customer_id: number = -1
  customer: any = null

  hosts: Array<LicenseHost> = []

  extension: number = -1
}
