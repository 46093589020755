import React from "react"
import SelectNamed from "./SelectNamed"
import { Connector } from "../models/Connector"

const SelectConnector = (props: {
  label: string
  sublabel?: string
  name: string
  list: Array<Connector>
  nullText?: string
  required?: boolean
}) => {
  return (
    <SelectNamed
      label={props.label}
      sublabel={props.sublabel}
      name={props.name}
      list={props.list.map((obj: { name: string }) => {
        return { text: obj.name }
      })}
      nullText={props.nullText}
      required={props.required}
    />
  )
}

export default SelectConnector
