import { EmgAccount } from "./EmgAccount"
import { ProductLicense } from "./product"

export class Customer {
  id: number = 0
  report_enabled: boolean = false
  name: string = ""
  address1: string = ""
  address2: string = ""
  zip: string = ""
  city: string = ""
  state: string = ""
  country: string = ""
  phone: string = ""
  fax: string = ""
  homepage: string = ""
  contact_admin: string = ""
  email_admin: string = ""
  contact_tech: string = ""
  email_tech: string = ""
  orgno: string = ""
  custno: string = ""
  email_report: string = ""

  accounts: Array<EmgAccount> = []
  licenses: Array<ProductLicense> = []
}
