import React from "react"
import { MailDomain } from "../models/MailDomain"
import { Link } from "react-router-dom"
import routes from "../routes"

interface LinkedMailDomainProps {
  maildomain: MailDomain
}

const LinkedMailDomain = (props: LinkedMailDomainProps) => {
  const maildomain = props.maildomain
  return (
    <Link
      to={routes.admin.root + routes.admin.MailDomains + "/" + maildomain.id}
    >
      {maildomain.domain}
    </Link>
  )
}

export default LinkedMailDomain
