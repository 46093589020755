import React, { useContext, useEffect, useState } from "react"
import { Route, RouteStatus } from "../models/Route"
import { Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import SelectNamed from "../helpers/SelectNamed"
import TextField from "../helpers/TextField"
import { useLocation } from "react-router-dom"
import SelectConnector from "../helpers/SelectConnector"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useConnectors,
  useEMGUsersWithCompany,
  useRouteCount,
  useRoutes,
} from "../helpers/hooks"
import AddNewLink from "../components/AddNewLink"
import LinkedRoute from "../components/LinkedRoute"

class RouteFields {
  emgaccount_id: number = 0
  connector: string = ""
  prefix: string = ""
  status: RouteStatus = RouteStatus.Status_Active
  limit?: number = undefined
}

const Routes = () => {
  const [params, setParams] = useState(new RouteFields())
  const {
    routes: route_configs,
    loading: routes_loading,
    deleter,
  } = useRoutes(params)
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany()
  const { count, loading: count_loading } = useRouteCount()
  const [filter, setFilter] = useState("")
  const { cs, loading: cs_loading } = useConnectors()
  const loading =
    routes_loading || accounts_loading || count_loading || cs_loading

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Routes" }])
  }, [context, location])

  const limitList = [{ text: "100", value: 100 }]
    .concat(count.data > 1000 ? [{ text: "1000", value: 1000 }] : [])
    .concat(count.data > 1000 ? [{ text: "4000", value: 4000 }] : [])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Routes</h1>
      <Formik
        initialValues={not_null(params) as RouteFields}
        onSubmit={(values, { setSubmitting }) => {
          ;(async () => {
            setSubmitting(false)
            setParams(values)
          })()
        }}
      >
        {() => (
          <Form id={"routes-form"}>
            <div className={"form"}>
              <fieldset>
                <legend>Selection</legend>
                <SelectEMGAccount
                  label={"Account"}
                  name={"emgaccount_id"}
                  accounts={accounts.data}
                  nullText={"Any"}
                />
                <SelectConnector
                  label={"Connector in"}
                  name={"connector"}
                  list={cs.data}
                  nullText={"Any connector"}
                />
                <TextField label={"Prefix"} name={"prefix"} />
                <SelectNamed
                  label={"Status"}
                  name={"status"}
                  list={[
                    { text: "Inactive", value: RouteStatus.Status_Inactive },
                    { text: "Active", value: RouteStatus.Status_Active },
                    { text: "Deleted", value: RouteStatus.Status_Deleted },
                  ]}
                  nullText={"Any"}
                />
                {count.data > 100 && (
                  <SelectNamed
                    label={"Max entries to display"}
                    name={"limit"}
                    list={limitList}
                    nullText={"All"}
                  />
                )}
              </fieldset>
            </div>

            <PrimaryButton text={"Search"} isSubmitting={undefined} />
          </Form>
        )}
      </Formik>
      {route_configs.isSuccess && (
        <>
          <p style={{ marginTop: "10px" }}>
            Displaying {route_configs.data.length || 0} route
            {route_configs.data.length === 1 ? "" : "s"}, of a total of{" "}
            {count.data}. <AddNewLink path={":id"} />
          </p>
          <p>
            Filter:{" "}
            <input
              type={"text"}
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value)
              }}
            />
          </p>
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                {/* <th>[checkbox]</th> */}
                <th>Id</th>
                <th>Status</th>
                <th>Name</th>
                <th>Connector in</th>
                <th>Account</th>
                <th>Prefix</th>
                <th>Priority</th>
                <th>Route</th>
                <th>Username</th>
                <th>Created</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {route_configs.data.length > 0 ? (
                route_configs.data
                  .filter((obj: Route) => {
                    if (filter.length === 0) return true
                    const values = JSON.stringify(
                      Object.values(obj)
                    ).toLowerCase()
                    return values.indexOf(filter.toLowerCase()) > -1
                  })
                  .map((obj: Route) => {
                    return (
                      <tr key={obj.id}>
                        <td>
                          <LinkedRoute route={obj} />
                        </td>
                        <td>{Route.statusName(obj)}</td>
                        <td>{obj.name}</td>
                        <td>{obj.connector || "*"}</td>
                        <td>{obj.account?.user.username || "*"}</td>
                        <td>{obj.prefix || "*"}</td>
                        <td>{obj.priority}</td>
                        <td>{obj.route}</td>
                        <td>{obj.route_emguser}</td>
                        <td>{obj.created}</td>
                        <td>
                          <img
                            src="/delete.png"
                            alt={"delete route " + obj.name}
                            onClick={() => deleter("route " + obj.name, obj.id)}
                          />
                        </td>
                      </tr>
                    )
                  })
              ) : (
                <tr>
                  <td colSpan={11}>No entries found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

export default Routes
