const routes = {
  root: { path: "/" },

  owner: {
    root: "/owner/",
    Customers: "customer",
    Routes: "route",
    Prices: "price",
    MO_Routes: "mo_route",
    Payments: "payment",
    server: {
      root: "server/",
      ConnectorStatus: "connectors",
      Queue: "queue/:connectorName",
    },
    config: {
      root: "config/",
      Connectors: "connectors",
      Plugins: "plugins",
      Satpools: "satpools",
    },
  },

  admin: {
    root: "/admin/",
    TrackingList: "tracking",
    TrafficOverview: "trafficOverview",
    TrafficDetails: "trafficDetails",
    TrafficPerConnector: "trafficPerConnector",
    Users: "user",
    EmgUsers: "emguser",
    MailDomains: "maildomains",
    Licenses: "licenses",
  },

  user: {
    root: "/user/",
    SendSMS: "sms",
    HLRLookup: "hlrlookup",
    Settings: "settings",
    Phonebooks: "phonebook",
    PhonebookEdit: "phonebook/:id",
    PBEntryEdit: "phonebook/:pbid/entry/:id",
  },

  site: {
    root: "/site/",
    SetPassword: "verify/:uuid",
    SetEmgPassword: "reset_emgpassword/:uuid",
  },
}

export default routes
