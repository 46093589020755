import React, { useContext, useEffect } from "react"
import { Plugin } from "../../models/Plugin"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../../BreadcrumbContext"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import { useConfiguredPlugins } from "../../helpers/hooks"
import AddNewLink from "../../components/AddNewLink"
import LinkedPlugin from "../../components/LinkedPlugin"

const Plugins = () => {
  const { plugins, loading, deleter } = useConfiguredPlugins()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Plugins" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Configured plugins</h1>
      <p>
        There {plugins.data.length === 1 ? "is" : "are"} {plugins.data.length}{" "}
        plugin
        {plugins.data.length === 1 ? "" : "s"} defined.{" "}
        <AddNewLink path={":id"} />
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Instances</th>
            <th>Library</th>
            <th>URL</th>
            <th>Config</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          {(plugins.data || []).map((obj: Plugin) => (
            <tr key={obj.id}>
              <td>
                <LinkedPlugin plugin={obj} />
              </td>
              <td>{obj.instances}</td>
              <td>{obj.library}</td>
              <td>{obj.url}</td>
              <td>{obj.config}</td>
              <td>
                <img
                  src="/delete.png"
                  alt={"delete plugin " + obj.name}
                  onClick={() => deleter("plugin " + obj.name, obj.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default Plugins
