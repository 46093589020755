import React, { useContext } from "react"
import User from "../User"
import UserContext from "../UserContext"
import { useNavigate } from "react-router-dom"

interface UserProps {
  setUser: (user: User) => void
}

const LogoutItem = (props: UserProps) => {
  const navigate = useNavigate()

  const handleSubmit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    console.log("logging out")
    // call the server maybe ...
    props.setUser(new User())
    navigate("/")
  }

  return (
    <a href="/" onClick={handleSubmit}>
      Logout
    </a>
  )
}

const UserLine = (props: UserProps) => {
  const user = useContext(UserContext)

  return (
    <div>
      <span>
        <LogoutItem setUser={props.setUser} />
      </span>
      <span style={{ marginLeft: "8px" }}>
        Logged in: {user.username}
        {user.role.length > 0 && <span> ({user.role})</span>}
      </span>
    </div>
  )
}

export default UserLine
