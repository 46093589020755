import React, { useContext, useEffect } from "react"
import axios from "axios"
import { Satpool } from "../../models/Satpool"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Form, Formik } from "formik"
import { not_null } from "../../helpers/emg"
import { useErrorHandler } from "react-error-boundary"
import routes from "../../routes"
import TextField from "../../helpers/TextField"
import BreadcrumbContext from "../../BreadcrumbContext"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import Checkbox from "../../helpers/Checkbox"
import PrimaryButton from "../../helpers/PrimaryButton"
import { useConfiguredSatpool } from "../../helpers/hooks"
import { useQueryClient } from "react-query"

const SatpoolEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const { satpool, loading } = useConfiguredSatpool(id!)
  const handleError = useErrorHandler()
  const cache = useQueryClient()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "SAT pools",
        path:
          routes.owner.root +
          routes.owner.config.root +
          routes.owner.config.Satpools,
      },
      { text: "SAT pool" },
    ])
  }, [context, location])

  if (loading) return <>{loading}</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/owner/config/satpool/" + satpool.data.id,
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      await cache.invalidateQueries("configuredSatpool")
    }
    setSubmitting(false)
    if (satpool.data.id < 1)
      navigate(
        routes.owner.root +
          routes.owner.config.root +
          routes.owner.config.Satpools
      )
  }

  return (
    <Formik
      initialValues={not_null(satpool.data) as Satpool}
      onSubmit={async (values, { setSubmitting }) => {
        return submitData(values, setSubmitting)
      }}
    >
      {({ isSubmitting }) => (
        <Form className={"form"}>
          <TextField label={"Name"} name={"name"} required={true} />
          <Checkbox label={"Disabled"} name={"disabled"} />
          <TextField
            label={"Expire"}
            name={"expire"}
            field_type={"number"}
            min_number={0}
          />
          <TextField label={"Address range"} name={"addressrange"} />
          <Checkbox label={"Threaded"} name={"threaded"} />
          <Checkbox label={"Quoted reply"} name={"quoted_reply"} />
          <Checkbox label={"Ignore destaddr"} name={"ignore_destaddr"} />
          <Checkbox label={"Random"} name={"random"} />
          <TextField label={"Note"} name={"Note"} tag={"textarea"} rows={5} />
          <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default SatpoolEdit
