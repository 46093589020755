import React, { useContext, useEffect, useState } from "react"
import { MO_Route } from "../models/MO_Route"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useMORoutes } from "../helpers/hooks"
import AddNewLink from "../components/AddNewLink"
import LinkedMORoute from "../components/LinkedMORoute"

const MORoutes = () => {
  const { mo_routes, loading, deleter } = useMORoutes()
  const [filter, setFilter] = useState("")

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "MO Routes" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>MO Routes</h1>
      <>
        <p style={{ marginTop: "10px" }}>
          Displaying {mo_routes.data.length || 0} route
          {mo_routes.data.length === 1 ? "" : "s"}. <AddNewLink path={":id"} />
        </p>
        <p>
          Filter:{" "}
          <input
            type={"text"}
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value)
            }}
          />
        </p>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Id</th>
              <th>Dest address</th>
              <th>Keyword</th>
              <th>Route</th>
              <th>Username</th>
              <th>Charge</th>
              <th>Cost</th>
              <th>Created</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {mo_routes.data.length > 0 ? (
              mo_routes.data
                .filter((obj: MO_Route) => {
                  if (filter.length === 0) return true
                  const values = JSON.stringify(
                    Object.values(obj)
                  ).toLowerCase()
                  return values.indexOf(filter.toLowerCase()) > -1
                })
                .map((obj: MO_Route) => {
                  return (
                    <tr key={obj.id}>
                      <td>
                        <LinkedMORoute route={obj} />
                      </td>
                      <td>{obj.destaddr}</td>
                      <td>{obj.keyword}</td>
                      <td>{obj.route}</td>
                      <td>{obj.route_emguser}</td>
                      <td>{obj.charge}</td>
                      <td>{obj.charge_cost}</td>
                      <td>{obj.created}</td>
                      <td>
                        <img
                          src="/delete.png"
                          alt={"delete MO route for " + obj.destaddr}
                          onClick={() =>
                            deleter("MO route for " + obj.destaddr, obj.id)
                          }
                        />
                      </td>
                    </tr>
                  )
                })
            ) : (
              <tr>
                <td colSpan={9}>No entries found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    </>
  )
}

export default MORoutes
