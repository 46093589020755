import React, { useState } from "react"
import axios from "axios"
import User from "./models/User"
import { Form, Formik } from "formik"
import TextField from "./helpers/TextField"
import { useNavigate } from "react-router-dom"

// https://postmarkapp.com/guides/password-reset-email-best-practices

interface LoginFormProps {
  setUser: (user: User) => void
}

interface LoginFields {
  username: string
  password: string
  // + "remember me" and whatnot
}

const LoginForm = (props: LoginFormProps) => {
  const [fields, setFields] = useState({ username: "", password: "" })
  const navigate = useNavigate()

  const login = async (values: LoginFields, setSubmitting: any) => {
    console.log("trying to log in " + JSON.stringify(values))
    try {
      setFields(values)
      const response = await axios.post("/api/site/login", {
        username: values.username,
        password: values.password,
      })
      console.log("response: " + JSON.stringify(response.data))
      setSubmitting(false) // must be done before setUser
      props.setUser(response.data)
      navigate("/")
    } catch (error) {
      setSubmitting(false)
      console.log("error: " + JSON.stringify(error))
    }
  }

  return (
    <div>
      <h1>Login</h1>
      <Formik
        initialValues={fields}
        onSubmit={(values, { setSubmitting }) => login(values, setSubmitting)}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <div className={"row"}>
              <p className="col note">
                Fields with <span className="required">*</span> are required.
              </p>
            </div>
            <TextField label={"Username"} name={"username"} required={true} />
            <TextField
              label={"Password"}
              name={"password"}
              required={true}
              field_type={"password"}
            />
            <div className="form-group row">
              <div className={"col-sm-3"} />
              <div className={"col-auto"}>
                <button
                  className={"btn btn-primary"}
                  type="submit"
                  value="Login"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm
