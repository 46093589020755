import { useMutation, useQueryClient } from "react-query"
import axios from "axios"

const useDeleteAfterConfirm = (path: string, cacheKey: string) => {
  const cache = useQueryClient()

  const { mutate: deleteThing } = useMutation(async (path: string) => {
    return axios.delete("/api/" + path)
  })

  return (thing: string, id: number) => {
    const rc = window.confirm("Are you sure you want to delete " + thing + "?")
    if (rc) {
      ;(async () => {
        await deleteThing(path + id)
        await cache.invalidateQueries(cacheKey)
      })()
    }
  }
}

export default useDeleteAfterConfirm
