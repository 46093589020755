import React from "react"
import { Link } from "react-router-dom"
import routes from "../routes"
import { Price } from "../models/Price"

interface LinkedPriceProps {
  price: Price
}

const LinkedPrice = (props: LinkedPriceProps) => {
  const price = props.price
  return (
    <Link to={routes.owner.root + routes.owner.Prices + "/" + price.id}>
      {price.id}
    </Link>
  )
}

export default LinkedPrice
