import React from "react"
import { Connector } from "../models/Connector"
import { Link } from "react-router-dom"
import routes from "../routes"

interface LinkedConnectorProps {
  connector: Connector
}

const LinkedConnector = (props: LinkedConnectorProps) => {
  const connector = props.connector
  return (
    <Link
      to={
        routes.owner.root +
        routes.owner.config.root +
        routes.owner.config.Connectors +
        "/" +
        connector.id
      }
    >
      {connector.name}
    </Link>
  )
}

export default LinkedConnector
