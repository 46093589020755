import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import User from "../models/User"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import SelectNamed from "../helpers/SelectNamed"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import Checkbox from "../helpers/Checkbox"
import PrimaryButton from "../helpers/PrimaryButton"
import { useEMGUsersWithCompany, useUser } from "../helpers/hooks"
import { useMutation, useQueryClient } from "react-query"
import UserContext from "../UserContext"
import routes from "../routes"

const UserEdit = () => {
  const appuser = useContext(UserContext)
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const role = appuser.role.toLowerCase()
  const { user, loading: user_loading } = useUser(role, id!)
  const handleError = useErrorHandler()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const cache = useQueryClient()
  const loading = user_loading || accounts_loading
  const [mailStatus, setMailStatus] = useState("")

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      { text: "Web users", path: routes.admin.root + routes.admin.Users },
      { text: "User" },
    ])
  }, [context, location])

  const { mutate: updateUser } = useMutation(async (values: object) => {
    await axios.post("/api/" + role + "/user/" + id, values)
    cache.setQueryData(["user", "/" + role + "/user/", id], values)
  })

  const { mutate: inviteUser } = useMutation(async () => {
    return axios.post(
      "/api/" +
        role +
        "/user/" +
        id +
        "/invite/" +
        btoa("" + window.location.protocol + "//" + window.location.host)
    )
  })

  if (loading) return <>{loading}</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updateUser(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      await cache.invalidateQueries("user")
      if (user.data.id < 1) navigate("..")
    }
  }

  const sendInvitation = async (values: Object, setSubmitting: any) => {
    console.log("send invitation to " + JSON.stringify(values))
    setMailStatus("Sending an invitation")
    try {
      await inviteUser()
      setMailStatus("Invitation sent")
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      setMailStatus("Invitation error: " + JSON.stringify(error))
      handleError(error)
    } finally {
      setSubmitting(false)
    }
    return cache.invalidateQueries("user")
  }

  const roles = (
    role === "owner" ? [{ text: "Owner", value: "OWNER" }] : []
  ).concat([
    { text: "Admin", value: "ADMIN" },
    { text: "User", value: "USER" },
  ])

  const initial_user = () => {
    const u = not_null(user.data) as User
    return role === "admin" ? { ...u, emgaccount_id: accounts.data[0].id } : u
  }

  const can_submit = (values: User) => {
    if (("" + values.username).length < 1) return false
    if (("" + values.name).length < 1) return false
    if (("" + values.email).length < 1) return false
    return true
  }

  return (
    <>
      <Formik
        initialValues={initial_user()}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={"form"}>
            <TextField label="Username" name="username" required={true} />
            <Checkbox label={"Disabled"} name={"disabled"} />
            <TextField label="Name" name="name" required={true} />
            <TextField
              label="Email"
              name="email"
              required={true}
              field_type="email"
            />
            <TextField label="Phone" name="phone" field_type="tel" />
            <SelectNamed label={"Role"} name={"role"} list={roles} />
            <Checkbox label={"Allow API calls"} name={"allow_api"} />
            <SelectEMGAccount
              label={"EMG Account"}
              name={"emgaccount_id"}
              accounts={accounts.data}
              nullText={role === "owner" ? "None selected" : undefined}
              required={true}
            />

            <PrimaryButton
              text={"Save"}
              isSubmitting={isSubmitting}
              disabled={!can_submit(values)}
            />
          </Form>
        )}
      </Formik>
      {id !== "0" && (
        <Formik
          initialValues={initial_user()}
          onSubmit={async (values, { setSubmitting }) => {
            return sendInvitation(values, setSubmitting)
          }}
        >
          {({ isSubmitting, values }) => (
            <>
              <Form className={"form"}>
                <div className={"row"}>
                  <div className={"col-sm-3"} />
                  <div className={"col-auto mt-2"}>
                    <PrimaryButton
                      text={"Send invitation email"}
                      isSubmitting={isSubmitting}
                      disabled={!can_submit(values)}
                    />
                  </div>
                  <div className={"col-auto"}>{mailStatus}</div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default UserEdit
