import React, { useContext, useEffect } from "react"
import axios from "axios"
import { Price, PriceStatus } from "../models/Price"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import SelectNamed from "../helpers/SelectNamed"
import SelectConnector from "../helpers/SelectConnector"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useConnectors,
  useEMGUsersWithCompany,
  usePrice,
} from "../helpers/hooks"
import UserContext from "../UserContext"

const PriceEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const { price, loading: price_loading } = usePrice(id!)
  const handleError = useErrorHandler()
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const { cs, loading: cs_loading } = useConnectors()
  const loading = price_loading || accounts_loading || cs_loading

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      { text: "Prices", path: routes.owner.root + routes.owner.Prices },
      { text: "Price" },
    ])
  }, [context, location])

  if (loading) return <>{loading}</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post("/api/owner/price/" + id, values)
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (id === "0") navigate("..")
    }
  }

  return (
    <Formik
      initialValues={not_null(price.data) as Price}
      onSubmit={async (values, { setSubmitting }) => {
        return submitData(values, setSubmitting)
      }}
    >
      {({ isSubmitting }) => (
        <Form className={"form"}>
          <SelectNamed
            label={"Status"}
            name={"status"}
            list={[
              { text: "Inactive", value: PriceStatus.Status_Inactive },
              { text: "Active", value: PriceStatus.Status_Active },
              { text: "Deleted", value: PriceStatus.Status_Deleted },
            ]}
          />
          <TextField label={"Name"} name={"name"} />
          <SelectConnector
            label={"Connector in"}
            name={"connector"}
            list={cs.data}
            nullText={"Any connector"}
          />
          <SelectConnector
            label={"Connector out"}
            name={"connector_out"}
            list={cs.data}
            nullText={"Any connector"}
          />
          <SelectEMGAccount
            label={"Account"}
            name={"emgaccount_id"}
            accounts={accounts.data}
            nullText={"Any"}
          />
          {/* * Prefix type: MSISDN (later: MCCMNC and whatnot) */}
          <TextField label={"Prefix (or *)"} name={"prefix"} required={true} />
          <TextField
            label={"Charge"}
            name={"charge"}
            field_type={"number"}
            step={"any"}
          />
          <TextField
            label={"Cost"}
            name={"charge_cost"}
            field_type={"number"}
            step={"any"}
          />

          <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default PriceEdit
