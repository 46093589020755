import { EmgAccount } from "./EmgAccount"

class User {
  id: number = 0
  username: string = ""
  password: string = ""
  role: string = ""
  name: string = ""
  disabled: boolean = false
  allow_api: boolean = false
  emgaccount_id: number = 0
  email: string = ""
  phone: string = ""
  account: EmgAccount = new EmgAccount()
}

export default User
