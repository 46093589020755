import { Crumb, CrumbState } from "../BreadcrumbContext"

export const updateBreadcrumbs = (
  context: CrumbState,
  location: { pathname: string },
  list: Array<Crumb>
) => {
  if (context.location !== location.pathname)
    context.setBreadcrumbs({
      location: location.pathname,
      list: list,
    })
}
