export class Satpool {
  id: number = 0
  name: string = ""
  disabled: boolean = false
  threaded: boolean = false
  quoted_reply: boolean = false
  ignore_destaddr: boolean = false
  random: boolean = false
  expire: number = 0
  addressrange: string = ""
  note: string = ""
}
