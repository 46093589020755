import React, { useContext, useState } from "react"
import store from "store"
import { BrowserRouter as Router, useNavigate } from "react-router-dom"
import { ErrorBoundary } from "react-error-boundary"
import Content from "./Content"
import MainMenu from "./MainMenu"
import User from "./User"
import BreadcrumbContext from "./BreadcrumbContext"
import { QueryCache, QueryClient, QueryClientProvider } from "react-query"
import UserContext from "./UserContext"

interface UserProps {
  setUser: (user: User) => void
}

const Header = (props: UserProps) => {
  const user = useContext(UserContext)

  return (
    <header>
      <div id="logo">
        <img src="/EMG-byBraxo-logo-RGB-raw-crop.png" alt="EMG" height="60" />
      </div>
      {user.username.length > 0 && <MainMenu setUser={props.setUser} />}
    </header>
  )
}

const Footer = () => {
  return (
    <footer>
      Copyright &copy; 2020-{new Date().getFullYear()} by{" "}
      <a href="https://braxo.se/en/services/" target="_top">
        Braxo AB
      </a>
      . All Rights Reserved. Version {process.env.REACT_APP_VERSION || "0.1.1"}.
    </footer>
  )
}

const useStateWithLocalStorage = (localStorageKey: string, defValue: any) => {
  const [value, setValue] = React.useState(() => {
    const storedValue = store.get(localStorageKey)
    if (storedValue) return storedValue
    return typeof defValue === "function" ? defValue() : defValue
  })

  React.useEffect(() => {
    store.set(localStorageKey, value)
  }, [value, localStorageKey])

  return [value, setValue]
}

const queryCache = new QueryCache({
  onSuccess: () => {
    //console.log(`Query cache success`)
  },
  onError: () => {
    //console.log(`Query cache error`)
  },
})

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {},
  },
})

const ErrorHandledApp = () => {
  const [user, setUser] = useStateWithLocalStorage(
    "currentUser",
    () => new User()
  )
  const navigate = useNavigate()

  const logout = () => {
    console.log("logging out")
    // call the server...
    setUser(new User())
    navigate("/")
  }

  const [breadcrumbs, setBreadcrumbs] = useState({
    location: "",
    list: [],
  })

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        console.log("Error: " + JSON.stringify(error?.message))
        return (
          <div>
            {error && (
              <span>
                Got exception: {error.message} <br />
                Error type: {error.name}
                <br />
              </span>
            )}
            <button type={"submit"} onClick={resetErrorBoundary}>
              Login again
            </button>
          </div>
        )
      }}
      onReset={logout}
    >
      <BreadcrumbContext.Provider
        value={{
          location: breadcrumbs.location,
          breadcrumbs: breadcrumbs.list,
          setBreadcrumbs,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider value={user}>
            <div className="container-fluid">
              <Header setUser={setUser} />
              <Content setUser={setUser} />
              <Footer />
            </div>
          </UserContext.Provider>
        </QueryClientProvider>
      </BreadcrumbContext.Provider>
    </ErrorBoundary>
  )
}

const App = () => {
  return (
    <Router>
      <ErrorHandledApp />
    </Router>
  )
}

export default App
