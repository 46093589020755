export class MO_Route {
  id: number = 0
  destaddr: string = ""
  keyword: string = ""
  route: string = ""
  route_emguser: string = ""
  charge: number = 0.0
  charge_cost: number = 0.0
  created: string = ""
}
