import React from "react"
import { Field } from "formik"

export default React.forwardRef(
  (
    props: {
      label: string
      sublabel?: string
      name: string
      tag?: string
      field_type?: string
      required?: boolean
      min_number?: number
      step?: string
      rows?: number
      disabled?: boolean
    },
    ref
  ) => {
    return (
      <div className={"form-group row"}>
        <label
          className={
            "col-form-label col-sm-3 " + (props.required ? "required" : "")
          }
        >
          {props.label}
          {props.required && <span className="required"> *</span>}
        </label>
        <Field
          className={"form-control form-control-sm col"}
          type={props.field_type || "text"}
          name={props.name}
          min={props.min_number}
          as={props.tag}
          rows={props.rows}
          step={props.step}
          disabled={props.disabled}
          innerRef={ref}
        />
        {props.sublabel && <small className={"col-12"}>{props.sublabel}</small>}
      </div>
    )
  }
)
