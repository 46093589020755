import React from "react"
import { Link } from "react-router-dom"
import routes from "../routes"
import { MO_Route } from "../models/MO_Route"

interface LinkedMORouteProps {
  route: MO_Route
}

const LinkedMORoute = (props: LinkedMORouteProps) => {
  const route = props.route
  return (
    <Link to={routes.owner.root + routes.owner.MO_Routes + "/" + route.id}>
      {route.id}
    </Link>
  )
}

export default LinkedMORoute
