import React, { useContext, useEffect } from "react"
import { Satpool } from "../../models/Satpool"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../../BreadcrumbContext"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import { useConfiguredSatpools } from "../../helpers/hooks"
import AddNewLink from "../../components/AddNewLink"
import LinkedSatpool from "../../components/LinkedSatpool"

const Satpools = () => {
  const { satpools, loading, deleter } = useConfiguredSatpools()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "SAT pools" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Configured satpools</h1>
      <p>
        There {satpools.data.length === 1 ? "is" : "are"} {satpools.data.length}{" "}
        satpool
        {satpools.data.length === 1 ? "" : "s"} defined.{" "}
        <AddNewLink path={":id"} />
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Address range</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          {(satpools.data || []).map((obj: Satpool) => (
            <tr key={obj.id}>
              <td>
                <LinkedSatpool satpool={obj} />
              </td>
              <td>{obj.addressrange}</td>
              <td>
                <img
                  src="/delete.png"
                  alt={"delete satpool " + obj.name}
                  onClick={() => deleter("satpool " + obj.name, obj.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default Satpools
