import React, { useContext, useEffect } from "react"
import { Customer } from "../models/Customer"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useCustomers } from "../helpers/hooks"
import AddNewLink from "../components/AddNewLink"
import LinkedCustomer from "../components/LinkedCustomer"
import UserContext from "../UserContext"

const Customers = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { customers, loading, deleter } = useCustomers(role)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Customers" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <div>
      <h1>Customers</h1>
      <p>
        There {customers.data.length === 1 ? "is" : "are"}{" "}
        {customers.data.length} customer
        {customers.data.length === 1 ? "" : "s"} defined.{" "}
        <AddNewLink path={":id"} />
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Country</th>
            <th>Customer id</th>
            <th>#Accounts</th>
            <th>#Licenses</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          {customers.data.length > 0 ? (
            customers.data.map((obj: Customer) => (
              <tr key={obj.id}>
                <td>
                  <LinkedCustomer customer={obj} />
                </td>
                <td>{obj.phone}</td>
                <td>{obj.country}</td>
                <td>{obj.custno}</td>
                <td>{obj.accounts.length}</td>
                <td>{obj.licenses.length}</td>
                <td>
                  <img
                    src="/delete.png"
                    alt={"delete customer " + obj.name}
                    onClick={() => deleter("customer " + obj.name, obj.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No entries found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Customers
