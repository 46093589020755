import React, { useContext, useEffect } from "react"
import axios from "axios"
import { Route, RouteStatus } from "../models/Route"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import SelectNamed from "../helpers/SelectNamed"
import SelectConnector from "../helpers/SelectConnector"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useConnectors,
  useEMGUsersWithCompany,
  useRoute,
} from "../helpers/hooks"
import UserContext from "../UserContext"

const RouteEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const { route, loading: route_loading } = useRoute(id!)
  const handleError = useErrorHandler()
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const { cs, loading: cs_loading } = useConnectors()
  const loading = route_loading || accounts_loading || cs_loading

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post("/api/owner/route/" + id, values)
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (id === "0") navigate("..")
    }
  }

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      { text: "Routes", path: routes.owner.root + routes.owner.Routes },
      { text: "Route" },
    ])
  }, [context, location])

  return loading ? (
    <>{loading}</>
  ) : (
    <Formik
      initialValues={not_null(route.data) as Route}
      onSubmit={async (values, { setSubmitting }) => {
        return submitData(values, setSubmitting)
      }}
    >
      {({ isSubmitting }) => (
        <Form className={"form"}>
          <SelectNamed
            label={"Status"}
            name={"status"}
            list={[
              { text: "Inactive", value: RouteStatus.Status_Inactive },
              { text: "Active", value: RouteStatus.Status_Active },
              { text: "Deleted", value: RouteStatus.Status_Deleted },
            ]}
          />
          <TextField label={"Name"} name={"name"} />
          <SelectConnector
            label={"Connector in"}
            name={"connector"}
            list={cs.data}
            nullText={"Any connector"}
          />
          <SelectEMGAccount
            label={"Account"}
            name={"emgaccount_id"}
            accounts={accounts.data}
            nullText={"Any"}
          />
          {/* * Prefix type: MSISDN (later: MCCMNC and whatnot) */}
          <TextField label={"Prefix (or *)"} name={"prefix"} required={true} />
          <SelectNamed
            label={"Priority"}
            name={"priority"}
            list={[
              { text: "1 - lowest", value: 1 },
              { text: "2", value: 2 },
              { text: "3", value: 3 },
              { text: "4", value: 4 },
              { text: "5 - highest", value: 5 },
            ]}
          />
          <SelectConnector
            label={"Route / Connector out"}
            name={"route"}
            list={cs.data}
            nullText={"Not set"}
            required={true}
          />
          <SelectEMGAccount
            label={"Receiving user"}
            name={"route_emguser"}
            accounts={accounts.data}
            nullText={"Not set"}
            by_name={true}
          />
          <TextField
            label={"Regular expression for address validation"}
            name={"valid_destaddr_regexp"}
          />

          <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default RouteEdit
