import React, { useContext, useEffect, useState } from "react"
import { Field, Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import BreadcrumbContext from "../BreadcrumbContext"
import { useLocation } from "react-router-dom"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import PrimaryButton from "../helpers/PrimaryButton"
import { HourlySummary } from "../models/HourlySummary"
import {
  useConnectors,
  useEMGUsersWithCompany,
  useTrafficPerConnector,
} from "../helpers/hooks"
import SelectNamed from "../helpers/SelectNamed"
import { useQueryClient } from "react-query"
import SelectConnector from "../helpers/SelectConnector"
import MessageCountSums from "../helpers/MessageCountSums"
import { numberFormatter, percentFormatter } from "../helpers/formatters"
import UserContext from "../UserContext"

enum SF_IntervalType {
  Period = "Period",
  Interval = "Interval",
}

enum SF_Interval {
  Today = "Today",
  ThisMonth = "ThisMonth",
  LastMonth = "LastMonth",
  ThisYear = "ThisYear",
  LastYear = "LastYear",
}

interface SelectionFields {
  username: string
  connector: string
  interval_type: SF_IntervalType
  interval: SF_Interval
  fromdate: string
  todate: string
}

const TrafficPerConnector = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const { cs: connectors, loading: cs_loading } = useConnectors()
  const loading = accounts_loading || cs_loading

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [params, setParams] = useState({
    username: "",
    connector: "",
    interval_type: SF_IntervalType.Period,
    interval: SF_Interval.ThisMonth,
    fromdate: new Date().toISOString().substring(0, 10),
    todate: tomorrow.toISOString().substring(0, 10),
  })
  const entries = useTrafficPerConnector(params)
  const cache = useQueryClient()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Traffic per connector" }])
  }, [context, location])

  if (loading) return <>{loading}</>

  return (
    <>
      <h1>Traffic per connector</h1>
      <Formik
        initialValues={not_null(params) as SelectionFields}
        onSubmit={async (values, { setSubmitting }) => {
          await cache.invalidateQueries("trafficPerConnector")
          setSubmitting(false)
          setParams(values)
        }}
      >
        {({ values, isSubmitting }) => (
          <div className={"card"}>
            <div className={"card-header text-center"}>
              <legend>Selection</legend>
            </div>
            <div className={"card-body"}>
              <Form className={"form"}>
                {accounts.isSuccess && (
                  <SelectEMGAccount
                    label={"Account"}
                    name={"username"}
                    accounts={accounts.data}
                    nullText={"Any"}
                    by_name={true}
                  />
                )}

                {connectors.isSuccess && (
                  <SelectConnector
                    label={"Connector"}
                    name={"connector"}
                    list={connectors.data}
                    nullText={"Any"}
                  />
                )}

                <div className={"form-check-inline ml-2 col-12"}>
                  <div className={"col-3"}>
                    <Field
                      name="interval_type"
                      type="radio"
                      value={SF_IntervalType.Period}
                      className={"form-check-input position-static"}
                    />
                    <label className={"col-form-label"}>Period</label>
                  </div>
                  <SelectNamed
                    label={""}
                    name={"interval"}
                    disabled={values.interval_type !== SF_IntervalType.Period}
                    list={[
                      { value: SF_Interval.Today, text: "Today" },
                      { value: SF_Interval.ThisMonth, text: "ThisMonth" },
                      { value: SF_Interval.LastMonth, text: "LastMonth" },
                      { value: SF_Interval.ThisYear, text: "ThisYear" },
                      { value: SF_Interval.LastYear, text: "LastYear" },
                    ]}
                  />
                </div>

                <div className={"form-check-inline ml-2 col-12"}>
                  <div className={"col-3"}>
                    <Field
                      name="interval_type"
                      type="radio"
                      value={SF_IntervalType.Interval}
                      className={"form-check-input position-static"}
                    />
                    <label className={"col-form-label"}>Range</label>
                  </div>
                  <Field
                    name={"fromdate"}
                    type="date"
                    placeholder={"from"}
                    disabled={values.interval_type !== SF_IntervalType.Interval}
                    className={"form-control col-auto mr-1"}
                  />
                  &ndash;
                  <Field
                    name={"todate"}
                    type="date"
                    placeholder={"to"}
                    disabled={values.interval_type !== SF_IntervalType.Interval}
                    className={"form-control col-auto ml-1 mb-2"}
                  />
                </div>

                <div className={"mt-3 col-12"}>
                  <PrimaryButton text={"Update"} isSubmitting={isSubmitting} />
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>

      <section className={"pt-2"}>
        <table className={"table table-sm table-responsive table-bordered"}>
          <thead>
            <tr>
              <th rowSpan={2}>Connector</th>
              <th rowSpan={2}>Account</th>
              <th colSpan={7} className={"text-center"}>
                Message counts
              </th>
              <th rowSpan={2} colSpan={2}>
                Total
                <br />
                charge
              </th>
            </tr>
            <tr>
              <th colSpan={2} className={"text-center table-success"}>
                Ok
              </th>
              <th colSpan={2} className={"text-center table-warning"}>
                Relayed
              </th>
              <th colSpan={2} className={"text-center table-danger"}>
                Failed
              </th>
              <th>Total</th>
            </tr>
          </thead>
          {entries.data && entries.data.length > 0 ? (
            <>
              <tbody>
                {entries.data.map((entry: HourlySummary, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{entry.connector_out}</td>
                      <td>{entry.username}</td>
                      <td className={"text-right table-success"}>
                        {numberFormatter.format(entry.msg_count_ok)}
                      </td>
                      <td className={"text-right table-success"}>
                        {percentFormatter.format(HourlySummary.pc_ok(entry))}
                      </td>
                      <td className={"text-right table-warning"}>
                        {numberFormatter.format(entry.msg_count_relayed)}
                      </td>
                      <td className={"text-right table-warning"}>
                        {percentFormatter.format(
                          HourlySummary.pc_relayed(entry)
                        )}
                      </td>
                      <td className={"text-right table-danger"}>
                        {numberFormatter.format(
                          HourlySummary.msg_count_failed(entry)
                        )}
                      </td>
                      <td className={"text-right table-danger"}>
                        {percentFormatter.format(
                          HourlySummary.pc_failed(entry)
                        )}
                      </td>
                      <td className={"text-right"}>{entry.msg_count}</td>
                      <td className={"text-right"}>{entry.charge || 0}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2} className={"text-center"}>
                    <strong>Total:</strong>
                  </td>
                  <MessageCountSums entries={entries.data} />
                </tr>
              </tfoot>
            </>
          ) : (
            <tbody>
              <tr>
                <td colSpan={12} className={"text-center"}>
                  No entries found
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </section>
    </>
  )
}

export default TrafficPerConnector
