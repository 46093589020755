import React, { useContext, useEffect } from "react"
import User from "../models/User"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useUsers } from "../helpers/hooks"
import UserContext from "../UserContext"
import LinkedCustomer from "../components/LinkedCustomer"
import LinkedEMGUser from "../components/LinkedEMGUser"
import AddNewLink from "../components/AddNewLink"
import LinkedUser from "../components/LinkedUser"

const Users = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { users, loading, deleter } = useUsers(role)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Web users" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Web users</h1>
      <p>
        There {users.data.length === 1 ? "is" : "are"} {users.data.length} user
        {users.data.length === 1 ? "" : "s"} defined.{" "}
        <AddNewLink path={":id"} />
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Username</th>
            <th>Name</th>
            <th>Role</th>
            {role === "owner" && <th>Customer</th>}
            <th>EMG Account</th>
            {/* <th></th> Impersonate */}
            {role === "owner" && <th>Delete</th>}
          </tr>
        </thead>

        <tbody>
          {users.data.length > 0 ? (
            users.data.map((obj: User) => {
              const account = obj.account
              const user = account?.user
              return (
                <tr key={obj.id}>
                  <td>
                    <LinkedUser user={obj} />
                  </td>
                  <td>{obj.name}</td>
                  <td>{obj.role}</td>
                  {role === "owner" && (
                    <td>
                      <LinkedCustomer
                        customer={account?.customer}
                        emptyText={" "}
                      />
                    </td>
                  )}
                  <td>
                    <LinkedEMGUser user={user} />
                  </td>
                  {/* <td>(impersonate)</td> */}
                  {role === "owner" && (
                    <td>
                      <img
                        src="/delete.png"
                        alt={"delete user " + obj.name}
                        onClick={() => deleter("user " + obj.name, obj.id)}
                      />
                    </td>
                  )}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>No entries found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default Users
