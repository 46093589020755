import { EmgAccount } from "./EmgAccount"

export class EmgUserAccess {
  connector: string = ""
  ipstr: string = ""
  ipwidth: number = 0
}

export class BalanceUpdate {
  id: number = 0
  created_at: Date = new Date()
  amount: number = 0

  static createdDesc(a: BalanceUpdate, b: BalanceUpdate) {
    if (a.created_at > b.created_at) {
      return -1
    } else if (a.created_at < b.created_at) {
      return 1
    }
    return 0
  }
}

export class EmgUser {
  userid: number = 0
  username: string = ""
  password: string = ""
  email: string = ""
  allowpostpaid: boolean = false
  charge_balance: number = 0
  charge_balance_postpaid_limit: string = ""
  charges: string = ""
  forcesourceaddr: string = ""
  throughput: string = ""
  maxsessions: string = ""
  routedlr: string = ""
  routesat: string = ""
  satpoolcreate: string = ""
  usergroup: string = "USER"
  md5password: string = ""
  mode: string = ""
  cert_fingerprint: string = ""
  min_vp: number = 0
  max_vp: string = ""
  lastlogin?: Date = undefined
  extra1?: string = undefined
  extra1label: string = "Extra 1"
  extra2?: string = undefined
  extra2label: string = "Extra 2"
  extra3?: string = undefined
  extra3label: string = "Extra 3"
  extra4?: string = undefined
  extra4label: string = "Extra 4"
  extra5?: string = undefined
  extra5label: string = "Extra 5"
  extra6?: string = undefined
  extra6label: string = "Extra 6"
  extra7?: string = undefined
  extra7label: string = "Extra 7"
  extra8?: string = undefined
  extra8label: string = "Extra 8"
  extra9?: string = undefined
  extra9label: string = "Extra 9"
  expires_at?: Date = undefined
  packsize: number = 0
  creditslookup: number = 0
  valid_sources: string = ""

  access: Array<EmgUserAccess> = []
  account?: EmgAccount = undefined

  access_new_connector: string = ""
  access_new_ipstr: string = ""
  access_new_ipwidth: string = ""

  balance_updates: Array<BalanceUpdate> = []
  index: number = 0
}
