import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { useLocation, useParams } from "react-router-dom"
import { EMGConnector, QE } from "../../helpers/emg"
import BreadcrumbContext from "../../BreadcrumbContext"
import routes from "../../routes"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import { useConnectors, useQueue } from "../../helpers/hooks"
import { useQueryClient } from "react-query"

const Queue = () => {
  const { connectorName } = useParams<string>()
  const { cs, loading: cs_loading } = useConnectors()
  const cache = useQueryClient()
  const { queue, loading: queue_loading } = useQueue(connectorName!)
  const loading = cs_loading || queue_loading
  const [moveTarget, setMoveTarget] = useState("")

  const connectorOperation = async (operation: string) => {
    console.log("connector operation " + operation)
    try {
      let path = "/api/emgd/connector/" + connectorName + "/" + operation
      if (operation === "moveall") path = path + "/" + moveTarget
      const response = await axios.post(path)
      console.log("got: " + JSON.stringify(response))
    } catch (error) {
      console.log("error: " + JSON.stringify(error))
    }
    return cache.invalidateQueries("queue")
  }

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "Connectors",
        path:
          routes.owner.root +
          routes.owner.server.root +
          routes.owner.server.ConnectorStatus,
      },
      { text: "Queue" },
    ])
  }, [context, location])

  if (loading) return <>{loading}</>

  return (
    <>
      <h1>Connector queue ({connectorName})</h1>

      <div style={{ marginBottom: "1px" }}>
        <div className="btn-group mr-2" role="group">
          {queue.data.state & 0x10 ? (
            <button
              className={"btn btn-primary"}
              onClick={() => connectorOperation("unhold")}
            >
              {"Unhold"}
            </button>
          ) : (
            <button
              className={"btn btn-primary"}
              onClick={() => connectorOperation("hold")}
            >
              {"Hold"}
            </button>
          )}
          {queue.data.state & 0x1000 ? (
            <button
              className={"btn btn-primary"}
              onClick={() => connectorOperation("release")}
            >
              {"Release"}
            </button>
          ) : (
            <>
              <button
                className={"btn btn-primary"}
                onClick={() => connectorOperation("bounce")}
              >
                {"Bounce"}
              </button>
              <button
                className={"btn btn-warning"}
                onClick={() => connectorOperation("forceclose")}
              >
                {"Force close"}
              </button>
            </>
          )}
        </div>
        <div className="btn-group" role="group">
          <button
            className={"btn btn-danger"}
            onClick={() => connectorOperation("clear")}
          >
            {"Delete all"}
          </button>
          <button
            className={"btn btn-warning"}
            disabled={moveTarget === ""}
            onClick={() => connectorOperation("moveall")}
          >
            {"Move all to: "}
          </button>
          <select
            name="moveto"
            value={moveTarget}
            onChange={(event) => setMoveTarget(event.target.value)}
          >
            <option key={0} value={""}>
              -- select --
            </option>
            {cs.isSuccess &&
              cs.data
                .filter((c: EMGConnector) => {
                  return c.name !== connectorName
                })
                .map((c: EMGConnector, index: number) => {
                  return (
                    <option key={index} value={c.name}>
                      {c.name}
                    </option>
                  )
                })}
          </select>
        </div>
      </div>

      {queue.isSuccess && (
        <>
          <p>There are {queue.data.queuesize} entries in the queue.</p>

          <div className="table-responsive">
            <table className="table table-striped table-sm">
              <caption className={"sr-only"}>
                List of messages on connector {connectorName}.
              </caption>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Start time</th>
                  <th>Username</th>
                  <th>Route to user</th>
                  <th>Source address</th>
                  <th>Destination address</th>
                </tr>
              </thead>
              <tbody>
                {queue.data.queue.map((qe: QE, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{qe.id}</td>
                      <td>{qe.starttime}</td>
                      <td>{qe.username}</td>
                      <td>{qe.xusername}</td>
                      <td>{qe.sourceaddr}</td>
                      <td>{qe.destaddr}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}

export default Queue
