import React, { useContext, useEffect } from "react"
import axios from "axios"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { usePBEntry, usePhoneBook } from "../helpers/hooks"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import routes from "../routes"
import { Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import { PBEntry } from "../models/PhoneBook"
import TextField from "../helpers/TextField"
import PrimaryButton from "../helpers/PrimaryButton"
import { useMutation, useQueryClient } from "react-query"
import { useErrorHandler } from "react-error-boundary"

export const PBEntryEdit = () => {
  const { pbid, id } = useParams<{ pbid: string; id: string }>()
  const navigate = useNavigate()
  const pb = usePhoneBook(pbid!)
  const entry = usePBEntry(id!)
  const handleError = useErrorHandler()
  const cache = useQueryClient()
  const pbpath =
    routes.user.root + routes.user.PhonebookEdit.replace(":id", pbid!)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      { text: "Phonebooks", path: routes.user.root + routes.user.Phonebooks },
      { text: "Phonebook " + pb.data?.name, path: pbpath },
      { text: "Entry" },
    ])
  }, [context, location, pbpath, pb.data, pb.data.name])

  const { mutate: updatePhoneBook } = useMutation(async (values: object) => {
    await axios.post("/api/user/pbentry/" + id, values)
    return cache.invalidateQueries("phonebook")
  })

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updatePhoneBook(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (entry.data.id < 1) navigate(pbpath)
    }
  }

  if (entry.isSuccess && entry.data) entry.data.pb_id = pbid

  return (
    (entry.isSuccess && entry.data && (
      <>
        <Formik
          initialValues={not_null(entry.data) as PBEntry}
          onSubmit={async (values, { setSubmitting }) => {
            return submitData(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form className={"form"}>
              <TextField label={"Company"} name={"company"} />
              <TextField label={"Name"} name={"name"} />
              <TextField
                label={"Phone"}
                name={"destaddr"}
                field_type={"phone"}
                required={true}
              />
              <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      </>
    )) || <>Loading.</>
  )
}
