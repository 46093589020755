import { useEffect, useState } from "react"
import { useErrorHandler } from "react-error-boundary"
import { NoConnectionError } from "../helpers/NoConnectionError"
import { InvalidUserError } from "../helpers/InvalidUserError"
import { UseQueryResult } from "react-query"

const useLoading = (result: UseQueryResult<any, unknown>) => {
  const handleError = useErrorHandler()
  const [loading, setLoading] = useState("Loading...")

  useEffect(() => {
    if (result.isError) {
      if (result.error instanceof NoConnectionError) {
        setLoading("No connection to the server.")
        return
      }
      if (result.error instanceof InvalidUserError) handleError(result.error)
    }
    if (result.isLoading) {
      setLoading("Loading...")
      return
    }
    if (result.isSuccess) {
      setLoading("")
    }
  }, [
    result.isLoading,
    result.isError,
    result.error,
    result.isSuccess,
    handleError,
  ])
  return loading
}

export default useLoading
