import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import routes from "./routes"
import BreadcrumbContext from "./BreadcrumbContext"
import { updateBreadcrumbs } from "./helpers/updateBreadcrumbs"
import { useEMGInfo } from "./helpers/hooks"
import UserContext from "./UserContext"
import axios from "axios"
import { useQueryClient } from "react-query"
import { ListItem } from "./MenuItem"

interface EMGInfo {
  version: string
  serial: string
  throughput: string
  expire: string
  expire_support: string
  activated_at: string
}

function EMGInfoView({ ei, role }: { ei: EMGInfo; role: string }) {
  const [reloadStatus, setReloadStatus] = useState("")
  const [reloadDisabled, setReloadDisabled] = useState(false)
  const cache = useQueryClient()

  const reloadConfig = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    console.log("reloadConfig")
    try {
      setReloadDisabled(true)
      setReloadStatus("Reloading...")
      const ret = await axios.post("/api/emgd/info/reload")
      console.log("reload returned: " + JSON.stringify(ret))
      if (ret && ret.data)
        setReloadStatus(
          ret.data.status === 0
            ? "Reload ok."
            : "Reload failed with status " + ret.data.status
        )
      await cache.invalidateQueries("EMGInfo")
      setReloadDisabled(false)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // handleError(error)
    }
  }

  return ei.version ? (
    <>
      <div className={"row"}>
        <div className={"col ml-3"}>
          <div className={"row"}>
            <span>The EMG version is {ei.version}.</span>
          </div>
          {role === "OWNER" && (
            <>
              <div className={"row"}>
                License {ei.serial && <span>&nbsp;{ei.serial}</span>}
                {ei.throughput && (
                  <span>
                    &nbsp;for
                    {ei.throughput < "9999" ? (
                      <span> {ei.throughput} MPS</span>
                    ) : (
                      <span> unlimited throughput</span>
                    )}
                  </span>
                )}
                {ei.expire && ei.expire !== "0" ? (
                  <span>
                    &nbsp;expires on {ei.expire}
                    {ei.expire_support && <>,&nbsp;</>}
                  </span>
                ) : (
                  <span>
                    &nbsp;is perpetual{ei.expire_support && <>,&nbsp;</>}
                  </span>
                )}
                {ei.expire_support && (
                  <span>and support expires on {ei.expire_support}</span>
                )}
                .
              </div>
              <div className={"row"}>&nbsp;</div>
              <div className={"row"}>
                The current configuration was activated at {ei.activated_at}.
              </div>
              <div className={"row mt-2"}>
                <a href={"/"} onClick={reloadConfig}>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={reloadDisabled}
                  >
                    Reload the configuration
                  </button>
                </a>
              </div>
              <div className={"row"}>{reloadStatus}</div>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <div>EMG does not seem to be running.</div>
  )
}

const Dashboard = () => {
  const emgInfo = useEMGInfo()
  const user = useContext(UserContext)
  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [])
  }, [context, location])

  return (
    <>
      <h1>Dashboard</h1>

      <div className={"row"}>
        <div className={"col"}>
          <section className={"card h-100"}>
            <fieldset className={"card-body"}>
              <legend>System information</legend>
              {emgInfo.isSuccess && (
                <EMGInfoView ei={emgInfo.data as EMGInfo} role={user.role} />
              )}
            </fieldset>
          </section>
        </div>
      </div>

      {(user.role === "OWNER" || user.role === "ADMIN") && (
        <div className={"row"}>
          <div className={"col-lg-4 col-sm-6"}>
            <section className={"card h-100"}>
              <fieldset className={"card-body"}>
                <legend>Reports</legend>
                <ul>
                  {user.role === "OWNER" && (
                    <ListItem
                      text={"Connector status"}
                      to={
                        routes.owner.root +
                        routes.owner.server.root +
                        routes.owner.server.ConnectorStatus
                      }
                    />
                  )}
                  <ListItem
                    text={"Message log"}
                    to={routes.admin.root + routes.admin.TrackingList}
                  />
                  {/* Not Yet Supported
                  user.role === "OWNER" && (
                    <>
                      <ListItem
                        text={"Traffic overview"}
                        to={routes.admin.root + routes.admin.TrafficOverview}
                      />
                      <ListItem
                        text={"Traffic details"}
                        to={routes.admin.root + routes.admin.TrafficDetails}
                      />
                      <ListItem
                        text={"Traffic per connector"}
                        to={
                          routes.admin.root + routes.admin.TrafficPerConnector
                        }
                      />
                    </>
                  )*/}
                  {user.role === "OWNER" && (
                    <ListItem
                      text={"Payments"}
                      to={routes.owner.root + routes.owner.Payments}
                    />
                  )}
                </ul>
              </fieldset>
            </section>
          </div>

          <div className={"col-lg-4 col-sm-6"}>
            <section className={"card h-100"}>
              <fieldset className={"card-body"}>
                <legend>Provisioning</legend>
                <ul>
                  <ListItem
                    text={"Web users"}
                    to={routes.admin.root + routes.admin.Users}
                  />
                  {user.role === "OWNER" && (
                    <ListItem
                      text={"Customers"}
                      to={routes.owner.root + routes.owner.Customers}
                    />
                  )}
                  <ListItem
                    text={"EMG accounts"}
                    to={routes.admin.root + routes.admin.EmgUsers}
                  />
                  <ListItem
                    text={"Mail Domains"}
                    to={routes.admin.root + routes.admin.MailDomains}
                  />
                  {user.role === "OWNER" && (
                    <>
                      <ListItem
                        text={"Routes"}
                        to={routes.owner.root + routes.owner.Routes}
                      />
                      <ListItem
                        text={"Prices"}
                        to={routes.owner.root + routes.owner.Prices}
                      />
                      <ListItem
                        text={"MO Routes"}
                        to={routes.owner.root + routes.owner.MO_Routes}
                      />
                    </>
                  )}
                  {(user.role === "OWNER" || user.role === "ADMIN") && (
                    <ListItem
                      text={"Licenses"}
                      to={routes.admin.root + routes.admin.Licenses}
                    />
                  )}
                </ul>
              </fieldset>
            </section>
          </div>

          {user.role === "OWNER" && (
            <div className={"col-lg-4 col-sm-6"}>
              <section className={"card h-100"}>
                <fieldset className={"card-body"}>
                  <legend>EMG configuration</legend>
                  <ul>
                    <ListItem
                      text={"Connectors"}
                      to={
                        routes.owner.root +
                        routes.owner.config.root +
                        routes.owner.config.Connectors
                      }
                      dataTestid="link_owner_cfg_connectors"
                    />
                    <ListItem
                      text={"Plugins"}
                      to={
                        routes.owner.root +
                        routes.owner.config.root +
                        routes.owner.config.Plugins
                      }
                    />
                    <ListItem
                      text={"SAT Pools"}
                      to={
                        routes.owner.root +
                        routes.owner.config.root +
                        routes.owner.config.Satpools
                      }
                    />
                  </ul>
                </fieldset>
              </section>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Dashboard
