import React, { useContext, useState } from "react"
import axios from "axios"
import { Field, Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import PrimaryButton from "../helpers/PrimaryButton"
import SelectConnector from "../helpers/SelectConnector"
import { useConnectors, usePhoneBook, usePhoneBooks } from "../helpers/hooks"
import { useErrorHandler } from "react-error-boundary"
import { PBEntry, PhoneBook } from "../models/PhoneBook"
import Checkbox from "../helpers/Checkbox"
import UserContext from "../UserContext"

interface SendForm {
  destaddr: string
  message: string
  charset: string
  route: string
  flash: boolean
  // scheduled time
}

class SendForm implements SendForm {
  constructor() {
    this.destaddr = ""
    this.message = ""
    this.charset = "UTF8"
    this.route = ""
  }
}

export const SendSMS = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { cs: connectors, loading: cs_loading } = useConnectors()
  const handleError = useErrorHandler()
  const { phonebooks: books, loading: pb_loading } = usePhoneBooks()
  const [pbid, setPBid] = useState(0)
  const pb = usePhoneBook("" + pbid)
  const [destaddrs, setDestaddrs] = useState([] as Array<string>)
  const loading = cs_loading || pb_loading

  if (loading) return <>{loading}</>

  const pluralized = (n: number, w: string) => {
    return "" + n + " " + w + (n === 1 ? "" : "s")
  }

  const showLength = (message: string) => {
    /* console.log(
      "message is: " +
        JSON.stringify(message) +
        ", encoded: " +
        encodeURIComponent(message)
    ) */
    const len = message.length
    let parts = 0
    if (len > 0) parts = 1
    if (len > 160) parts = Math.ceil(len / 154)
    return pluralized(len, "character") + ", " + parts + " SMS"
  }

  const submitData = async (values: SendForm, setSubmitting: any) => {
    console.log("send sms: " + JSON.stringify(values))
    try {
      const res = await axios.post("/api/user/sms/send", {
        message: values.message,
        destaddrs,
        flash: values.flash,
        route: values.route,
      })
      console.log("got response " + JSON.stringify(res.data.results))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const selectPhoneBook = (event: any) => {
    setPBid(event.target.value)
  }

  const addDestaddr = (destaddr: string) => {
    setDestaddrs(destaddrs.concat([destaddr]))
  }

  const deleteDestaddr = (destaddr: string) => {
    setDestaddrs(destaddrs.filter((a) => a !== destaddr))
  }

  return (
    <>
      <h1>Send SMS</h1>
      <Formik
        initialValues={new SendForm()}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={"form"}>
            {role === "owner" && (
              <>
                <div className={"form-group row"}>
                  <div className={"col-sm-3"}> </div>
                  <select
                    className={"form-control form-control-sm col"}
                    onChange={selectPhoneBook}
                  >
                    <option key={-1} value={0}>
                      Select a phonebook
                    </option>
                    {books.data.map((pb: PhoneBook, index: number) => {
                      return (
                        <option key={index} value={pb.id}>
                          {pb.name}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className={"form-group row"}>
                  <div className={"col-sm-3"}> </div>
                  {pb.isSuccess && pb.data && pb.data.entries && (
                    <Field
                      as={"select"}
                      name={"destaddr"}
                      className={"form-control form-control-sm col"}
                      disabled={pbid === 0}
                    >
                      <option key={-1} value={0}>
                        Select a recipient
                      </option>
                      {pb.data.entries.map((pbe: PBEntry, index: number) => {
                        return (
                          <option key={index} value={pbe.destaddr}>
                            {pbe.name}
                            {pbe.company.length > 0 && " - " + pbe.company}
                          </option>
                        )
                      })}
                    </Field>
                  )}
                </div>
              </>
            )}

            <div className={"form-group row"}>
              <label className={"col-sm-3"}>Recipient</label>
              <Field
                className={"form-control form-control-sm col-sm-4"}
                type={"text"}
                name={"destaddr"}
              />
              <button
                className={"btn btn-primary ml-3"}
                type={"button"}
                onClick={() => addDestaddr(values.destaddr)}
              >
                Add
              </button>
            </div>

            <div className={"form-group row"}>
              <label className={"col-sm-3"}>Recipients</label>
              {destaddrs.length > 0 ? (
                <table className={"col-sm-4"}>
                  <tbody>
                    {destaddrs.map((destaddr: string, index: number) => {
                      return (
                        <tr key={index}>
                          <td className={"col"}>{destaddr}</td>
                          <td>
                            <img
                              src="/delete.png"
                              alt={"delete recipient " + destaddr}
                              onClick={() => {
                                deleteDestaddr(destaddr)
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <i className={"col-sm-4"}>Add recipient(s) above</i>
              )}
            </div>

            <TextField
              label={"Message"}
              name={"message"}
              tag={"textarea"}
              rows={5}
            />

            <div className={"form-group row"}>
              <div className={"col-sm-3"} />
              <div className={"col"}>{showLength(values.message)}</div>
            </div>

            <Checkbox name={"flash"} label={"Flash SMS"} />

            {role === "owner" && connectors.isSuccess && (
              <SelectConnector
                label={"Route"}
                name={"route"}
                list={connectors.data}
                nullText={"default"}
                key={"name"}
              />
            )}

            <PrimaryButton
              text={"Send"}
              isSubmitting={isSubmitting}
              disabled={values.message.length === 0 || destaddrs.length === 0}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
