export class PBEntry {
  id: number = 0
  pb_id: number = 0
  company: string = ""
  name: string = ""
  destaddr: string = ""
}

export enum PhoneBookType {
  Private = 0,
  // Group = 1, // huh?
  Public = 2,
}

export class PhoneBook {
  id: number = 0
  type: PhoneBookType = PhoneBookType.Private
  name: string = ""
  user_id: number = 0
  n_entries: number = 0

  entries: Array<PBEntry> = []
}
