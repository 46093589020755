class User {
  userid: number = 0
  username: string = ""
  role: string = ""

  constructor(username: string = "") {
    this.username = username
  }
}

export default User
