import React, { useContext, useEffect, useState } from "react"
import { Field, Form, Formik } from "formik"
import {
  emptyRLE,
  not_null,
  RouteLog,
  statusName,
  time_with_unit,
  unhex,
} from "../helpers/emg"
import Modal from "react-modal"
import BreadcrumbContext from "../BreadcrumbContext" // http://reactcommunity.org/react-modal/
import { useLocation } from "react-router-dom"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useDlrs, useEMGUsersWithCompany, useRoutelog } from "../helpers/hooks"
import UserContext from "../UserContext"
import SelectEMGAccount from "../helpers/SelectEMGAccount"

// TODO: Per connector (in + out)
// TODO: Multiple message id's

enum TF_DateFixedType {
  LastHour = "LastHour",
  Today = "Today",
  ThisMonth = "ThisMonth",
  LastMonth = "LastMonth",
}

enum TF_DestaddrSelType {
  StartsWith = "StartsWith",
  Contains = "Contains",
}

enum TF_Status {
  Any = "Any",
  Delivered = "Delivered",
  Pending = "Pending",
  Failed = "Failed",
}

interface TrackingFields {
  date_seltype: string
  date_fixedtype: TF_DateFixedType
  fromdate: string
  todate: string
  destaddr_seltype: TF_DestaddrSelType
  destaddr_sel: string
  msgid: string
  maxentries: number
  simple_status: TF_Status
}

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root")

const StatusImage = (props: { status: number }) => {
  let color
  switch (props.status) {
    case 1:
      color = "green"
      break
    case 2:
    case 8:
    case 9:
    case 10:
      color = "yellow"
      break
    default:
      color = "red"
  }
  return <img alt={color} src={"/images/dot-" + color + ".gif"} />
}

const tableClass = (status: string) => {
  switch (parseInt(status)) {
    case 1:
      return "table-success"
    case 2:
    case 8:
    case 9:
    case 10:
      return "table-warning"
    default:
      return "table-danger"
  }
}

interface MessageDetailsProps {
  selectedEntry: RouteLog
  hideDetails: any
}

const MessageDetails = (props: MessageDetailsProps) => {
  const selectedEntry = props.selectedEntry
  const dlrs = useDlrs(selectedEntry.msgid)

  return (
    <Modal
      isOpen={true}
      /*style={{content: {height: 600, width: 450}}}*/
      onRequestClose={props.hideDetails}
    >
      <table className={"table table-sm table-borderless"}>
        <tbody>
          <tr>
            <th>Message id</th>
            <td>{selectedEntry.msgid}</td>
          </tr>
          <tr>
            <th>Account</th>
            <td>{selectedEntry.username}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td className={tableClass(selectedEntry.status)}>
              {statusName(parseInt(selectedEntry.status))}
            </td>
          </tr>
          <tr>
            <th>Charge</th>
            <td>{selectedEntry.charge}</td>
          </tr>
          <tr>
            <th>Sourceaddr</th>
            <td>{selectedEntry.sourceaddr}</td>
          </tr>
          <tr>
            <th>Destaddr</th>
            <td>{selectedEntry.destaddr}</td>
          </tr>
          {/* + price */}
          <tr>
            <th>Lifetime</th>
            <td>{time_with_unit(selectedEntry.delay)}</td>
          </tr>
          <tr>
            <th>Reason</th>
            <td>{selectedEntry.reason}</td>
          </tr>
          <tr>
            <th>Connector in</th>
            <td>{selectedEntry.inconnector}</td>
            {/* plus part id's */}
          </tr>
          <tr>
            <th>Connector out</th>
            <td>
              {selectedEntry.outconnector}
              {(selectedEntry.smscid || "").length > 0 && (
                <>
                  <br />
                  (msgid: {selectedEntry.smscid})
                </>
              )}
            </td>
          </tr>
          {selectedEntry.note && selectedEntry.note.length > 0 && (
            <tr>
              <th>Note</th>
              <td>{selectedEntry.note}</td>
            </tr>
          )}

          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>

          <tr>
            <th>Received</th>
            <td>{selectedEntry.starttime}</td>
          </tr>
          <tr>
            <th>Delay</th>
            <td>{time_with_unit(selectedEntry.delay)}</td>
          </tr>
          <tr>
            <th>Sent & acked</th>
            <td>{selectedEntry.endtime}</td>
          </tr>

          {dlrs.isSuccess &&
            dlrs.data.map((dlr: RouteLog, index: number) => {
              return (
                <React.Fragment key={index * 10}>
                  <tr>
                    <td colSpan={2}>&nbsp;</td>
                  </tr>
                  <tr>
                    <th>DLR created/received</th>
                    <td>{dlr.starttime}</td>
                  </tr>
                  <tr>
                    <th>Delay</th>
                    <td>{time_with_unit(dlr.delay)}</td>
                  </tr>
                  <tr>
                    <th>DLR sent & acked</th>
                    <td>{dlr.endtime}</td>
                  </tr>
                </React.Fragment>
              )
            })}

          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>

          {(selectedEntry.body || "").length > 0 && (
            <tr>
              <td colSpan={2}>
                <strong>Message text (encoding may be wrong):</strong>
                <br />
                <i>{unhex(selectedEntry.body)}</i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Modal>
  )
}

const TrackingList = () => {
  const [params, setParams] = useState({
    date_seltype: "0",
    date_fixedtype: TF_DateFixedType.ThisMonth,
    fromdate: new Date().toISOString().slice(0, 10),
    todate: new Date().toISOString().slice(0, 10),
    destaddr_seltype: TF_DestaddrSelType.StartsWith,
    destaddr_sel: "",
    msgid: "",
    maxentries: 50,
    simple_status: TF_Status.Any,
    currentDateTime: "",
  })
  const { entries, loading: entries_loading } = useRoutelog(params)
  const [selectedEntry, setSelectedEntry] = useState(emptyRLE)
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const loading = entries_loading || accounts_loading

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Message log" }])
  }, [context, location])

  const showDetails = (
    event: React.MouseEvent<HTMLAnchorElement>,
    entry: RouteLog
  ) => {
    event.preventDefault()
    setSelectedEntry(entry)
  }

  if (loading) return <>{loading}</>

  const readable_body = (entry: RouteLog) => {
    if (!entry.body) return ""
    // console.log("body", entry.body)
    return entry.body
  }

  return (
    <>
      <h1>Message log</h1>
      <Formik
        initialValues={not_null(params) as TrackingFields}
        onSubmit={async (values, { setSubmitting }) => {
          await setSubmitting(false)
          console.log("Setting new search parameters")
          setParams({
            ...values,
            currentDateTime: Date().toLocaleString(),
          })
        }}
      >
        {() => (
          <div className={"card"}>
            <div className={"card-header text-center"}>
              <legend>Selection</legend>
            </div>
            <div className={"card-body"}>
              <Form className={"form-inline"}>
                <div className={"form-row align-items-center col-12"}>
                  <div className={"form-group"}>
                    <label className={"col-form-label"}>Date:</label>
                    <div className={"form-check form-check-inline ml-2"}>
                      <Field
                        value={"0"}
                        name="date_seltype"
                        type="radio"
                        className={"form-check-input position-static"}
                      />
                    </div>
                  </div>
                  <div>
                    <Field
                      as={"select"}
                      name={"date_fixedtype"}
                      className={"form-control"}
                    >
                      <option value={TF_DateFixedType.LastHour}>
                        Last hour
                      </option>
                      <option value={TF_DateFixedType.Today}>Today</option>
                      <option value={TF_DateFixedType.ThisMonth}>
                        This month
                      </option>
                      <option value={TF_DateFixedType.LastMonth}>
                        Last month
                      </option>
                    </Field>
                  </div>
                  <div>
                    <div className={"form-check-inline ml-2"}>
                      <Field
                        value={"1"}
                        name="date_seltype"
                        type="radio"
                        className={"form-check-input position-static"}
                      />
                    </div>
                    <Field
                      name={"fromdate"}
                      type="date"
                      placeholder={"from"}
                      className={"form-control col-auto mr-1"}
                    />
                    &ndash;
                    <Field
                      name={"todate"}
                      type="date"
                      placeholder={"to"}
                      className={"form-control col-auto ml-1 mb-2"}
                    />
                  </div>
                </div>
                <div className={"form-row align-items-center col-12 mb-2"}>
                  <div className={"form-group"}>
                    <label className={"col-form-label"}>Recipient:</label>
                    <div className={"form-check-inline ml-2"}>
                      <Field
                        value={TF_DestaddrSelType.StartsWith}
                        name="destaddr_seltype"
                        type="radio"
                        className={"form-check-input position-static"}
                      />
                    </div>
                    <label className={"col-form-label ml-2"}>Starts with</label>
                    <div className={"form-check-inline ml-2"}>
                      <Field
                        value={TF_DestaddrSelType.Contains}
                        name="destaddr_seltype"
                        type="radio"
                      />
                      <label className={"col-form-label ml-2"}>Contains</label>
                    </div>
                    <div className={"form-check-inline"}>
                      <Field
                        name={"destaddr_sel"}
                        type={"text"}
                        size={"20"}
                        className={"form-control"}
                      />
                    </div>
                  </div>
                </div>
                <div className={"form-row align-items-center col-12 mb-2"}>
                  <div className={"form-group"}>
                    <label className={"col-form-label mr-2"}>Status</label>
                    <Field
                      as={"select"}
                      name={"simple_status"}
                      className={"form-control"}
                    >
                      <option value={TF_Status.Any}>─ Any ─</option>
                      <option value={TF_Status.Delivered}>Delivered</option>
                      <option value={TF_Status.Pending}>Pending</option>
                      <option value={TF_Status.Failed}>Failed</option>
                    </Field>
                    <div className={"form-group"}>
                      <label className={"col-form-label ml-2 mr-2"}>
                        Message id
                      </label>
                      <Field
                        name={"msgid"}
                        type="text"
                        size={"20"}
                        className={"form-control"}
                      />
                    </div>
                  </div>
                </div>
                {(role === "owner" || role === "admin") &&
                  accounts.data &&
                  accounts.data.length > 0 && (
                    <div className={"form-row align-items-center col-12 mb-2"}>
                      <div className={"form-group"}>
                        <SelectEMGAccount
                          label={"EMG User"}
                          name={"username"}
                          by_name={true}
                          accounts={accounts.data}
                          nullText={"Select"}
                        />
                      </div>
                    </div>
                  )}
                <div className={"form-row align-items-center col-12 mb-2"}>
                  <div className={"form-group"}>
                    <label className={"col-form-label mr-2"}>
                      Max entries to display
                    </label>
                    <Field
                      as={"select"}
                      name={"maxentries"}
                      className={"form-control"}
                    >
                      <option value={"50"}>50</option>
                      <option value={"1000"}>1000</option>
                      <option value={"10000"}>10000</option>
                      <option value={"-1"}>All</option>
                    </Field>
                  </div>
                </div>
                <div className={"form-row col-12"}>
                  <button type={"submit"}>Update</button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>

      <section className={"pt-2"}>
        <p>Displaying {(entries.data || []).length} messages.</p>
        <table className={"table table-sm table-responsive"}>
          <thead>
            <tr>
              <th />
              <th>Id</th>
              <th>Received</th>
              <th>Delay</th>
              <th>Account</th>
              <th>Sourceaddr</th>
              <th>Destaddr</th>
              <th>Status</th>
              <th>Reason</th>
              <th>#SMS</th>
              <th>Charge</th>
              <th>Text</th>
            </tr>
          </thead>
          <tbody>
            {(entries.data || []).length > 0 ? (
              <>
                {entries.data.map((entry: RouteLog) => {
                  return (
                    <tr key={entry.msgid} className={tableClass(entry.status)}>
                      <td>
                        <StatusImage status={parseInt(entry.status)} />
                      </td>
                      <td>
                        <a
                          href={"details"}
                          onClick={(event) => showDetails(event, entry)}
                        >
                          {entry.msgid}
                        </a>
                      </td>
                      <td>{entry.starttime}</td>
                      <td>{time_with_unit(entry.delay)}</td>
                      <td>{entry.username}</td>
                      <td>{entry.sourceaddr}</td>
                      <td>{entry.destaddr}</td>
                      <td>
                        {entry.status} - {statusName(parseInt(entry.status))}
                      </td>
                      <td>{entry.reason}</td>
                      <td>{entry.npdus}</td>
                      <td>{entry.charge}</td>
                      <td>{readable_body(entry)}</td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr>
                <td colSpan={12}>No entries found</td>
              </tr>
            )}
          </tbody>
        </table>
        {selectedEntry.msgid && (
          <MessageDetails
            selectedEntry={selectedEntry}
            hideDetails={() => setSelectedEntry(emptyRLE)}
          />
        )}
      </section>
    </>
  )
}

export default TrackingList
