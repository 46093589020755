import React, { useContext, useEffect } from "react"
import axios from "axios"
import { useSettings } from "../helpers/hooks"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import BreadcrumbContext from "../BreadcrumbContext"
import { useLocation } from "react-router-dom"
import { Field, Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import PrimaryButton from "../helpers/PrimaryButton"
import { useMutation, useQueryClient } from "react-query"
import { useErrorHandler } from "react-error-boundary"
import UserContext from "../UserContext"

export const SettingsView = () => {
  const user = useContext(UserContext)
  const { settings, loading } = useSettings()
  const handleError = useErrorHandler()
  const cache = useQueryClient()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Settings" }])
  }, [context, location])

  const { mutate: updateSettings } = useMutation(async (values: object) => {
    await axios.post("/api/user/settings/update", values)
    return cache.invalidateQueries("settings")
  })

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updateSettings(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
    }
  }

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>System settings</h1>

      <Formik
        initialValues={not_null(settings.data!)}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting }) => (
          <Form className={"form"}>
            <h2>EMG server(s)</h2>
            {user.role === "OWNER" && (
              // owner or admin: max_recipients
              // admin: admin_message
              // user: theme
              <>
                <table className={"table table-sm table-striped"}>
                  <thead>
                    <tr>
                      <th>Host</th>
                      <th>Port</th>
                      <th>Nodeid (1, 2, ...)</th>
                      <th>Watchdog port</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        This port should match an incoming HTTP connector, which
                        must use USERDB and XAUTH and be reachable from the EMG
                        Portal server.
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <Field
                          className={"form-control form-control-sm"}
                          name={"emg_host"}
                        />
                      </td>
                      <td>
                        <Field
                          className={"form-control form-control-sm"}
                          name={"emg_port"}
                          type={"number"}
                          min_number={1024}
                        />
                      </td>
                      <td>
                        <Field
                          className={"form-control form-control-sm"}
                          name={"emg_nodeid"}
                          type={"number"}
                          min_number={0}
                        />
                      </td>
                      <td>
                        <Field
                          className={"form-control form-control-sm"}
                          name={"watchdog_port"}
                          type={"number"}
                          min_number={0}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            <PrimaryButton isSubmitting={isSubmitting} text={"Update"} />
          </Form>
        )}
      </Formik>
    </>
  )
}
