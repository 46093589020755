import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useLicenses } from "../helpers/hooks"
import UserContext from "../UserContext"
import { ProductLicense } from "../models/product"
import LinkedCustomer from "../components/LinkedCustomer"
import AddNewLink from "../components/AddNewLink"
import LinkedLicense from "../components/LinkedLicense"

const Licenses = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { licenses, loading, deleter } = useLicenses(role)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Licenses" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Licenses</h1>
      <p>
        There {licenses!.length === 1 ? "is" : "are"} {licenses!.length} license
        {licenses!.length === 1 ? "" : "s"} defined.
        {user.role === "OWNER" && (
          <>
            {" "}
            <AddNewLink path={":id"} />
          </>
        )}
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            {user.role === "OWNER" && <th>Customer</th>}
            <th>License no</th>
            <th>Product</th>
            <th>MPS</th>
            <th>Type</th>
            <th>Valid until</th>
            <th>Support until</th>
            {user.role === "OWNER" && <th>Delete</th>}
          </tr>
        </thead>

        <tbody>
          {licenses!.length > 0 ? (
            licenses!.map((obj: ProductLicense) => {
              // const customer = obj.customer
              return (
                <tr key={obj.id}>
                  {user.role === "OWNER" && (
                    <td>
                      <LinkedCustomer
                        customer={obj.customer}
                        emptyText={"&nbsp;"}
                      />
                    </td>
                  )}
                  <td>
                    <LinkedLicense license={obj} />
                  </td>
                  <td>
                    {obj.name} {obj.version}
                  </td>
                  <td>{obj.name === "EMG" ? obj.mps : <>&nbsp;</>}</td>
                  <td>&nbsp;</td>
                  <td>{obj.name === "EMG" ? obj.valid_until : <>&nbsp;</>}</td>
                  <td>{obj.support_until}</td>
                  {/* <td>{customer.name}</td> */}
                  {user.role === "OWNER" && (
                    <td>
                      <img
                        src="/delete.png"
                        alt={"delete license " + obj.licno}
                        onClick={() => deleter("license " + obj.licno, obj.id)}
                      />
                    </td>
                  )}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={10}>No entries found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default Licenses
