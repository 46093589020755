import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { usePayments } from "../helpers/hooks"
import UserContext from "../UserContext"
import { Payment } from "../models/Payment"

const Payments = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { payments, loading } = usePayments(role)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Payments" }])
  }, [context, location])

  if (loading) return <>{loading}</>

  const rowClass = (payment: Payment) => {
    if (payment.processed_ok) return "table-success"
    if (payment.processed_at) return "table-danger"
    return "table-warning"
  }

  return (
    <>
      <h1>Payments</h1>
      <p>
        There {payments!.length === 1 ? "has" : "have"}
        {" been "}
        {payments!.length}
        {" payment"}
        {payments!.length === 1 ? "" : "s"} made.
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Paid at</th>
            <th>Processed at</th>
            <th>Ok?</th>
            <th>Product id</th>
            <th>Product name</th>
            <th>Email</th>
          </tr>
        </thead>

        <tbody>
          {(payments || []).length > 0 ? (
            payments!.map((obj: Payment) => {
              return (
                <tr key={obj.id} className={rowClass(obj)}>
                  <td>{obj.paid_at}</td>
                  <td>{obj.processed_at || ""}</td>
                  <td>{obj.processed_ok ? "Yes" : ""}</td>
                  <td>{obj.product_id}</td>
                  <td>{obj.product_name}</td>
                  <td>{obj.email}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>No entries found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default Payments
