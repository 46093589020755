import { EmgAccount } from "./EmgAccount"

export enum PriceStatus {
  Status_Inactive = 0,
  Status_Active = 1,
  Status_Deleted = 2,
}

export class Price {
  id: number = 0
  name: string = ""
  emgaccount_id: number = 0
  connector: string = ""
  priority: number = 3
  status: PriceStatus = PriceStatus.Status_Active
  prefix_type: number = 0
  prefix: string = ""
  connector_out: string = ""
  charge: number = 0.0
  charge_cost: number = 0.0
  created: string = ""

  account: EmgAccount = new EmgAccount()

  static statusName(obj: Price) {
    switch (obj.status) {
      case PriceStatus.Status_Inactive:
        return "Inactive"
      case PriceStatus.Status_Active:
        return "Active"
      case PriceStatus.Status_Deleted:
        return "Deleted"
    }
    return "?"
  }
}
