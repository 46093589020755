import React, { useContext, useEffect } from "react"
import axios from "axios"
import { useLocation, useParams } from "react-router-dom"
import { Form, Formik } from "formik"
import { not_null } from "../../helpers/emg"
import { useErrorHandler } from "react-error-boundary"
import TextField from "../../helpers/TextField"
import BreadcrumbContext from "../../BreadcrumbContext"
import routes from "../../routes"
import Checkbox from "../../helpers/Checkbox"
import PrimaryButton from "../../helpers/PrimaryButton"
import { useConfiguredPlugin } from "../../helpers/hooks"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import { useQueryClient } from "react-query"

const breadcrumbs = [
  {
    text: "Plugins",
    path:
      routes.owner.root +
      routes.owner.config.root +
      routes.owner.config.Plugins,
  },
  { text: "Plugin" },
]

const PluginEdit = () => {
  const { id } = useParams<string>()
  const { plugin, loading } = useConfiguredPlugin(id!)
  const handleError = useErrorHandler()
  const cache = useQueryClient()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, breadcrumbs)
  }, [context, location])

  if (loading) return <>{loading}</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/owner/config/plugin/" + plugin.data.id,
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      // TODO: handle validity checks
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      await cache.invalidateQueries("configuredPlugin")
    }
    setSubmitting(false)
  }

  return (
    <>
      <Formik
        initialValues={not_null(plugin.data)}
        onSubmit={(values, { setSubmitting }) =>
          submitData(values, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form className={"form"}>
            <TextField label={"Name"} name={"name"} required={true} />
            <Checkbox label={"Disabled"} name={"disabled"} />
            <TextField
              label={"Instances"}
              name={"instances"}
              field_type={"number"}
              min_number={0}
            />
            <TextField label={"Library"} name={"library"} />
            <TextField label={"URL"} name={"url"} />
            <TextField label={"Config"} name={"config"} />
            <TextField label={"Database profile"} name={"dbprofile"} />
            <TextField label={"Connectors"} name={"connectors"} />
            <TextField label={"Note"} name={"note"} tag={"textarea"} rows={5} />
            <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PluginEdit
