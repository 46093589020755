import React from "react"
import { useErrorHandler } from "react-error-boundary"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useEMGUser } from "../helpers/hooks"
import PrimaryButton from "../helpers/PrimaryButton"
import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"

interface EmgPasswordForm {
  password: string
  password2: string
}

export const SetEmgPassword = () => {
  const { uuid } = useParams<string>()
  const navigate = useNavigate()

  const user = useEMGUser(uuid!, "site")
  const cache = useQueryClient()
  const handleError = useErrorHandler()

  console.log("Set password for emg user with token " + uuid)

  const { mutate: updateUser } = useMutation(
    async (values: EmgPasswordForm) => {
      console.log("posting new data: " + JSON.stringify(values))
      await axios.post("/site/reset_emgpassword/" + user.data!.userid, {
        password: values.password,
      })
    }
  )

  if (!user.isSuccess) return <>Loading...</>

  const can_submit = (values: any) => {
    // console.log("validate: " + JSON.stringify(values))
    return values.password.length > 0 && values.password === values.password2
  }

  const submitData = async (values: EmgPasswordForm, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updateUser(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      await cache.invalidateQueries("user")
      navigate("/")
    }
  }

  return (
    <>
      <h1>Set Password for EMG user {user.data.username}</h1>
      <Formik
        initialValues={{ password: "", password2: "" } as EmgPasswordForm}
        onSubmit={async (values, { setSubmitting }) => {
          await submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={"form"}>
            <TextField label="Password" name="password" />
            <TextField label="Same password" name="password2" />
            <PrimaryButton
              text={"Set Password"}
              isSubmitting={isSubmitting}
              disabled={!can_submit(values)}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
