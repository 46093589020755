import React from "react"
import { Link } from "react-router-dom"
import routes from "../routes"
import { Plugin } from "../models/Plugin"

interface LinkedPluginProps {
  plugin: Plugin
}

const LinkedPlugin = (props: LinkedPluginProps) => {
  const plugin = props.plugin
  return (
    <Link
      to={
        routes.owner.root +
        routes.owner.config.root +
        routes.owner.config.Plugins +
        "/" +
        plugin.id
      }
    >
      {plugin.name}
    </Link>
  )
}

export default LinkedPlugin
