import React, { useContext, useEffect, useState } from "react"
import { Price, PriceStatus } from "../models/Price"
import { Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import SelectNamed from "../helpers/SelectNamed"
import TextField from "../helpers/TextField"
import { useLocation } from "react-router-dom"
import SelectConnector from "../helpers/SelectConnector"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useConnectors,
  useEMGUsersWithCompany,
  usePriceCount,
  usePrices,
} from "../helpers/hooks"
import AddNewLink from "../components/AddNewLink"
import LinkedPrice from "../components/LinkedPrice"
import UserContext from "../UserContext"

class PriceFields {
  emgaccount_id: number = 0
  connector: string = ""
  connector_out: string = ""
  prefix: string = ""
  status: PriceStatus = PriceStatus.Status_Active
  limit?: number = undefined
}

const Prices = () => {
  const [params, setParams] = useState(new PriceFields())
  const { prices, loading: prices_loading, deleter } = usePrices(params)
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const { count, loading: count_loading } = usePriceCount()
  const [filter, setFilter] = useState("")
  const { cs, loading: cs_loading } = useConnectors()
  const loading =
    prices_loading || accounts_loading || count_loading || cs_loading

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Prices" }])
  }, [context, location])

  const limitList = [{ text: "100", value: 100 }]
    .concat(count.data > 1000 ? [{ text: "1000", value: 1000 }] : [])
    .concat(count.data > 1000 ? [{ text: "4000", value: 4000 }] : [])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Prices</h1>
      <Formik
        initialValues={not_null(params) as PriceFields}
        onSubmit={(values, { setSubmitting }) => {
          ;(async () => {
            setSubmitting(false)
            setParams(values)
          })()
        }}
      >
        {() => (
          <Form id={"prices-form"}>
            <div className={"form"}>
              <fieldset>
                <legend>Selection</legend>
                <SelectEMGAccount
                  label={"Account"}
                  name={"emgaccount_id"}
                  accounts={accounts.data}
                  nullText={"Any"}
                />
                <SelectConnector
                  label={"Connector in"}
                  name={"connector"}
                  list={cs.data}
                  nullText={"Any connector"}
                />
                <SelectConnector
                  label={"Connector out"}
                  name={"connector_out"}
                  list={cs.data}
                  nullText={"Any connector"}
                />
                <TextField label={"Prefix"} name={"prefix"} />
                <SelectNamed
                  label={"Status"}
                  name={"status"}
                  list={[
                    { text: "Inactive", value: PriceStatus.Status_Inactive },
                    { text: "Active", value: PriceStatus.Status_Active },
                    { text: "Deleted", value: PriceStatus.Status_Deleted },
                  ]}
                  nullText={"Any"}
                />
                {count.data > 100 && (
                  <SelectNamed
                    label={"Max entries to display"}
                    name={"limit"}
                    list={limitList}
                    nullText={"All"}
                  />
                )}
              </fieldset>
            </div>

            <PrimaryButton text={"Update"} isSubmitting={undefined} />
          </Form>
        )}
      </Formik>
      <>
        <p style={{ marginTop: "10px" }}>
          Displaying {(prices.data || []).length} price
          {(prices.data || []).length === 1 ? "" : "s"}, of a total of{" "}
          {count.data}. <AddNewLink path={":id"} />
        </p>
        <p>
          Filter:{" "}
          <input
            type={"text"}
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value)
            }}
          />
        </p>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              {/* <th>[checkbox]</th> */}
              <th>Id</th>
              <th>Status</th>
              <th>Name</th>
              <th>Connector in</th>
              <th>Connector out</th>
              <th>Account</th>
              <th>Prefix</th>
              <th>Charge</th>
              <th>Cost</th>
              <th>Created</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {(prices.data || []).length > 0 ? (
              prices.data
                .filter((obj: Price) => {
                  if (filter.length === 0) return true
                  const values = JSON.stringify(
                    Object.values(obj)
                  ).toLowerCase()
                  return values.indexOf(filter.toLowerCase()) > -1
                })
                .map((obj: Price) => {
                  return (
                    <tr key={obj.id}>
                      <td>
                        <LinkedPrice price={obj} />
                      </td>
                      <td>{Price.statusName(obj)}</td>
                      <td>{obj.name}</td>
                      <td>{obj.connector || "*"}</td>
                      <td>{obj.connector_out || "*"}</td>
                      <td>{obj.account?.user.username || "*"}</td>
                      <td>{obj.prefix || "*"}</td>
                      <td>{obj.charge}</td>
                      <td>{obj.charge_cost}</td>
                      <td>{obj.created}</td>
                      <td>
                        <img
                          src="/delete.png"
                          alt={"delete price " + obj.name}
                          onClick={() => deleter("price " + obj.name, obj.id)}
                        />
                      </td>
                    </tr>
                  )
                })
            ) : (
              <tr>
                <td colSpan={11}>No entries found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    </>
  )
}

export default Prices
