import { InvalidSender, MailDomain } from "../models/MailDomain"
import React from "react"

export const InvalidSenders = ({ maildomain }: { maildomain: MailDomain }) => {
  const nsenders = maildomain.invalid_senders.length
  if (nsenders < 1) return <></>
  return (
    <>
      Currently known invalid sender{nsenders === 1 ? "" : "s"}:
      <ul>
        {maildomain.invalid_senders.map((is: InvalidSender) => {
          return (
            <li key={is.id}>
              {is.email}, first seen at {is.seen_at}.
            </li>
          )
        })}
      </ul>
    </>
  )
}
