import React from "react"

export class Crumb {
  text: string = ""
  path?: string = ""
}

export class CrumbState {
  location: string = ""
  breadcrumbs: Array<Crumb> = []
  setBreadcrumbs: any
}

const defaultState: CrumbState = {
  location: "",
  breadcrumbs: [],
  setBreadcrumbs: undefined,
}

const BreadcrumbContext = React.createContext(defaultState)

export default BreadcrumbContext
