import { EmgUser } from "./EmgUser"

export class SenderPerMonth {
  id: number = 0
  email: string = ""
  month: string = ""
  count: number = 0
}

export class MailSender {
  id: number = 0
  maildomain_id: number = 0
  emguser_id: number = 0
  prepaid_pdus: number = 0
  packsize: number = 0
  confirm_fails_only: boolean = false
  from_address: string = ""
  name: string = ""
  sourceaddr: string = ""
  cost_center: string = ""
  itemno: string = ""
  invoiced_on: string = ""

  sender_per_month: Array<SenderPerMonth> = []

  index: number = 0
  latest_month: string = ""
}

export class RecipientAlias {
  id: number = 0
  alias: string = ""
  addresses: string = ""

  index: number = 0
}

export class InvalidSender {
  id: number = 0
  seen_at: string = ""
  email: string = ""
}

export class MailDomain {
  id: number = 0
  maxlen: number = 160
  domain: string = ""
  username: string = ""
  allowedsender: string = ""
  sourceaddr: string = ""
  usefrom: boolean = false
  usesubject: boolean = false
  sat: boolean = false
  dlr_footer: string = ""
  report_address: string = ""
  cc_text: string = ""

  emguser?: EmgUser = undefined
  senders: Array<MailSender> = []
  recipients: Array<RecipientAlias> = []
  invalid_senders: Array<InvalidSender> = []

  // when creating new sub entries
  new_alias: string = ""
  new_sender: string = ""

  static orderByName(a: MailDomain, b: MailDomain) {
    if (a.domain > b.domain) {
      return 1
    } else if (a.domain < b.domain) {
      return -1
    }
    return 0
  }
}
