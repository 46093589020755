import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { Customer } from "../models/Customer"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { FieldArray, Form, Formik, FormikValues } from "formik"
import { EmgUser } from "../models/EmgUser"
import { EmgAccount } from "../models/EmgAccount"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import PrimaryButton from "../helpers/PrimaryButton"
import { useCustomer, useFreeEMGUsers } from "../helpers/hooks"
import { ProductLicense } from "../models/product"
import LinkedEMGUser from "../components/LinkedEMGUser"
import LinkedLicense from "../components/LinkedLicense"
import { useQueryClient } from "react-query"

const CustomerEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const { customer, loading: customer_loading } = useCustomer(id!)
  const { freeUsers, loading: users_loading } = useFreeEMGUsers()
  const cache = useQueryClient()
  const handleError = useErrorHandler()
  const [selectedLicenseIndex, setLicenseIndex] = useState(-1)
  const loading = users_loading || customer_loading

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "Customers",
        path: routes.owner.root + routes.owner.Customers,
      },
      { text: "Customer" },
    ])
  }, [context, location])

  const connectUser = async (values: FormikValues, setSubmitting: any) => {
    console.log("submitting " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/owner/customer/" + customer.data.id + "/connect/" + values.id
      )
      console.log("got: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("error: " + JSON.stringify(error))
    } finally {
      setSubmitting(false)
    }
    return Promise.all([
      cache.invalidateQueries("customer"),
      cache.invalidateQueries("freeEMGUsers"),
    ])
  }

  const disconnectAfterConfirm = async (id: number, name: string) => {
    const rc = window.confirm(
      "Are you sure you want to disconnect user " + name + "?"
    )
    console.log("rc = " + rc)
    if (rc) {
      const result = await axios.post(
        "/api/owner/customer/" + customer.data.id + "/disconnect/" + id
      )
      console.log("result: " + JSON.stringify(result))
    }
    return Promise.all([
      cache.invalidateQueries(["customer", id]),
      cache.invalidateQueries("freeEMGUsers"),
    ])
  }

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/owner/customer/" + customer.data.id,
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (customer.data.id < 1) navigate("..")
    }
  }

  const updateLicense = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/owner/customer/" + customer.data.id + "/license",
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
    }
  }

  if (loading) return <>{loading}</>
  if (!customer.data) {
    return <>Customer not found.</>
  }
  return (
    <>
      <Formik
        initialValues={not_null(customer.data) as Customer}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values }) => (
          <>
            <Form className={"form"}>
              <TextField label="Name" name="name" />
              {/*
            <TextField label="Address 1" name="address1" />
            <TextField label="Address 2" name="address2" />
            <TextField label="Zip" name="zip" />
            <TextField label="City" name="city" />
            <TextField label="State" name="state" />
            <TextField label="Country" name="country" />
            <TextField label="Phone" name="phone" field_type="tel" />
            <TextField label="Fax" name="fax" field_type="tel" />
            <TextField label="Homepage" name="homepage" field_type="url" />
            <TextField label="Contact admin" name="contact_admin" />
            <TextField
              label="Email admin"
              name="email_admin"
              field_type="email"
            />
            <TextField label="Contact tech" name="contact_tech" />
            <TextField
              label="Email tech"
              name="email_tech"
              field_type="email"
            />
            <TextField label="Organization no" name="orgno" />
            <Checkbox label={"Report enabled"} name={"report_enabled"} />
            <TextField
              label="Email reports to"
              name="email_report"
              field_type="email"
            />
            */}
              <TextField label="Customer id" name="custno" />

              <PrimaryButton
                text={"Save"}
                isSubmitting={isSubmitting}
                disabled={values.name.length === 0}
              />
            </Form>
          </>
        )}
      </Formik>
      {customer.data.id > 0 && (
        <>
          <h2>Accounts</h2>
          <table className={"table table-sm table-striped"}>
            <thead>
              <tr>
                <th>Account</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {customer.data.accounts.map((account: EmgAccount) => {
                const user = account.user
                return (
                  <tr key={account.id}>
                    <td>
                      <LinkedEMGUser user={user} />
                    </td>
                    <td>
                      <img
                        src="/delete.png"
                        alt={"disconnect user " + user.username}
                        onClick={async () => {
                          return disconnectAfterConfirm(
                            user.userid,
                            user.username
                          )
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {freeUsers.data.length > 0 && (
            <Formik
              initialValues={{
                id: "" + (freeUsers.data[0] as EmgUser).userid,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                return connectUser(values, setSubmitting)
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <SelectEMGAccount
                    label={"Add user"}
                    name={"id"}
                    accounts={freeUsers.data.map((user: EmgUser) => {
                      // console.log("map user " + JSON.stringify(user))
                      return { id: user.userid, username: user.username }
                    })}
                  />
                  <PrimaryButton text={"Connect"} isSubmitting={isSubmitting} />
                </Form>
              )}
            </Formik>
          )}

          <h2>Licenses</h2>
          <Formik
            initialValues={customer.data}
            onSubmit={async (values, { setSubmitting }) => {
              return updateLicense(
                values.licenses[selectedLicenseIndex],
                setSubmitting
              )
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <FieldArray name="licenses">
                  {(functions) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            console.log("New license")
                            functions.push({
                              company: customer.data.name,
                              version: "7",
                              name: "EMG",
                              licno: "newlic",
                              mps: 0,
                            })
                            console.log(
                              "Licenses afterwards: " +
                                JSON.stringify(values.licenses)
                            )
                          }}
                        >
                          New license &raquo;
                        </div>
                        <table className={"table table-sm table-hover"}>
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Product</th>
                              <th>Version</th>
                              <th>License no</th>
                              <th>MPS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.licenses.map(
                              (license: ProductLicense, index: number) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      setLicenseIndex(index)
                                    }}
                                  >
                                    <td>{license.company}</td>
                                    <td>{license.name}</td>
                                    <td>{license.version}</td>
                                    <td>
                                      <LinkedLicense license={license} />
                                    </td>
                                    <td>{license.mps}</td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                        {selectedLicenseIndex > -1 && (
                          <>
                            <TextField
                              label={"Company Name"}
                              name={`licenses.${selectedLicenseIndex}.company`}
                            />
                            <TextField
                              label={"License no"}
                              name={`licenses.${selectedLicenseIndex}.licno`}
                            />
                            <TextField
                              label={"MPS"}
                              name={`licenses.${selectedLicenseIndex}.mps`}
                              field_type={"number"}
                              min_number={0}
                            />
                            <TextField
                              label={"Max Hosts"}
                              name={`licenses.${selectedLicenseIndex}.max_hosts`}
                              field_type={"number"}
                              min_number={1}
                            />
                            <PrimaryButton
                              text={"Save"}
                              isSubmitting={isSubmitting}
                            />
                          </>
                        )}
                      </>
                    )
                  }}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  )
}

export default CustomerEdit
