import React from "react"

const FilterField = (props: { valueProp: any; setValueProp: any }) => {
  return (
    <div className={"row mb-4 mt-4"}>
      <div className={"col-1 font-weight-bold align-middle"}>Filter</div>
      <div className={"col"}>
        <input
          type={"text"}
          size={32}
          value={props.valueProp}
          onChange={props.setValueProp}
        />
      </div>
    </div>
  )
}

export default FilterField
