import React from "react"
import { Link } from "react-router-dom"

interface MenuItemProps {
  text: string
  to: any
  dataTestid?: string
}

const MenuItem = (props: MenuItemProps) => {
  return (
    <li className={"nav-item active"} key={JSON.stringify(props.to)}>
      <Link className={"nav-link"} to={props.to}>
        {props.text}
      </Link>
    </li>
  )
}

const ListItem = (props: MenuItemProps) => {
  return (
    <li key={JSON.stringify(props.to)}>
      <Link to={props.to} data-testid={props.dataTestid}>
        {props.text} &raquo;
      </Link>
    </li>
  )
}

export { MenuItem, ListItem }
