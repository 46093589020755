import React, { useContext, useEffect } from "react"
import { usePhoneBooks } from "../helpers/hooks"
import { Link, useLocation } from "react-router-dom"
import { PhoneBook, PhoneBookType } from "../models/PhoneBook"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import routes from "../routes"
import AddNewLink from "../components/AddNewLink"

export const Phonebooks = () => {
  const { phonebooks, loading, deleter } = usePhoneBooks()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Phonebooks" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <>
      <h1>Phonebooks</h1>

      <>
        <p>
          Showing {phonebooks.data.length} phonebook
          {phonebooks.data.length === 1 ? "" : "s"}.{" "}
          <AddNewLink path={routes.user.root + routes.user.PhonebookEdit} />
        </p>
        {/* name type owner # delete */}
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Owner</th>
              <th>#</th>
              <th> </th>
            </tr>
          </thead>

          <tbody>
            {phonebooks.data.length > 0 ? (
              phonebooks.data.map((obj: PhoneBook) => {
                return (
                  <tr key={obj.id}>
                    <td>
                      <Link to={`/user/phonebook/${obj.id}`}>{obj.name}</Link>
                    </td>
                    <td>
                      {obj.type === PhoneBookType.Private
                        ? "Private"
                        : "Public"}
                    </td>
                    <td>{obj.user_id}</td>
                    <td>{obj.n_entries}</td>
                    <td>
                      <img
                        src="/delete.png"
                        alt={"delete phonebook " + obj.name}
                        onClick={() => deleter("phonebook " + obj.name, obj.id)}
                      />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={5}>No entries found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    </>
  )
}
