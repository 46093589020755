import React from "react"
import { Link } from "react-router-dom"
import routes from "../routes"
import { Satpool } from "../models/Satpool"

interface LinkedSatpoolProps {
  satpool: Satpool
}

const LinkedSatpool = (props: LinkedSatpoolProps) => {
  const satpool = props.satpool
  return (
    <Link
      to={
        routes.owner.root +
        routes.owner.config.root +
        routes.owner.config.Satpools +
        "/" +
        satpool.id
      }
    >
      {satpool.name}
    </Link>
  )
}

export default LinkedSatpool
