import React, { useContext, useEffect } from "react"
import axios from "axios"
import { MO_Route } from "../models/MO_Route"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import SelectConnector from "../helpers/SelectConnector"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useConnectors,
  useEMGUsersWithCompany,
  useMORoute,
} from "../helpers/hooks"
import UserContext from "../UserContext"

const MORouteEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const { route, loading: route_loading } = useMORoute(id!)
  const handleError = useErrorHandler()
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const { cs, loading: cs_loading } = useConnectors()
  const loading = route_loading || cs_loading || accounts_loading

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post("/api/owner/mo_route/" + id, values)
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (id === "0") navigate("..")
    }
  }

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "MO_Routes",
        path: routes.owner.root + routes.owner.MO_Routes,
      },
      { text: "MO_Route" },
    ])
  }, [context, location])

  if (loading) return <>{loading}</>

  return (
    <Formik
      initialValues={not_null(route.data) as MO_Route}
      onSubmit={(values, { setSubmitting }) =>
        submitData(values, setSubmitting)
      }
    >
      {({ isSubmitting, values }) => (
        <Form className={"form"}>
          <TextField
            label={"Destination address"}
            name={"destaddr"}
            required={true}
          />
          <TextField label={"Keyword"} name={"keyword"} />
          <SelectConnector
            label={"Route"}
            name={"route"}
            list={cs.data}
            required={true}
          />
          <SelectEMGAccount
            label={"Receiving user"}
            name={"route_emguser"}
            nullText={"Not set"}
            by_name={true}
            accounts={accounts.data}
          />
          <TextField
            label={"Charge"}
            name={"charge"}
            field_type={"number"}
            step={"any"}
            min_number={0}
          />
          <TextField
            label={"Cost"}
            name={"charge_cost"}
            field_type={"number"}
            step={"any"}
            min_number={0}
          />

          <PrimaryButton
            text={"Save"}
            isSubmitting={values.destaddr.length === 0 || isSubmitting}
          />
        </Form>
      )}
    </Formik>
  )
}

export default MORouteEdit
