import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import PrimaryButton from "../helpers/PrimaryButton"
import { useErrorHandler } from "react-error-boundary"
import { useEMGUserMe } from "../helpers/hooks"
import BreadcrumbContext from "../BreadcrumbContext"
import { useLocation } from "react-router-dom"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"

interface HLRForm {
  msisdn: string
}

class HLRForm implements HLRForm {
  constructor() {
    this.msisdn = ""
  }
}

export const HLRLookup = () => {
  const handleError = useErrorHandler()
  const [spinner, setSpinner] = useState("")
  const [hlr, setHLR] = useState("")
  const { emguser, loading } = useEMGUserMe()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "HLR Lookup" }])
  }, [context, location])

  if (loading) return <>{loading}</>

  const dolookup = async (values: HLRForm, setSubmitting: any) => {
    setHLR("")
    setSpinner("Looking up " + values.msisdn + "...")
    try {
      const res = await axios.post("/api/user/hlr/lookup", {
        values,
      })
      console.log("response:", JSON.stringify(res.data))
      const hlrinfo = res.data
      setHLR(
        "MSISDN " +
          values.msisdn +
          " has MCC " +
          hlrinfo.mcc +
          " and MNC " +
          hlrinfo.mnc +
          "."
      )
    } catch (error) {
      console.log("lookup error", JSON.stringify(error))
      handleError(error)
    } finally {
      setSpinner("")
      setSubmitting(false)
    }
  }

  const credits = emguser?.creditslookup || 0
  const emguser_name = emguser?.username || ""

  return (
    <>
      <h1>HLR Lookup</h1>
      {(credits > 0 && (
        <>
          <p>
            Your EMG account "{emguser_name}" has {credits} remaining lookup
            credits.
          </p>
          <Formik
            initialValues={new HLRForm()}
            onSubmit={async (values, { setSubmitting }) => {
              return dolookup(values, setSubmitting)
            }}
          >
            {({ isSubmitting, values }) => (
              <Form className="form">
                <TextField label={"MSISDN"} name={"msisdn"} required={true} />
                {spinner}
                <PrimaryButton
                  text={"Lookup"}
                  isSubmitting={isSubmitting}
                  disabled={values.msisdn.length === 0}
                />
                {hlr}
              </Form>
            )}
          </Formik>
        </>
      )) || (
        <p>
          This feature requires prepaid lookup credits. Please send an email to
          support@braxo.se for details, or see{" "}
          <a
            href={"https://smssubmit.se/hlr-lookup"}
            target={"_blank"}
            rel={"noopener"}
          >
            here
          </a>
          .
        </p>
      )}
    </>
  )
}
