import React from "react"
import { Link } from "react-router-dom"
import User from "../models/User"
import routes from "../routes"

interface LinkedUserProps {
  user: User
}

const LinkedUser = (props: LinkedUserProps) => {
  const user = props.user
  return (
    <Link to={routes.admin.root + routes.admin.Users + "/" + user.id}>
      {user.username}
    </Link>
  )
}

export default LinkedUser
