import React, { useContext } from "react"
import { Link } from "react-router-dom"
import User from "./User"
import UserLine from "./components/UserLine"
import routes from "./routes"
import BreadcrumbContext from "./BreadcrumbContext"
import UserContext from "./UserContext"
import { MenuItem } from "./MenuItem"

interface MainMenuProps {
  setUser: (user: User) => void
}

const MainMenu = (props: MainMenuProps) => {
  const user = useContext(UserContext)

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"> </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <MenuItem text="Dashboard" to="/" />
            {user.role === "OWNER" && (
              <MenuItem
                text={"Phonebooks"}
                to={routes.user.root + routes.user.Phonebooks}
              />
            )}
            <MenuItem
              text={"Send SMS"}
              to={routes.user.root + routes.user.SendSMS}
            />
            <MenuItem
              text={"HLR Lookup"}
              to={routes.user.root + routes.user.HLRLookup}
            />
            {user.role === "OWNER" && (
              <MenuItem
                text={"Settings"}
                to={routes.user.root + routes.user.Settings}
              />
            )}
          </ul>
          <UserLine setUser={props.setUser} />
        </div>
      </nav>

      <div>
        <nav>
          <ol className={"breadcrumb"}>
            <li key={0} className={"breadcrumb-item"}>
              <Link to={routes.root.path}>Home</Link>
            </li>
            <BreadcrumbContext.Consumer>
              {(value) => {
                if (value.breadcrumbs) {
                  return value.breadcrumbs.map((obj, index) => {
                    return (
                      <li key={index} className={"breadcrumb-item"}>
                        {obj.path ? (
                          <Link to={obj.path}>{obj.text}</Link>
                        ) : (
                          <span>{obj.text}</span>
                        )}
                      </li>
                    )
                  })
                }
              }}
            </BreadcrumbContext.Consumer>
          </ol>
        </nav>
      </div>
    </>
  )
}

export default MainMenu
