import React from "react"
import SelectNamed from "./SelectNamed"

type AccountEntry = {
  id: number
  username: string
  customer_name?: string
  cost_center?: string
}

const SelectEMGAccount = (props: {
  label: string
  name: string
  accounts: Array<AccountEntry>
  nullText?: string
  required?: boolean
  by_name?: boolean
  disabled?: boolean
}) => {
  return (
    <SelectNamed
      label={props.label}
      name={props.name}
      list={props.accounts.map((entry) => {
        return {
          text:
            entry.username +
            (entry.customer_name
              ? " (" +
                entry.customer_name +
                (entry.cost_center ? " / " + entry.cost_center : "") +
                ")"
              : ""),
          value: props.by_name ? entry.username : entry.id,
        }
      })}
      nullText={props.nullText}
      required={props.required}
      disabled={props.disabled}
    />
  )
}

export default SelectEMGAccount
