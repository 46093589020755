import { QE } from "../helpers/emg"

export class ConnectorOption {
  keyname: string = ""
  value: string = ""
  disabled: boolean = false
  note: string = ""

  toDelete?: boolean;

  [fieldKey: string]: any // annoying
}

export class Connector {
  id: number = 0
  name: string = ""
  disabled: boolean = false
  note: string = ""

  address: string = ""
  idletimeout: string = ""
  inherit: string = ""
  instances: string = "0"
  keepalive: string = ""
  password: string = ""
  protocol: string = "SMPP"
  static: boolean = false
  type: string = "INCOMING"
  userdb: string = ""
  username: string = ""
  users: string = ""
  virtual: boolean = false

  options: Array<ConnectorOption> = [];
  [fieldKey: string]: any // annoying

  static coreFields: Array<string> = [
    "address",
    "idletimeout",
    "inherit",
    "instances",
    "keepalive",
    "protocol",
    "type",
    "virtual",
  ]

  static incomingFields: Array<string> = ["userdb", "users"]

  static outgoingFields: Array<string> = ["username", "password", "static"]

  static allFields = Connector.coreFields
    .concat(Connector.incomingFields)
    .concat(Connector.outgoingFields)

  static fromJSON(data: string): Connector {
    let c0: Connector = JSON.parse(data)
    return Connector.includeOptions(c0)
  }

  static includeOptions(c0: Connector) {
    if (!c0.options) c0.options = []
    const c = new Connector()
    ;["name", "disabled", "note"].forEach((key) => {
      c[key] = c0[key]
    })
    c.id = c0.id
    let o2: Array<ConnectorOption> = []
    c0.options.forEach((co: ConnectorOption) => {
      const lowkey = co.keyname.toLowerCase()
      // console.log('move ' + JSON.stringify(co))
      if (Connector.allFields.includes(lowkey)) {
        if (lowkey === "virtual" || lowkey === "static") {
          c[lowkey] = co.value === "1"
        } else {
          c[lowkey] = co.value
        }
      } else {
        const co2 = new ConnectorOption()
        ;["id", "keyname", "value", "note", "disabled"].forEach((key) => {
          co2[key] = co[key] !== null ? co[key] : ""
        })
        o2.push(co2)
      }
    })
    o2.push(new ConnectorOption()) // for new/edit
    o2.forEach((co: ConnectorOption) => {
      co.toDelete = false
    })
    c.options = o2
    /*
    if (process.env.NODE_ENV !== "test")
      console.log("Modified: " + JSON.stringify(c))
    */
    return c
  }

  static send_option(keyname: string, value: any): boolean {
    //console.log("check keyname", keyname, "value", JSON.stringify(value))
    if (keyname === "inherit") {
      return ("" + value).length > 0
    }
    if (keyname === "static") {
      return value === "1" || value === true
    }
    if (keyname === "virtual") {
      return value === "1" || value === true
    }
    if (keyname === "idletimeout") {
      return ("" + value).length > 0
    }
    if (keyname === "keepalive") {
      return ("" + value).length > 0
    }
    if (keyname === "userdb") {
      return ("" + value).length > 0
    }
    if (keyname === "users") {
      return ("" + value).length > 0
    }
    if (keyname === "username") {
      return ("" + value).length > 0
    }
    if (keyname === "password") {
      return ("" + value).length > 0
    }
    return true
  }

  static toServer(c: Connector): Object {
    const c0 = new Connector()
    ;["id", "name", "disabled", "note"].forEach((key) => {
      c0[key] = c[key]
    })
    const c0options: Array<ConnectorOption> = c.options.filter((co) => {
      return co.keyname.length > 0 && !co.toDelete
    })
    Connector.coreFields.forEach((keyname) => {
      //console.log("copy common field " + keyname)
      if (this.send_option(keyname, c[keyname])) {
        const co: ConnectorOption = new ConnectorOption()
        co.keyname = keyname
        co.value = c[keyname]
        c0options.push(co)
      }
    })
    // console.log("c.type: " + c.type)
    if (c.type === "INCOMING") {
      Connector.incomingFields.forEach((keyname) => {
        // console.log("copy incoming field " + keyname)
        if (this.send_option(keyname, c[keyname])) {
          const co: ConnectorOption = new ConnectorOption()
          co.keyname = keyname
          co.value = c[keyname]
          c0options.push(co)
        }
      })
    } else {
      Connector.outgoingFields.forEach((keyname) => {
        // console.log("copy outgoing field " + keyname)
        if (this.send_option(keyname, c[keyname])) {
          const co: ConnectorOption = new ConnectorOption()
          co.keyname = keyname
          co.value = c[keyname]
          c0options.push(co)
        }
      })
    }
    c0options.forEach((co: ConnectorOption) => {
      // console.log("Uppercase option " + co.keyname)
      co.keyname = co.keyname.toUpperCase()
      co.disabled = !!co.disabled
    })
    c0.options = c0options
    console.log("For the server: " + JSON.stringify(c0))
    return c0
  }

  static isTrue(s: string): boolean {
    return !!s && s.length > 0 && parseInt(s) !== 0
  }
}

export class ActiveConnector {
  state: number = 0
  queue: Array<QE> = []
  queuesize: number = 0
}
