import { EmgAccount } from "./EmgAccount"

export enum RouteStatus {
  Status_Inactive = 0,
  Status_Active = 1,
  Status_Deleted = 2,
}

export class Route {
  id: number = 0
  name: string = ""
  emgaccount_id: number = 0
  connector: string = ""
  priority: number = 3
  status: RouteStatus = RouteStatus.Status_Active
  prefix_type: number = 0
  prefix: string = ""
  route: string = ""
  route_emguser: string = ""
  valid_destaddr_reject_invalid: boolean = false
  valid_destaddr_regexp: string = ""
  created: string = ""

  account: EmgAccount = new EmgAccount()

  static statusName(obj: Route) {
    switch (obj.status) {
      case RouteStatus.Status_Inactive:
        return "Inactive"
      case RouteStatus.Status_Active:
        return "Active"
      case RouteStatus.Status_Deleted:
        return "Deleted"
    }
    return "?"
  }
}
