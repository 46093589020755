import React, { useContext, useEffect, useState } from "react"
import { BalanceUpdate, EmgUser } from "../models/EmgUser"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useEMGUsage, useEMGUsers } from "../helpers/hooks"
import Modal from "react-modal"
import { useLocalStorage } from "../helpers/useLocalStorage"
import UserContext from "../UserContext"
import LinkedCustomer from "../components/LinkedCustomer"
import LinkedEMGUser from "../components/LinkedEMGUser"
import AddNewLink from "../components/AddNewLink"
import FilterField from "../helpers/FilterField"
import * as V from "victory"

enum SortFields {
  SORT_USERNAME,
  SORT_CUSTOMER,
  SORT_LOGIN,
  SORT_BALANCE,
}

interface UserDetailsProps {
  selectedUser: EmgUser
  hideDetails: any
}

const PaymentDetails = (props: UserDetailsProps) => {
  const selectedUser = props.selectedUser

  return (
    <Modal isOpen={true} onRequestClose={props.hideDetails}>
      <h2>Updates for {selectedUser.username}</h2>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {selectedUser.balance_updates
            .sort(BalanceUpdate.createdDesc)
            .map((obj) => (
              <tr key={obj.id}>
                <td>{obj.created_at}</td>
                <td>{obj.amount}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Modal>
  )
}

const UsageDetails = (props: UserDetailsProps) => {
  const selectedUser = props.selectedUser
  const { usage, loading } = useEMGUsage(selectedUser.username)
  const values = usage.map((row: any) => row.sms)

  if (loading) return <>Loading.</>

  return (
    <Modal isOpen={true} onRequestClose={props.hideDetails}>
      <h2>Usage for {selectedUser.username}</h2>
      <V.VictoryChart
        domainPadding={20}
        theme={V.VictoryTheme.material}
        height={200}
        style={{ parent: { height: "80%" /*, border: "1px solid red" */ } }}
        padding={{ top: 0, bottom: 60, left: 60, right: 10 }}
      >
        <V.VictoryAxis
          tickValues={usage.map((row: any) => row.month)}
          tickLabelComponent={
            <V.VictoryLabel
              angle={-90}
              dx={-20}
              dy={-6}
              style={[{ fontSize: 12 }]}
            />
          }
        />
        <V.VictoryAxis
          dependentAxis
          tickFormat={(x) => `${x}`}
          tickLabelComponent={<V.VictoryLabel style={[{ fontSize: 12 }]} />}
          domain={[0, Math.max(1, ...values) * 1.2]}
        />
        <V.VictoryLine
          data={usage}
          x={"month"}
          y={"sms"}
          labels={values}
          labelComponent={
            <V.VictoryLabel
              style={[{ fontSize: 12 }]}
              angle={-90}
              dx={24}
              dy={6}
            />
          }
          interpolation={"catmullRom"} // "basis", "cardinal", "catmullRom", "linear"
        />
        <V.VictoryScatter data={usage} x={"month"} y={"sms"} />
      </V.VictoryChart>
    </Modal>
  )
}

const EmgUsers = () => {
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { emgusers, loading, deleter } = useEMGUsers(role)
  const [selectedUser, setSelectedUser] = useState(new EmgUser())
  const [selectedTrafficUser, setSelectedTrafficUser] = useState(new EmgUser())
  const [sortField, setSortField] = useLocalStorage(
    "EmgUsers.sortField",
    SortFields.SORT_USERNAME
  )
  const [filter, setFilter] = useState("")

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "EMG Accounts" }])
  }, [context, location])

  if (loading) return <>{loading}</>

  const showUpdates = (
    event: React.MouseEvent<HTMLAnchorElement>,
    entry: EmgUser
  ) => {
    console.log("show details for " + JSON.stringify(entry))
    event.preventDefault()
    setSelectedUser(entry)
  }

  const showUsage = (
    event: React.MouseEvent<HTMLAnchorElement>,
    entry: EmgUser
  ) => {
    console.log("show usage for " + JSON.stringify(entry))
    event.preventDefault()
    setSelectedTrafficUser(entry)
  }

  const user_cmp = (u1: EmgUser, u2: EmgUser) => {
    if (sortField === SortFields.SORT_BALANCE) {
      if (u1.charge_balance < u2.charge_balance) return -1
      if (u1.charge_balance > u2.charge_balance) return 1
      if (u1.creditslookup < u2.creditslookup) return -1
      if (u1.creditslookup > u2.creditslookup) return 1
    }
    if (sortField === SortFields.SORT_LOGIN) {
      if (!u1.lastlogin && u2.lastlogin) return 1
      if (!u2.lastlogin && u1.lastlogin) return -1
      if (u1.lastlogin && u2.lastlogin) {
        if (u1.lastlogin < u2.lastlogin) return 1
        if (u1.lastlogin > u2.lastlogin) return -1
      }
    }
    if (sortField !== SortFields.SORT_USERNAME) {
      if (!u1.account && u2.account) return -1
      if (!u2.account && u1.account) return 1
      if (u1.account && u2.account) {
        if (u1.account.customer.name < u2.account.customer.name) return -1
        if (u1.account.customer.name > u2.account.customer.name) return 1
      }
    }
    if (u1.username < u2.username) return -1
    if (u1.username > u2.username) return 1
    return 0
  }

  const filter_user = (entry: EmgUser) => {
    const filter_text = filter.toLowerCase()
    if (entry.username.toLowerCase().indexOf(filter_text) > -1) return true
    const customer = entry.account?.customer
    if (
      customer &&
      customer.name &&
      customer.name.toLowerCase().indexOf(filter_text) > -1
    )
      return true
    return false
  }

  const updateFilter = (e: any) => {
    setFilter(e.target.value)
  }

  return (
    <>
      <h1>Configured EMG accounts</h1>
      <p>
        EMG accounts are used by the EMG server for authenticating clients that
        connect to send and receive messages.
      </p>
      <p>
        There {emgusers.length === 1 ? "is" : "are"} {emgusers.length} EMG user
        {emgusers.length === 1 ? "" : "s"} defined.{" "}
        {role === "owner" && <AddNewLink path={":id"} />}
      </p>
      <FilterField valueProp={filter} setValueProp={updateFilter} />
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th
              onClick={() => {
                setSortField(SortFields.SORT_USERNAME)
              }}
            >
              Username
            </th>
            {role === "owner" && (
              <th
                onClick={() => {
                  setSortField(SortFields.SORT_CUSTOMER)
                }}
              >
                Customer
              </th>
            )}
            <th>Cost&nbsp;center</th>
            <th
              onClick={() => {
                setSortField(SortFields.SORT_LOGIN)
              }}
            >
              Last login
            </th>
            {/* role === "owner" && <th>SMS this month</th> */}
            <th
              onClick={() => {
                setSortField(SortFields.SORT_BALANCE)
              }}
            >
              Account balance
            </th>
            <th>Lookup credit</th>
            {role === "owner" && <th>Updates</th>}
            {role === "owner" && <th>Usage</th>}
            <th>Allow post-paid</th>
            {role === "owner" && <th>Delete</th>}
          </tr>
        </thead>

        <tbody>
          {emgusers.sort(user_cmp).map((obj: EmgUser) => {
            if (!filter_user(obj)) return <tr key={obj.userid} hidden={true} />
            return (
              <tr key={obj.userid}>
                <td>
                  <LinkedEMGUser user={obj} />
                </td>
                {role === "owner" && (
                  <td>
                    <LinkedCustomer
                      customer={obj.account?.customer}
                      emptyText={"-Not assigned-"}
                    />
                  </td>
                )}
                <td>
                  {("" + obj.extra3).length > 0 && (
                    <div>&nbsp;{obj.extra3}</div>
                  )}
                </td>
                <td>{obj.lastlogin ? obj.lastlogin : "-"}</td>
                {/* role === "owner" && <td>0</td> */}
                <td>{obj.charge_balance}</td>
                <td>{obj.creditslookup}</td>
                {role === "owner" && (
                  <>
                    <td>
                      {obj.balance_updates.length > 0 ? (
                        <a
                          href={"#updates"}
                          onClick={(event) => showUpdates(event, obj)}
                        >
                          Show
                        </a>
                      ) : (
                        "None"
                      )}
                    </td>
                    <td>
                      <a
                        href={"#traffic"}
                        onClick={(event) => showUsage(event, obj)}
                      >
                        Show
                      </a>
                    </td>
                  </>
                )}
                <td>{obj.allowpostpaid ? "Yes" : ""}</td>
                {role === "owner" && (
                  <td>
                    <img
                      src="/delete.png"
                      alt={"delete emguser " + obj.username}
                      onClick={() =>
                        deleter("EMG user " + obj.username, obj.userid)
                      }
                    />
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
      {role === "owner" && (
        <>
          {selectedUser.userid > 0 && (
            <PaymentDetails
              selectedUser={selectedUser}
              hideDetails={() => {
                setSelectedUser(new EmgUser())
              }}
            />
          )}
          {selectedTrafficUser.userid > 0 && (
            <UsageDetails
              selectedUser={selectedTrafficUser}
              hideDetails={() => {
                setSelectedTrafficUser(new EmgUser())
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default EmgUsers
