import React from "react"
import { Customer } from "../models/Customer"
import { Link } from "react-router-dom"
import routes from "../routes"

interface LinkedCustomerProps {
  customer?: Customer
  emptyText?: string
}

const LinkedCustomer = (props: LinkedCustomerProps) => {
  const customer = props.customer
  if (customer) {
    return (
      <Link to={routes.owner.root + routes.owner.Customers + "/" + customer.id}>
        {customer.name}
      </Link>
    )
  }
  return <>{props.emptyText}</>
}

export default LinkedCustomer
