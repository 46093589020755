import React, { useContext, useEffect } from "react"
import { Connector } from "../../models/Connector"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../../BreadcrumbContext"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import { useConfiguredConnectors } from "../../helpers/hooks"
import LinkedConnector from "../../components/LinkedConnector"
import AddNewLink from "../../components/AddNewLink"

const Connectors = () => {
  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Connectors" }])
  }, [context, location])

  const { cs, loading, deleter } = useConfiguredConnectors()

  if (loading) return <>{loading}</>

  const italicIfVirtual = (c: Connector) => {
    //console.log("Virtual?", JSON.stringify(c))
    return c.virtual ? { className: "font-italic" } : {}
  }

  return (
    <>
      <h1>Connectors</h1>
      <p>
        There {cs.data.length === 1 ? "is" : "are"} {cs.data.length} connector
        {cs.data.length === 1 ? "" : "s"} defined. <AddNewLink path={":id"} />
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Protocol</th>
            <th>Address</th>
            <th>Instances</th>
            <th>Inherits from</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          {cs.data.map((c: Connector) => (
            <tr key={c.id}>
              <td {...italicIfVirtual(c)}>
                <LinkedConnector connector={c} />
              </td>
              <td>{c.type}</td>
              <td>{c.protocol}</td>
              <td>{c.address}</td>
              <td>{c.instances}</td>
              <td>{c.inherit}</td>
              <td>
                <img
                  src="/delete.png"
                  alt={"delete connector " + c.name}
                  onClick={() => deleter("connector " + c.name, c.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default Connectors
