import React, { useContext, useEffect } from "react"
import { MailDomain } from "../models/MailDomain"
import { useLocation } from "react-router-dom"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import { useMailDomains } from "../helpers/hooks"
import UserContext from "../UserContext"
import LinkedEMGUser from "../components/LinkedEMGUser"
import AddNewLink from "../components/AddNewLink"
import LinkedMailDomain from "../components/LinkedMailDomain"

const MailDomains = () => {
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const { maildomains, loading, deleter } = useMailDomains(role)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "MailDomains" }])
  }, [context, location])

  if (loading) return <>{loading}</>
  return (
    <div>
      <h1>Mail Domains</h1>
      <p>
        There {maildomains.data.length === 1 ? "is" : "are"}{" "}
        {maildomains.data.length} mail domain
        {maildomains.data.length === 1 ? "" : "s"} defined.{" "}
        <AddNewLink path={":id"} />
      </p>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Domain</th>
            <th>EMG Username</th>
            <th>Allowed Sender</th>
            <th>Source Address</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          {maildomains.data.length > 0 ? (
            maildomains.data
              .sort(MailDomain.orderByName)
              .map((obj: MailDomain) => (
                <tr key={obj.id}>
                  <td>
                    <LinkedMailDomain maildomain={obj} />
                  </td>
                  <td>
                    <LinkedEMGUser user={obj.emguser} />
                  </td>
                  <td>{obj.allowedsender}</td>
                  <td>{obj.sourceaddr}</td>
                  <td>
                    <img
                      src="/delete.png"
                      alt={"delete maildomain " + obj.domain}
                      onClick={() =>
                        deleter("mail domain " + obj.domain, obj.id)
                      }
                    />
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={5}>No entries found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default MailDomains
