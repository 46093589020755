// https://tc39.es/ecma402/#numberformat-objects

export const numberFormatter = new Intl.NumberFormat("en")

export const percentFormatter = new Intl.NumberFormat("en", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  maximumSignificantDigits: 4,
})
