import React, { useContext, useEffect, useState } from "react"
import { EMGConnector, EMGInstance, stateName } from "../../helpers/emg"
import { Link, useLocation } from "react-router-dom"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import BreadcrumbContext from "../../BreadcrumbContext"
import { useConnectors } from "../../helpers/hooks"

const nullConnector = { name: "", type: "", children: [] }

const ConnectorStatus = () => {
  const { cs, loading } = useConnectors()
  const [{ showInstances, showConnector }, setShowInfo] = useState({
    showInstances: false,
    showConnector: nullConnector,
  })

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Connectors" }])
  }, [context, location])

  if (loading) return <>{loading}</>

  const showInfo = (index: number) => {
    console.log("show connector info for " + index)
    setShowInfo({ showInstances: true, showConnector: cs.data[index] })
  }

  const ConnectorInstances = () => {
    return (
      <div
        id="statusinfo"
        onClick={() =>
          setShowInfo({ showInstances: false, showConnector: nullConnector })
        }
      >
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <caption className={"xsr-only"}>
              Connector instances for {showConnector.name}.
            </caption>
            <thead>
              <tr>
                <th>Instance</th>
                <th>State</th>
                <th>Mode</th>
                <th>IP</th>
                {showConnector.type === "INCOMING" && <th>Username</th>}
              </tr>
            </thead>
            <tbody>
              {showConnector.children.map((i: EMGInstance, index: number) => {
                return (
                  <tr key={index}>
                    <td>{i.instance}</td>
                    <td>{i.state}</td>
                    <td>{i.mode}</td>
                    <td>{i.remoteip}</td>
                    {showConnector.type === "INCOMING" && <td>{i.username}</td>}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <>
      <h1>Connectors</h1>

      <p>
        There {cs.data.length === 1 ? "is " : "are "}
        {cs.data.length}
        {" active connector"}
        {cs.data.length === 1 ? "" : "s"}.
      </p>

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <caption className={"sr-only"}>
            List of configured connectors.
          </caption>
          <thead>
            <tr>
              <th>Connector</th>
              <th>Type</th>
              <th>Protocol</th>
              <th>Instances</th>
              <th>Used</th>
              <th>State</th>
              <th>Queue size</th>
              <th>(/max)</th>
              <th>Avg 1M</th>
              <th>Avg 5M</th>
              <th>Avg 15M</th>
            </tr>
          </thead>
          <tbody>
            {(cs.data || []).map((c: EMGConnector, index: number) => {
              return (
                <tr key={index}>
                  <td>{c.name}</td>
                  <td>{c.type}</td>
                  <td>{c.protocol}</td>
                  <td onClick={() => showInfo(index)}>{c.instances}</td>
                  <td>{c.used}</td>
                  <td>{stateName(c.state)}</td>
                  <td>
                    <Link to={`/owner/server/queue/${c.name}`}>
                      {c.queuesize}
                    </Link>
                  </td>
                  <td>{c.max_queuesize && <span>/{c.max_queuesize}</span>}</td>
                  <td>{c.qstats1}</td>
                  <td>{c.qstats5}</td>
                  <td>{c.qstats15}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {showInstances && <ConnectorInstances />}
    </>
  )
}

export default ConnectorStatus
