import React from "react"
import { Field } from "formik"

const SelectNamed = (props: {
  label: string
  sublabel?: string
  name: string
  list: Array<{ text: string; value?: any }>
  nullText?: string
  required?: boolean
  disabled?: boolean
}) => {
  return (
    <div className={"form-group row"}>
      <label className={"col-form-label col-sm-3"}>
        {props.label}
        {props.required && <span className="required"> *</span>}
        {props.sublabel && (
          <>
            <br />
            <div style={{ fontWeight: "normal" }}>{props.sublabel}</div>
          </>
        )}
      </label>
      <Field
        className={"form-control-sm"}
        as="select"
        name={props.name}
        disabled={props.disabled}
      >
        {props.nullText && (
          <option key="-1" value={""}>
            {props.nullText === "*"
              ? props.nullText
              : "─ " + props.nullText + " ─"}
          </option>
        )}
        {props.list.map((obj, index) => {
          return (
            <option
              key={index}
              value={("" + obj.value).length > 0 ? obj.value : obj.text}
            >
              {obj.text}
            </option>
          )
        })}
      </Field>
    </div>
  )
}

export default SelectNamed
