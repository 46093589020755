import React from "react"
import { Link } from "react-router-dom"
import routes from "../routes"
import { Route } from "../models/Route"

interface LinkedRouteProps {
  route: Route
}

const LinkedRoute = (props: LinkedRouteProps) => {
  const route = props.route
  return (
    <Link to={routes.owner.root + routes.owner.Routes + "/" + route.id}>
      {route.id}
    </Link>
  )
}

export default LinkedRoute
