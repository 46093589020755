import React from "react"
import { Field } from "formik"

const Checkbox = (props: {
  label: string
  sublabel?: string
  name: string
  disabled?: boolean
}) => {
  return (
    <div className={"form-group row"}>
      <label className={"form-check-label col-sm-3"}>{" " + props.label}</label>
      <div className={"form-check"}>
        <Field
          className={"form-check-input"}
          type={"checkbox"}
          name={props.name}
          disabled={props.disabled}
        />
      </div>
    </div>
  )
}

export default Checkbox
