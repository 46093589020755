import React from "react"
import { useErrorHandler } from "react-error-boundary"
import { Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useUser } from "../helpers/hooks"
import PrimaryButton from "../helpers/PrimaryButton"
import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"

interface PasswordForm {
  password: string
  password2: string
}

export const SetPassword = () => {
  const { uuid } = useParams<string>()
  const navigate = useNavigate()
  const { user, loading } = useUser("site", uuid!)
  const cache = useQueryClient()
  const handleError = useErrorHandler()

  console.log("Set password for user with token " + uuid)

  const { mutate: updateUser } = useMutation(async (values: PasswordForm) => {
    return axios.post("/api/site/verify/" + user.data.id, {
      password: values.password,
    })
  })

  if (!uuid) return <></>
  if (loading) return <>{loading}</>

  const can_submit = (values: any) => {
    // console.log("validate: " + JSON.stringify(values))
    return values.password.length > 0 && values.password === values.password2
  }

  const submitData = async (values: PasswordForm, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updateUser(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      await cache.invalidateQueries("user")
      navigate("/")
    }
  }

  return (
    <>
      <h1>Set Password for {user.data.email}</h1>
      <Formik
        initialValues={{ password: "", password2: "" } as PasswordForm}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={"form"}>
            <TextField label="Password" name="password" />
            <TextField label="Same password" name="password2" />
            <PrimaryButton
              text={"Set Password"}
              isSubmitting={isSubmitting}
              disabled={!can_submit(values)}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
