import { Price } from "./Price"

export class HourlySummary {
  day: string = ""
  month: string = ""
  prefix: string = ""
  connector_out: string = ""
  username: string = ""
  xcount: number = 0
  msg_count: number = 0
  msg_count_ok: number = 0
  msg_count_relayed: number = 0
  charge: number = 0.0
  charge_cost: number = 0.0
  price?: Price = undefined

  constructor(d: string) {
    this.day = d
  }

  static msg_count_failed(e: HourlySummary) {
    return e.msg_count - e.msg_count_ok - e.msg_count_relayed
  }

  static margin(e: HourlySummary) {
    return e.charge - e.charge_cost
  }

  static margin_pc(e: HourlySummary) {
    return e.charge > 0 ? (e.charge - e.charge_cost) / e.charge : 0.0
  }

  static pc_ok(e: HourlySummary) {
    return e.msg_count > 0 ? e.msg_count_ok / e.msg_count : 0
  }

  static pc_failed(e: HourlySummary) {
    return e.msg_count > 0 ? this.msg_count_failed(e) / e.msg_count : 0
  }

  static pc_relayed(e: HourlySummary) {
    return e.msg_count > 0 ? e.msg_count_relayed / e.msg_count : 0
  }
}
