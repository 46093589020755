import React, { useContext, useEffect } from "react"
import axios from "axios"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { usePhoneBook } from "../helpers/hooks"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import routes from "../routes"
import { Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import { PBEntry, PhoneBook, PhoneBookType } from "../models/PhoneBook"
import TextField from "../helpers/TextField"
import PrimaryButton from "../helpers/PrimaryButton"
import SelectNamed from "../helpers/SelectNamed"
import { useMutation, useQueryClient } from "react-query"
import { useErrorHandler } from "react-error-boundary"
import AddNewLink from "../components/AddNewLink"

export const PhoneBookEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const pb = usePhoneBook(id!)
  const handleError = useErrorHandler()
  const cache = useQueryClient()

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      { text: "Phonebooks", path: routes.user.root + routes.user.Phonebooks },
      { text: "Phonebook" },
    ])
  }, [context, location])

  const { mutate: updatePhoneBook } = useMutation(async (values: object) => {
    await axios.post("/api/user/pb/" + id, values)
    return cache.invalidateQueries("phonebook")
  })

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updatePhoneBook(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (pb.data.id < 1) navigate(routes.user.root + routes.user.Phonebooks)
    }
  }

  const deleteAfterConfirm = async (id: number, name: string) => {
    const rc = window.confirm(
      "Are you sure you want to delete the phonebook entry for " + name + "?"
    )
    console.log("rc = " + rc)
    if (rc) {
      await axios.delete("/api/user/pbentry/" + id)
    }
    return Promise.all([
      cache.invalidateQueries("pbentry"),
      cache.invalidateQueries("phonebook"),
    ])
  }

  return (
    (pb.isSuccess && pb.data && (
      <>
        <Formik
          initialValues={not_null(pb.data) as PhoneBook}
          onSubmit={async (values, { setSubmitting }) => {
            return submitData(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form className={"form"}>
              <TextField label={"Name"} name={"name"} />
              <SelectNamed
                label={"Type"}
                name={"type"}
                list={[
                  { text: "Private", value: PhoneBookType.Private },
                  { text: "Public", value: PhoneBookType.Public },
                ]}
              />
              <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
        {pb.data.id > 0 && (
          <>
            <h2>Recipients</h2>
            <AddNewLink path={"/user/phonebook/" + pb.data.id + "/entry/:id"} />
            {/* company name phone */}
            <table className={"table table-sm table-striped"}>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {pb.data.entries.map((entry: PBEntry, key: number) => {
                  return (
                    <tr key={key}>
                      <td>{entry.company}</td>
                      <td>
                        <Link
                          to={
                            "/user/phonebook/" +
                            pb.data.id +
                            "/entry/" +
                            entry.id
                          }
                        >
                          {entry.name}
                        </Link>
                      </td>
                      <td>{entry.destaddr}</td>
                      <td>
                        <img
                          src="/delete.png"
                          alt={"delete entry for " + entry.name}
                          onClick={async () => {
                            return deleteAfterConfirm(entry.id, entry.name)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </>
    )) || <>Loading.</>
  )
}
