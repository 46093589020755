import React from "react"

const PrimaryButton = (props: {
  text: string
  isSubmitting: any
  disabled?: boolean
}) => {
  return (
    <div className="form-group row my-auto">
      <div className={"col-sm-3"} />
      <div className={"col-auto"}>
        <button
          className={"btn btn-primary"}
          type="submit"
          disabled={props.isSubmitting || props.disabled}
        >
          {props.text}
        </button>
      </div>
      <div className={"col"} />
    </div>
  )
}

export default PrimaryButton
