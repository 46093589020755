export interface EMGInstance {
  instance: number
  state: number
  mode: string
  remoteip: string
  username: string
}

export interface EMGConnector {
  name: string
  type: string
  protocol: string
  instances: number
  used: number
  state: number
  queuesize: number
  max_queuesize: number
  max_queuesize_soft: number
  qstats1: string
  qstats5: string
  qstats15: string

  children: Array<EMGInstance>
}

export interface QE {
  id: string
  starttime: string
  username: string
  xusername: string
  sourceaddr: string
  destaddr: string
}

const stateName = (state: number) => {
  let name = "UNKNOWN"
  if ((state & 15) < 5)
    name = ["UNKNOWN", "CONNECTED", "IDLE", "ERROR", "DEAD"][state & 7]
  else if (state & 8) name = "BOUND"
  if (state & 0x10)
    // hold
    name = name + "*"
  if (state & 0x1000)
    // force closed
    name = name + "!"
  // console.log("state " + state + " => " + name)
  return name
}

export interface MessageBody {
  id: number
  data: string
}

export interface RouteLog {
  msgid: string
  starttime: string
  endtime: string
  delay: string
  username: string
  sourceaddr: string
  destaddr: string
  status: string
  reason: string
  npdus: string
  charge: string
  inconnector: string
  outconnector: string
  smscid: string
  body: string
  note: string
}

const emptyRLE: RouteLog = {
  msgid: "",
  starttime: "",
  endtime: "",
  delay: "",
  username: "",
  sourceaddr: "",
  destaddr: "",
  status: "",
  reason: "",
  npdus: "",
  charge: "",
  inconnector: "",
  outconnector: "",
  smscid: "",
  body: "",
  note: "",
}

const statusName = (status: number): string => {
  switch (status) {
    case 1:
      return "Delivered"
    case 2:
      return "In process"
    case 3:
      return "Failed"
    case 4:
      return "Deleted"
    case 5:
      return "Expired"
    case 6:
      return "Rejected"
    case 7:
      return "Cancelled"
    case 8:
      return "Queued"
    case 9:
      return "Orphaned"
    case 10:
      return "Relayed"
  }
  return "Unknown"
}

const unhex = (h: string): string => {
  let s = ""
  for (let i = 0; i < h.length; i += 2) {
    s += String.fromCharCode(parseInt(h.substring(i, i + 2), 16))
  }
  return s
}

const not_null = (
  obj: object,
  defaults: { [key: string]: any } = {}
): object => {
  if (!obj) return obj
  let o2: { [fieldKey: string]: any } = {}
  Object.entries(obj).forEach(([key, value]) => {
    o2[key] =
      value !== null ? value : defaults[key] !== undefined ? defaults[key] : ""
  })
  //console.log("not_null returns " + JSON.stringify(o2))
  return o2
}

const time_with_unit = (value: string) => {
  let v: number = parseInt(value) || 0
  let unit = "s"
  if (v > 2 * 60) {
    v = v / 60
    unit = "m"
  }
  if (v > 2 * 60) {
    v = v / 60
    unit = "h"
  }
  return "" + Math.floor(v) + unit
}

export { stateName, unhex, statusName, emptyRLE, not_null, time_with_unit }
