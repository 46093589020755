export class Plugin {
  id: number = 0
  disabled: boolean = false
  name: string = ""
  instances: number = 0
  library: string = ""
  url: string = ""
  config: string = ""
  dbprofile: string = ""
  connectors: string = ""
  note: string = ""
}
