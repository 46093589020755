import axios, { AxiosError } from "axios"
import { ActiveConnector, Connector } from "../models/Connector"
import { InvalidUserError } from "./InvalidUserError"
import { NoConnectionError } from "./NoConnectionError"
import { useQuery } from "react-query"
import { Satpool } from "../models/Satpool"
import { Customer } from "../models/Customer"
import { EmgUser } from "../models/EmgUser"
import User from "../models/User"
import { Route } from "../models/Route"
import { Price } from "../models/Price"
import { MO_Route } from "../models/MO_Route"
import { Plugin } from "../models/Plugin"
import { PBEntry, PhoneBook } from "../models/PhoneBook"
import { MailDomain, MailSender, RecipientAlias } from "../models/MailDomain"
import { LicenseHost, ProductLicense } from "../models/product"
import useLoading from "../components/LoadingHook"
import useDeleteAfterConfirm from "./mutators"
import { Settings } from "../models/Settings"
import { Payment } from "../models/Payment"

const errorCaught = (error: AxiosError) => {
  console.log("got axios response: " + JSON.stringify(error.response))
  if (!error.response) throw error
  switch (error.response.status) {
    case 401:
      throw new InvalidUserError()
    case 500:
      throw new NoConnectionError()
  }
  throw error
}

const fetchField = async (key: string, url: string, field: string = "list") => {
  try {
    console.log("fetching " + key + " from " + url + " returning " + field)
    const result = await axios.get(url)
    console.log("field result", JSON.stringify(result.data, null, 4))
    const defaultValue = field === "list" ? [] : 0
    return result.data[field] || defaultValue
  } catch (error) {
    console.log("fetchField error", JSON.stringify(error))
    // @ts-ignore
    errorCaught(error)
  }
}

const fetchList = async (key: string, url: string) => {
  return fetchField(key, url, "list")
}

const fetchListWithParameters = async (
  key: string,
  url: string,
  params: any
) => {
  try {
    console.log(
      "fetching " +
        key +
        " from " +
        url +
        " with params " +
        JSON.stringify(params)
    )
    const result = await axios.get(url, { params: params })
    return result.data.list
  } catch (error) {
    // @ts-ignore
    errorCaught(error)
  }
}

const fetchEntry = async (key: string, url: string, id: string) => {
  if (id === "0") {
    console.log("create a new " + key)
    switch (key) {
      case "connector":
        return new Connector()
      case "customer":
        return new Customer()
      case "EMGUser":
        return new EmgUser()
      case "mo_route":
        return new MO_Route()
      case "configuredPlugin":
        return new Plugin()
      case "configuredSatpool":
        return new Satpool()
      case "pbentry":
        return new PBEntry()
      case "phonebook":
        return new PhoneBook()
      case "price":
        return new Price()
      case "queue":
        return new ActiveConnector()
      case "route":
        return new Route()
      case "user":
        return { ...new User(), role: "USER" }
      case "maildomain":
        return new MailDomain()
      case "maildomain_sender":
        return new MailSender()
      case "maildomain_recipient":
        return new RecipientAlias()
      case "license":
        const today = new Date().toISOString().substring(0, 10)
        return {
          ...new ProductLicense(),
          valid_until: today,
          support_until: today,
        }
      case "licenseData":
        return new LicenseHost()
    }
  }
  console.log("fetching " + key + " " + id)
  try {
    const response = await axios.get(url + id)
    if (process.env.NODE_ENV !== "test")
      console.log("got: " + JSON.stringify(response.data, null, 4))
    switch (key) {
      case "connector":
        return Connector.includeOptions(response.data)
      case "EMGUser":
        return {
          ...response.data,
          access_new_connector: "",
          access_new_ipstr: "",
          access_new_ipwidth: "",
        }
      case "queue":
        return {
          state: response.data.state,
          queue: response.data.queue,
          queuesize: response.data.queuesize || 0,
        }
    }
    return response.data
  } catch (error) {
    // @ts-ignore
    errorCaught(error)
  }
}

const fetchConfiguredConnectors = async () => {
  try {
    console.log("fetching connectors")
    const result = await axios.get("/api/owner/config/connector/index")
    return result.data.list.map((c: Connector) => {
      return Connector.includeOptions(c)
    })
  } catch (error) {
    // @ts-ignore
    errorCaught(error)
  }
}

export const useConfiguredConnectors = () => {
  const key = "configuredConnectors"
  const list = useQuery(key, fetchConfiguredConnectors)
  return {
    cs: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/config/connector/", key),
  }
}

export const useConfiguredPlugins = () => {
  const key = "configuredPlugins"
  const list = useQuery(key, () =>
    fetchList(key, "/api/owner/config/plugin/index")
  )
  return {
    plugins: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/config/plugin/", key),
  }
}

export const useConfiguredPlugin = (id: string) => {
  const key = "configuredPlugin"
  const entry = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/config/plugin/", id)
  )
  return {
    plugin: entry,
    loading: useLoading(entry),
  }
}

export const useConfiguredSatpools = () => {
  const key = "configuredSatpools"
  const list = useQuery(key, () =>
    fetchList(key, "/api/owner/config/satpool/index")
  )
  return {
    satpools: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/config/satpool/", key),
  }
}

export const useConfiguredSatpool = (id: string) => {
  const key = "configuredSatpool"
  const entry = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/config/satpool/", id)
  )
  return {
    satpool: entry,
    loading: useLoading(entry),
  }
}

// customer
export const useCustomers = (role: string) => {
  const key = "customers"
  const list = useQuery(key, () =>
    fetchList(key, "/api/" + role + "/customer/index")
  )
  return {
    customers: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/customer/", key),
  }
}

export const useCustomer = (id: string) => {
  const key = "customer"
  const entry = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/customer/", id)
  )
  return {
    customer: entry,
    loading: useLoading(entry),
  }
}

// configured connector
export const useConnector = (id: string) => {
  let key = "connector"
  const c = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/config/connector/", id)
  )
  return {
    c: c.data,
    loading: useLoading(c),
  }
}

export const useEMGInfo = () => {
  const key = "EMGInfo"
  return useQuery(key, () => fetchField(key, "/api/emgd/info", "info"))
}

// active connectors
export const useConnectors = () => {
  const key = "connectors"
  const list = useQuery(key, () => fetchList(key, "/api/emgd/connectors"))
  return {
    cs: list,
    loading: useLoading(list),
  }
}

export const useActiveSatpools = () => {
  const key = "activeSatpools"
  const satpools = useQuery(key, () => fetchList(key, "/api/emgd/satpools"))
  return {
    satpools,
    loading: useLoading(satpools),
  }
}

// emg users
export const useEMGUsers = (role: string) => {
  const key = "EMGUsers"
  const list = useQuery([key, role], () =>
    fetchList(key, "/api/" + role + "/emguser/index")
  )
  return {
    emgusers: list.data || [],
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm(role + "/emguser/", key),
  }
}

export const useEMGUsersWithCompany = (role: string = "owner") => {
  const key = "EMGUsersWithCompany"
  const list = useQuery([key, role], () =>
    fetchList(key, "/api/" + role + "/emguser/with_company")
  )
  return {
    accounts: list,
    loading: useLoading(list),
  }
}

// free users
export const useFreeEMGUsers = () => {
  const key = "freeEMGUsers"
  const list = useQuery(key, () => fetchList(key, "/api/owner/emguser/free"))
  return {
    freeUsers: list,
    loading: useLoading(list),
  }
}

export const useEMGUser = (id: string, role: string = "owner") => {
  const key = "EMGUser"
  return useQuery<EmgUser>([key, id, role], () =>
    fetchEntry(key, "/api/" + role + "/emguser/", id)
  )
}

export const useEMGUserMe = () => {
  const key = "EMGUser"
  const result = useQuery<EmgUser>([key], () =>
    fetchEntry(key, "/api/user/emguser/", "me")
  )
  return {
    emguser: (result.data as any)?.emguser,
    loading: useLoading(result),
  }
}

export const useEMGUsage = (username: string) => {
  const key = "EMGUsage"
  const list = useQuery([key, username], () =>
    fetchList(key, "/api/owner/emguser/" + username + "/usage")
  )
  return {
    usage: list.data || [],
    loading: useLoading(list),
  }
}

// web users
export const useUsers = (role: string = "owner") => {
  const key = "users"
  const list = useQuery([key, role], () =>
    fetchList(key, "/api/" + role + "/user/index")
  )
  return {
    users: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm(role + "/user/", key),
  }
}

export const useUser = (role: string = "owner", id: string) => {
  const key = "user"
  const entry = useQuery([key, id, role], () =>
    fetchEntry(key, "/api/" + role + "/user/", id)
  )
  return {
    user: entry,
    loading: useLoading(entry),
  }
}

// routes
export const useRoutes = (params: any) => {
  const key = "routes"
  const list = useQuery([key, params], () =>
    fetchListWithParameters(key, "/api/owner/route/list", params)
  )
  return {
    routes: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/route/", key),
  }
}

export const useRouteCount = () => {
  const key = "routeCount"
  const entry = useQuery(key, () =>
    fetchField(key, "/api/owner/route/count", "count")
  )
  return {
    count: entry,
    loading: useLoading(entry),
  }
}

export const useRoute = (id: string) => {
  const key = "route"
  const entry = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/route/", id)
  )
  return {
    route: entry,
    loading: useLoading(entry),
  }
}

// mo routes
export const useMORoutes = () => {
  const key = "mo_routes"
  const list = useQuery(key, () => fetchList(key, "/api/owner/mo_route/index"))
  return {
    mo_routes: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/mo_route/", key),
  }
}

export const useMORoute = (id: string) => {
  const key = "mo_route"
  const entry = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/mo_route/", id)
  )
  return {
    route: entry,
    loading: useLoading(entry),
  }
}

// prices
export const usePrices = (params: any) => {
  const key = "prices"
  const list = useQuery([key, params], () =>
    fetchListWithParameters(key, "/api/owner/price/list", params)
  )
  return {
    prices: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("owner/price/", key),
  }
}

export const usePriceCount = () => {
  const key = "priceCount"
  const entry = useQuery(key, () =>
    fetchField(key, "/api/owner/price/count", "count")
  )
  return {
    count: entry,
    loading: useLoading(entry),
  }
}

export const usePrice = (id: string) => {
  const key = "price"
  const entry = useQuery([key, id], () =>
    fetchEntry(key, "/api/owner/price/", id)
  )
  return {
    price: entry,
    loading: useLoading(entry),
  }
}

// routelog
export const useRoutelog = (params: any) => {
  const key = "routelog"
  const list = useQuery([key, params], () =>
    fetchListWithParameters(key, "/api/admin/tracking/list", params)
  )
  return {
    entries: list,
    loading: useLoading(list),
  }
}

export const useDlrs = (msgid: string) => {
  const key = "dlrs"
  return useQuery([key, msgid], () =>
    fetchList(key, "/api/admin/tracking/dlrs/" + msgid)
  )
}

// hourly summary
export const useTrafficOverview = (params: any) => {
  const key = "trafficOverview"
  return useQuery([key, params], () =>
    fetchListWithParameters(key, "/api/admin/traffic/overview", params)
  )
}

export const useTrafficDetails = (params: any) => {
  const key = "trafficDetails"
  return useQuery([key, params], () =>
    fetchListWithParameters(key, "/api/admin/traffic/details", params)
  )
}

export const useTrafficPerConnector = (params: any) => {
  const key = "trafficPerConnector"
  return useQuery([key, params], () =>
    fetchListWithParameters(key, "/api/admin/traffic/perconnector", params)
  )
}

// connector status
export const useQueue = (name: string) => {
  const key = "queue"
  const entry = useQuery([key, name], () =>
    fetchEntry(key, "/api/emgd/queue/", name)
  )
  return {
    queue: entry,
    loading: useLoading(entry),
  }
}

// phonebooks
export const usePhoneBooks = () => {
  const key = "phonebooks"
  const list = useQuery(key, () => fetchList(key, "/api/user/pb/index"))
  return {
    phonebooks: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm("user/pb/", key),
  }
}

export const usePhoneBook = (id: string) => {
  const key = "phonebook"
  return useQuery([key, id], () => fetchEntry(key, "/api/user/pb/", id))
}

export const usePBEntry = (id: string) => {
  const key = "pbentry"
  return useQuery([key, id], () => fetchEntry(key, "/api/user/pbentry/", id))
}

// settings
export const useSettings = () => {
  const key = "settings"
  const settings = useQuery<Settings>(key, () =>
    fetchEntry(key, "/api/user/settings/", "index")
  )
  return {
    settings,
    loading: useLoading(settings),
  }
}

// skickasms + messmeup
export const useMailDomains = (role: string) => {
  const key = "maildomain"
  const list = useQuery([key, role], () =>
    fetchList(key, "/api/" + role + "/maildomain/index")
  )
  return {
    maildomains: list,
    loading: useLoading(list),
    deleter: useDeleteAfterConfirm(role + "/maildomain/", key),
  }
}

export const useMailDomain = (role: string, id: string) => {
  const key = "maildomain"
  const entry = useQuery([key, id, role], () =>
    fetchEntry(key, "/api/" + role + "/maildomain/", id)
  )
  return {
    maildomain: entry.data,
    loading: useLoading(entry),
  }
}

export const useMailDomainSender = (
  role: string,
  md_id: string,
  sender_id: number
) => {
  const key = "maildomain_sender"
  const entry = useQuery([key, md_id, sender_id, role], () =>
    fetchEntry(
      key,
      "/api/" + role + "/maildomain/" + md_id + "/sender/",
      sender_id < 0 ? "0" : "" + sender_id
    )
  )
  return {
    sender: entry.data,
    loading: useLoading(entry),
  }
}

export const useMailDomainRecipient = (
  role: string,
  md_id: string,
  recipient_id: number
) => {
  const key = "maildomain_recipient"
  const entry = useQuery([key, md_id, recipient_id, role], () =>
    fetchEntry(
      key,
      "/api/" + role + "/maildomain/" + md_id + "/recipient/",
      recipient_id < 0 ? "0" : "" + recipient_id
    )
  )
  return {
    recipient: entry.data,
    loading: useLoading(entry),
  }
}

export const useLicenses = (role: string) => {
  const key = "licenses"
  const query = useQuery<ProductLicense[]>(["license", "index", role], () =>
    fetchList(key, "/api/" + role + "/license/index")
  )
  return {
    licenses: query.data,
    loading: useLoading(query),
    deleter: useDeleteAfterConfirm(role + "/license/", key),
  }
}

export const useLicense = (role: string, id: string) => {
  const key = "license"
  const entry = useQuery([key, id, role], () =>
    fetchEntry(key, "/api/" + role + "/license/", id)
  )
  return {
    license: entry.data,
    loading: useLoading(entry),
  }
}

export const useLicenseData = (role: string, id: string) => {
  let key = "licenseData"
  const entry = useQuery(["license", id, "data", role], () =>
    fetchEntry(key, "/api/" + role + "/license/data/", id)
  )
  return {
    licenseData: entry,
    loading: useLoading(entry),
  }
}

export const usePayments = (role: string) => {
  const key = "payments"
  const query = useQuery<Payment[]>(["payments", "index", role], () =>
    fetchList(key, "/api/" + role + "/payment/index")
  )
  return {
    payments: query.data,
    loading: useLoading(query),
  }
}
