import React, { useContext, useEffect, useRef, useState } from "react"
import axios from "axios"
import { EmgUser } from "../models/EmgUser"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { FieldArray, Form, Formik } from "formik"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import TextField from "../helpers/TextField"
import SelectNamed from "../helpers/SelectNamed"
import SelectConnector from "../helpers/SelectConnector"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import Checkbox from "../helpers/Checkbox"
import PrimaryButton from "../helpers/PrimaryButton"
import { useActiveSatpools, useConnectors, useEMGUser } from "../helpers/hooks"
import { useMutation, useQueryClient } from "react-query"
import UserContext from "../UserContext"

const EmgUserEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const handleError = useErrorHandler()
  const { cs, loading: cs_loading } = useConnectors()
  const { satpools, loading: satpools_loading } = useActiveSatpools()
  const cache = useQueryClient()
  const appuser = useContext(UserContext)
  const role = appuser.role.toLowerCase()
  const emguser = useEMGUser(id!, role)
  const loading = cs_loading || satpools_loading
  const usernameRef = useRef<HTMLInputElement>(null)
  const [topupValue, setTopupValue] = useState(0)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "EMG Accounts",
        path: routes.admin.root + routes.admin.EmgUsers,
      },
      { text: "EMG Account" },
    ])
  }, [context, location])

  const { mutate: inviteUser } = useMutation(
    async () => {
      await axios.post(
        "/api/" +
          role +
          "/emguser/" +
          id +
          "/resetpassword/" +
          btoa("" + window.location.protocol + "//" + window.location.host)
      )
    },
    {
      //mutationKey: "xxx",
      //onSuccess: (data) => {},
    }
  )

  if (id !== "0" && emguser.isLoading) {
    return <div>Loading.</div>
  }
  if (loading) return <>{loading}</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/" + role + "/emguser/" + emguser.data!.userid,
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
    } finally {
      setSubmitting(false)
      await cache.invalidateQueries("EMGUser")
      if (emguser.data!.userid < 1) navigate("..")
    }
  }

  const sendResetPasswordEmail = async (values: Object, setSubmitting: any) => {
    console.log("send invitation to " + JSON.stringify(values))
    try {
      await inviteUser()
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
    }
    return cache.invalidateQueries("EMGUser")
  }

  const topupBalance = async (values: Object) => {
    console.log("Topup balance with " + topupValue)
    try {
      const response = await axios.post(
        "/api/" +
          role +
          "/emguser/" +
          emguser.data!.userid +
          "/topup/" +
          topupValue
      )
      console.log("response: " + JSON.stringify(response.data))
      await cache.invalidateQueries("EMGUser")
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
    } finally {
      setTopupValue(0)
    }
  }

  const roles = (
    role === "owner" ? [{ text: "Internal", value: "INTERNAL" }] : []
  ).concat([
    { text: "Admin", value: "ADMIN" },
    { text: "User", value: "USER" },
  ])

  const can_reset_password = (values: EmgUser) => {
    // console.log("can reset password? " + JSON.stringify(values))
    if (("" + values.email).length < 1) return false
    return true
  }

  const isOwner = role === "owner"

  const initial_emguser = (user: EmgUser) => {
    return not_null(user, { charge_balance: 0.0, maxsession: "" }) as EmgUser
  }

  return (
    <>
      <Formik
        initialValues={initial_emguser(emguser.data!)}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={"form"}>
            <fieldset>
              <legend>Authentication</legend>
              <TextField
                label={"Username"}
                name={"username"}
                disabled={!isOwner}
                ref={usernameRef}
              />
              <TextField
                label={"Email"}
                name={"email"}
                sublabel={"Required in order to set the password."}
                disabled={!isOwner}
              />

              {/* no, this should be done in a separate dialog
                <div className="row">
                  <label>
                    Password <Field type="password" name="password" />
                  </label>
                </div>*/}

              {/*
                  // Hmm, emgd seems to use sha1, but other variants exist.
                  // A guide is needed on how to generate this value from a given key,
                  // and emgd must support other algorithms (i.e., md5 and sha256).
                  <div className="row">
                    <label>
                      SSH certificate fingerprint
                      <Field type="text" name="cert_fingerprint" size={40} />
                    </label>
                  </div>
                */}

              <SelectNamed label={"Role"} name={"usergroup"} list={roles} />

              <TextField
                label={"Max login sessions"}
                sublabel={"Blank or 0: No limitation"}
                name={"maxsession"}
                disabled={!isOwner}
              />

              <TextField
                label={"Expires on"}
                name={"expires_at"}
                field_type={"date"}
                sublabel={
                  "From this date onwards, logins and message submissions are rejected."
                }
                disabled={!isOwner}
              />
            </fieldset>

            <fieldset>
              <legend>Charges</legend>

              {isOwner && (
                <TextField
                  label={"Charges file"}
                  sublabel={"Filename with charges given special conditions."}
                  name={"charges"}
                  disabled={!isOwner}
                />
              )}

              <div className={"form-group row"}>
                <span className="bold col-sm-3">Account balance: </span>
                <span className={"col-sm-1"}>
                  {" "}
                  {values && values.charge_balance}
                </span>
                {isOwner && (
                  <div>
                    <input
                      type="number"
                      className={"col-sm-5 mr-4"}
                      value={topupValue}
                      onChange={(event) => {
                        setTopupValue(parseInt(event.target.value))
                      }}
                    />
                    <button
                      className={"btn btn-primary"}
                      type={"button"}
                      disabled={!topupValue}
                      onClick={() => {
                        topupBalance(values)
                      }}
                    >
                      Topup
                    </button>
                  </div>
                )}
              </div>

              <div className={"form-group row"}>
                <span className="bold col-sm-3">Lookup credit: </span>
                {values && values.creditslookup}
              </div>

              <Checkbox
                label={"Allow post-paid"}
                name={"allowpostpaid"}
                disabled={!isOwner}
              />

              <TextField
                label={"Account balance postpaid limit"}
                sublabel={"Normally a negative number."}
                name={"charge_balance_postpaid_limit"}
                disabled={!isOwner}
              />

              <TextField
                label={"Pack size"}
                sublabel={"SMS are invoiced in whole multiples of this number."}
                name={"packsize"}
                field_type={"number"}
                min_number={0}
              />
            </fieldset>

            <fieldset>
              <legend>Messaging</legend>
              <TextField
                label={"Force source address"}
                name={"forcesourceaddr"}
                disabled={!isOwner}
              />

              <TextField
                label={"Valid source addresses"}
                sublabel={
                  "One or more addresses, separated by a pipe ('|') character."
                }
                name={"valid_sources"}
                disabled={!isOwner}
              />

              <TextField
                label={"Throughput"}
                sublabel={
                  "In messages per second (mps). Blank means no limitation, 0 means 0.5 mps."
                }
                name={"throughput"}
                disabled={!isOwner}
              />

              <SelectNamed
                label={"Receive only"}
                name={"mode"}
                list={[
                  { text: "No", value: "" },
                  { text: "Yes", value: "RX" },
                ]}
              />

              <TextField
                label={"Minimum validity period"}
                name={"min_vp"}
                field_type={"number"}
                min_number={0}
              />

              <TextField label={"Maximum validity period"} name={"max_vp"} />

              {isOwner && (
                <SelectNamed
                  label={"Use SAT pool"}
                  name={"satpoolcreate"}
                  list={satpools.data.map((obj: { name: string }) => {
                    return { text: obj.name }
                  })}
                  nullText={"Disabled"}
                />
              )}

              {/* use a common connector list, referred to with <datalist>? */}
              {isOwner && (
                <SelectConnector
                  label={"Route"}
                  name={"route"}
                  list={cs.data}
                  nullText={"Default route"}
                />
              )}

              {isOwner && (
                <TextField
                  label={"Routing file"}
                  name={"routing"}
                  sublabel={
                    "Unless this path starts with '/', it is taken to be relative to $EMGDIR."
                  }
                />
              )}

              {isOwner && (
                <SelectConnector
                  label={"Route DLR"}
                  name={"routedlr"}
                  list={cs.data}
                  nullText={"Default route"}
                />
              )}

              {isOwner && (
                <SelectConnector
                  label={"Route SAT"}
                  name={"routesat"}
                  list={cs.data}
                  nullText={"Default route"}
                />
              )}
            </fieldset>

            {isOwner && (
              <fieldset>
                <legend>Site specific fields</legend>
                <TextField label={values.extra1label} name={"extra1"} />
                <TextField label={values.extra2label} name={"extra2"} />
                <TextField label={values.extra3label} name={"extra3"} />
                <TextField label={values.extra4label} name={"extra4"} />
                <TextField label={values.extra5label} name={"extra5"} />
                <TextField label={values.extra6label} name={"extra6"} />
                <TextField label={values.extra7label} name={"extra7"} />
                <TextField label={values.extra8label} name={"extra8"} />
                <TextField label={values.extra9label} name={"extra9"} />
              </fieldset>
            )}

            {emguser.data!.userid > 0 && (
              <fieldset>
                <legend>Access</legend>

                <FieldArray name="access">
                  {(functions) => {
                    return (
                      <div>
                        {isOwner && (
                          <>
                            <SelectConnector
                              label={"Connector"}
                              sublabel={"* = Any connector."}
                              name={"access_new_connector"}
                              list={cs.data}
                              nullText={"*"}
                            />

                            {/* "^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$" */}
                            <TextField
                              label={"IP address"}
                              name={"access_new_ipstr"}
                            />

                            <TextField
                              label={"IP width"}
                              name={"access_new_ipwidth"}
                              field_type={"number"}
                              min_number={0}
                            />

                            <div className="form-group row">
                              <div className={"col-sm-3"} />
                              <div className={"col-auto"}>
                                <button
                                  className={"btn btn-primary"}
                                  type="button"
                                  onClick={() => {
                                    functions.push({
                                      userid: values.userid,
                                      connector: values.access_new_connector,
                                      ipstr: values.access_new_ipstr,
                                      ipwidth: values.access_new_ipwidth,
                                    })
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {(values.access || []).length > 0 && (
                          <table className="table table-sm table-striped">
                            <thead>
                              <tr>
                                <th>Connector</th>
                                <th>IP address</th>
                                <th>IP width</th>
                                {isOwner && <th>Delete</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {(values.access || []).map((access, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{access.connector}</td>
                                    <td>{access.ipstr}</td>
                                    <td>{access.ipwidth}</td>
                                    {isOwner && (
                                      <td>
                                        <img
                                          src="/delete.png"
                                          alt={
                                            "delete access from " +
                                            access.connector
                                          }
                                          onClick={() =>
                                            functions.remove(index)
                                          }
                                        />
                                      </td>
                                    )}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )
                  }}
                </FieldArray>
              </fieldset>
            )}

            <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
      {id !== "0" && can_reset_password(emguser.data!) && (
        <Formik
          initialValues={initial_emguser(emguser.data!)}
          onSubmit={async (values, { setSubmitting }) => {
            return sendResetPasswordEmail(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form className={"form"}>
              <PrimaryButton
                text={"Send 'reset password' email"}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default EmgUserEdit
