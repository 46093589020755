import React from "react"
import { Link } from "react-router-dom"
import { ProductLicense } from "../models/product"
import routes from "../routes"

interface LinkedLicenseProps {
  license: ProductLicense
}

const LinkedLicense = (props: LinkedLicenseProps) => {
  const license = props.license
  return (
    <Link to={routes.admin.root + routes.admin.Licenses + "/" + license.id}>
      {license.licno}
    </Link>
  )
}

export default LinkedLicense
