import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react"
import { Field, Form, Formik } from "formik"
import { not_null } from "../helpers/emg"
import BreadcrumbContext from "../BreadcrumbContext"
import { useLocation } from "react-router-dom"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import PrimaryButton from "../helpers/PrimaryButton"
import { HourlySummary } from "../models/HourlySummary"
import { useEMGUsersWithCompany, useTrafficOverview } from "../helpers/hooks"
import UserContext from "../UserContext"
import { numberFormatter, percentFormatter } from "../helpers/formatters"

enum SF_Interval {
  ByMonth = "ByMonth",
  ByMonthInYear = "ByMonthInYear",
  ByDay = "ByDay",
}

interface SelectionFields {
  username: string
  interval_type: SF_Interval
  year: string
  month: string
}

const TrafficOverview: FunctionComponent = () => {
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { accounts, loading } = useEMGUsersWithCompany(role)

  const zeropad = (v: number) => {
    const s = "0" + v
    const l = s.length
    return s.substring(l - 2)
  }

  const now = new Date()
  const thisYear = now.getFullYear()
  const thisMonth = "" + thisYear + "-" + zeropad(now.getMonth() + 1)

  const [params, setParams] = useState({
    username: "",
    interval_type: SF_Interval.ByMonth,
    year: "" + thisYear,
    month: thisMonth,
  })
  const entries = useTrafficOverview(params)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [{ text: "Traffic overview" }])
  }, [context, location])

  if (loading) return <>{loading}</>

  const selectableYears = () => {
    return [thisYear, thisYear - 1, thisYear - 2, thisYear - 3]
  }

  const selectableMonths = () => {
    return selectableYears()
      .map((year) => {
        let ms = []
        for (let m = 12; m > 0; --m) {
          ms.push(m)
        }
        return ms.map((m) => {
          return "" + year + "-" + zeropad(m)
        })
      })
      .flat()
      .filter((m) => {
        return m <= thisMonth
      })
      .slice(0, 30)
  }

  const addZeroes = (
    list: Array<HourlySummary>,
    interval_type: SF_Interval,
    year: string,
    month: string
  ) => {
    let new_list: Array<HourlySummary> = []
    let date = new Date(month + "-01")
    const m = date.getMonth()
    switch (interval_type) {
      case SF_Interval.ByDay:
        for (let d = 1; d <= 31; ++d) {
          date = new Date(month + "-" + d)
          if (date.getMonth() !== m) break
          new_list[d - 1] = new HourlySummary(month + "-" + zeropad(d))
        }
        list.forEach((entry) => {
          const d2 = new Date(entry.day)
          new_list[d2.getDate() - 1] = entry
        })
        break
      case SF_Interval.ByMonthInYear:
        for (let d = 1; d <= 12; ++d) {
          new_list[d - 1] = new HourlySummary(year + "-" + zeropad(d))
        }
        list.forEach((entry) => {
          new_list[parseInt(entry.month.substring(6))] = entry
        })
        break
      case SF_Interval.ByMonth:
        list.forEach((entry) => {
          new_list[parseInt(entry.month.replace("-", "")) - 200000] = entry
        })
        break
    }
    return new_list
  }

  return (
    <>
      <h1>Traffic overview</h1>
      <Formik
        initialValues={not_null(params) as SelectionFields}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          setParams({
            ...values,
            month:
              values.interval_type === SF_Interval.ByMonth
                ? thisMonth
                : values.month,
          })
        }}
      >
        {({ values, isSubmitting }) => (
          <div className={"card"}>
            <div className={"card-header text-center"}>
              <legend>Selection</legend>
            </div>
            <div className={"card-body"}>
              <Form className={"form"}>
                <SelectEMGAccount
                  label={"Account"}
                  name={"username"}
                  accounts={(accounts.isSuccess && accounts.data) || []}
                  nullText={"Any"}
                  by_name={true}
                  disabled={!accounts.isSuccess}
                />

                <div className={"form-check-inline ml-2 col-12"}>
                  <div className={"col-3"}>
                    <Field
                      name="interval_type"
                      type="radio"
                      value={SF_Interval.ByMonth}
                      className={"form-check-input position-static"}
                    />
                    <label className={"col-form-label"}>Last 12 months</label>
                  </div>
                </div>

                <div className={"form-check-inline ml-2 col-12"}>
                  <div className={"col-3"}>
                    <Field
                      name="interval_type"
                      type="radio"
                      value={SF_Interval.ByMonthInYear}
                      className={"form-check-input position-static"}
                    />
                    <label className={"col-form-label"}>
                      By month, for year
                    </label>
                  </div>
                  <Field
                    className={"form-control-sm"}
                    name={"year"}
                    as={"select"}
                    disabled={
                      values.interval_type !== SF_Interval.ByMonthInYear
                    }
                  >
                    {selectableYears().map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      )
                    })}
                  </Field>
                </div>

                <div className={"form-check-inline ml-2 col-12"}>
                  <div className={"col-3"}>
                    <Field
                      name="interval_type"
                      type="radio"
                      value={SF_Interval.ByDay}
                      className={"form-check-input position-static"}
                    />
                    <label className={"col-form-label"}>
                      By day, for month
                    </label>
                  </div>
                  <Field
                    className={"form-control-sm"}
                    name={"month"}
                    as={"select"}
                    disabled={values.interval_type !== SF_Interval.ByDay}
                  >
                    {selectableMonths().map((month, index) => {
                      return (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      )
                    })}
                  </Field>
                </div>

                <div className={"mt-3 col-12"}>
                  <PrimaryButton text={"Update"} isSubmitting={isSubmitting} />
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>

      <section className={"pt-2"}>
        <table className={"table table-sm table-responsive"}>
          <thead>
            <tr>
              <th />
              <th>Total</th>
              <th>Charge</th>
              <th>Cost</th>
              <th>Margin</th>
              <th className={"text-right"}>%</th>
            </tr>
          </thead>
          <tbody>
            {entries.data && entries.data.length > 0 ? (
              <>
                {addZeroes(
                  entries.data,
                  params.interval_type,
                  params.year,
                  params.month
                ).map((entry: HourlySummary, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {entry.month}
                        {entry.day}
                      </td>
                      <td className={"text-right"}>{entry.xcount}</td>
                      <td className={"text-right"}>{entry.charge}</td>
                      <td className={"text-right"}>{entry.charge_cost}</td>
                      <td className={"text-right"}>
                        {numberFormatter.format(HourlySummary.margin(entry))}
                      </td>
                      <td className={"text-right"}>
                        {percentFormatter.format(
                          HourlySummary.margin_pc(entry)
                        )}
                      </td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr>
                <td colSpan={12}>No entries found</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </>
  )
}

export default TrafficOverview
