import React, { useContext, useEffect } from "react"
import axios from "axios"
import { Connector, ConnectorOption } from "../../models/Connector"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { not_null } from "../../helpers/emg"
import { useErrorHandler } from "react-error-boundary"
import routes from "../../routes"
import BreadcrumbContext from "../../BreadcrumbContext"
import { updateBreadcrumbs } from "../../helpers/updateBreadcrumbs"
import { Field, FieldArray, Form, Formik } from "formik"
import TextField from "../../helpers/TextField"
import Checkbox from "../../helpers/Checkbox"
import SelectConnector from "../../helpers/SelectConnector"
import SelectNamed from "../../helpers/SelectNamed"
import PrimaryButton from "../../helpers/PrimaryButton"
import { useConfiguredConnectors, useConnector } from "../../helpers/hooks"
import { useQueryClient } from "react-query"

const protocols = [
  "mgp",
  "http",
  "https",
  "http-dynamic",
  "http-json",
  "smtp",
  "ucp",
  "cimd2",
  "cimd2-smsci",
  "smpp",
  "ois",
  "gsm",
  "ebe",
  "mm1",
  "eaif",
  "mm7",
  "pap",
  "cmgpap",
  "dll",
  "route",
]
  .sort()
  .map((s) => {
    return { text: s.toUpperCase() }
  })

const ConnectorEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const handleError = useErrorHandler()
  const cache = useQueryClient()

  const { c, loading: c_loading } = useConnector(id!)
  const { cs, loading: cs_loading } = useConfiguredConnectors()
  const loading = c_loading || cs_loading

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "Connectors",
        path:
          routes.owner.root +
          routes.owner.config.root +
          routes.owner.config.Connectors,
      },
      { text: "Connector" },
    ])
  }, [context, location])

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving connector " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/owner/config/connector/" + id,
        Connector.toServer(values as Connector)
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      await cache.invalidateQueries("connector")
      setSubmitting(false)
      if (c.data && c.data.id < 1)
        navigate(
          routes.owner.root +
            routes.owner.config.root +
            routes.owner.config.Connectors
        )
    }
  }

  if (loading) return <>{loading}</>
  return (
    <>
      <Formik
        initialValues={{ type: "INCOMING", ...not_null(c) } as Connector}
        onSubmit={(values, { setSubmitting }) =>
          submitData(values, setSubmitting)
        }
      >
        {({ isSubmitting, values }) => (
          <Form className={"form"}>
            <fieldset>
              <legend>Main options</legend>

              <TextField label="Name" name="name" />
              <Checkbox label={"Disabled"} name={"disabled"} />
              <Checkbox label={"Virtual"} name={"virtual"} />
              <SelectConnector
                label={"Inherit from"}
                name={"inherit"}
                list={cs.data}
                nullText={"No inherit"}
              />
              <SelectNamed
                label={"Protocol"}
                name={"protocol"}
                list={protocols}
              />
              <TextField
                label={"Address"}
                name={"address"}
                sublabel={"Syntax: ip:port or |ip1:port1|ip2:port2|...|."}
              />
              <TextField
                label={"Keepalive"}
                name={"keepalive"}
                field_type={"number"}
                min_number={0}
              />
              <TextField
                label={"Idle timeout"}
                name={"idletimeout"}
                field_type={"number"}
                min_number={0}
              />
            </fieldset>

            <fieldset>
              <hr />
              <SelectNamed
                label={"Type"}
                name={"type"}
                list={[{ text: "INCOMING" }, { text: "OUTGOING" }]}
              />
              <TextField
                label={"Instances"}
                sublabel={
                  values.type === "OUTGOING"
                    ? "Number of connections to initiate. Normally you would start with 1."
                    : "Number of simultaneous connections that can be handled. 10 may be a good starting point."
                }
                name={"instances"}
                field_type={"number"}
                min_number={0}
              />
              {values.type === "OUTGOING" ? (
                <>
                  <TextField label={"Username"} name={"username"} />
                  <TextField label={"Password"} name={"password"} />
                  <Checkbox label={"Static"} name={"static"} />
                </>
              ) : (
                <>
                  <TextField
                    label={"User file"}
                    name={"users"}
                    sublabel={"File with credentials, relative to $EMGDIR"}
                  />
                  <TextField
                    label={"User DB"}
                    name={"userdb"}
                    sublabel={
                      'Database profile to use for authenticating users. Normally "emg".'
                    }
                  />
                </>
              )}
            </fieldset>

            <hr />
            <TextField label={"Note"} name={"note"} tag={"textarea"} rows={2} />

            <fieldset>
              <legend>Other options</legend>
              <FieldArray name={"options"}>
                {(functions) => {
                  return (
                    <>
                      <table className={"table table-sm"}>
                        <thead>
                          <tr>
                            <th>Disabled</th>
                            <th>Keyword</th>
                            <th>Value</th>
                            <th>Note</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.options.map(
                            (co0: ConnectorOption, index: number) => {
                              const co: ConnectorOption = co0 as ConnectorOption
                              /* console.log(
                                "extra option at index",
                                index,
                                ":",
                                JSON.stringify(co)
                              ) */
                              return (
                                <tr key={index}>
                                  <td>
                                    <Field
                                      type={"checkbox"}
                                      name={`options.${index}.disabled`}
                                    />
                                  </td>
                                  <td>
                                    <Field name={`options.${index}.keyname`} />
                                  </td>
                                  <td>
                                    <Field name={`options.${index}.value`} />
                                  </td>
                                  <td>
                                    <Field name={`options.${index}.note`} />
                                  </td>
                                  <td>
                                    {co.keyname.length > 0 && (
                                      <img
                                        src="/delete.png"
                                        alt={"delete option " + co.keyname}
                                        onClick={() => functions.remove(index)}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    </>
                  )
                }}
              </FieldArray>{" "}
            </fieldset>

            <PrimaryButton text={"Save"} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ConnectorEdit
