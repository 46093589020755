import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Field, Form, Formik } from "formik"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useCustomers,
  useLicense,
  useLicenseData,
  useLicenses,
} from "../helpers/hooks"
import UserContext from "../UserContext"
import { LicenseHost, LicenseType, ProductLicense } from "../models/product"
import SelectNamed from "../helpers/SelectNamed"
import { Customer } from "../models/Customer"
import { useQueryClient } from "react-query"
import LinkedLicense from "../components/LinkedLicense"

const LicenseEdit = () => {
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const role = user.role.toLowerCase()
  const { license, loading: license_loading } = useLicense(role, id!)
  const handleError = useErrorHandler()
  const { customers, loading: customers_loading } = useCustomers(role)
  const cache = useQueryClient()
  const [selectedHostId, setHostId] = useState(0)
  const { licenseData, loading: host_loading } = useLicenseData(
    role,
    "" + selectedHostId
  )
  const { licenses, loading: licenses_loading } = useLicenses(role)
  const loading = license_loading || customers_loading || licenses_loading
  const [prevItem, setPrevItem] = useState(-1)
  const [nextItem, setNextItem] = useState(-1)

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "Licenses",
        path: routes.admin.root + routes.admin.Licenses,
      },
      { text: "License" },
    ])
  }, [context, location])

  useEffect(() => {
    if (licenses_loading || !licenses) return
    const index = licenses!.findIndex((item: ProductLicense) => {
      return "" + (item as ProductLicense).id === id
    })
    setPrevItem(index - 1)
    const n = licenses!.length
    setNextItem(index < n - 1 ? index + 1 : -1)
  }, [licenses_loading, licenses, id])

  // Seriously, why do we need to do this?
  useEffect(() => setHostId(0), [id])

  if (loading) return <>{loading}</>
  if (!license || !licenses || !customers) return <>Loading...</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/" + role + "/license/" + id,
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      if (id === "0") navigate("..")
    }
    return Promise.all([cache.invalidateQueries("license")])
  }

  const addHost = async (values: Object, setSubmitting: any) => {
    console.log("add host " + JSON.stringify(values))
    try {
      const response = await axios.post(
        "/api/" + role + "/license/" + id + "/host",
        values
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      cache.invalidateQueries("license")
    }
  }

  /*
  const extendLicenseForHost = async (hostid: number) => {
    console.log("extendLicenseForHost")
    try {
      const response = await axios.post(
        "/api/" + role + "/license/" + hostid + "/extend_host"
      )
      console.log("response: " + JSON.stringify(response.data))
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      handleError(error)
    } finally {
      cache.invalidateQueries("license")
    }
  }
  */

  const extendLicense = async (extension: number) => {
    console.log("Extend:", extension)
    try {
      const response = await axios.post(
        "/api/" + role + "/license/" + id + "/extend/" + extension
      )
      console.log(
        "response after extending license " +
          extension +
          ": " +
          JSON.stringify(response.data, null, 4)
      )
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    }
    return Promise.all([cache.invalidateQueries("license")])
  }

  const invalid_date_format = (date: string) => {
    const r = date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)
    // console.log("Check format on `" + date + "' returns " + JSON.stringify(r))
    return r === null
  }

  const models = [
    { text: LicenseType.Perpetual, value: LicenseType.Perpetual },
    { text: LicenseType.RentToBuy, value: LicenseType.RentToBuy },
    { text: LicenseType.PureRental, value: LicenseType.PureRental },
    { text: LicenseType.OnTap, value: LicenseType.OnTap },
  ]

  const extensions = [
    { text: "─ Select ─", value: -1 },
    { text: "1 month", value: 1 },
    { text: "3 months", value: 3 },
    { text: "12 months", value: 12 },
  ]

  const licdata =
    selectedHostId > 0 && !host_loading && licenseData.data
      ? licenseData.data
      : {}

  const customer_list = customers_loading
    ? []
    : [{ name: "─ Select ─", id: -1 }, ...customers.data]

  const versions = (product: string) => {
    if (product === "EMG") {
      return [
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
      ]
    }
    if (product === "Form2PDF") {
      return [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
      ]
    }
    return []
  }

  const throughputs = () => {
    return [
      { text: "0.5", value: "0" },
      { text: "1", value: "1" },
      { text: "5", value: "5" },
      { text: "10", value: "10" },
      { text: "20", value: "20" },
      { text: "40", value: "40" },
      { text: "60", value: "60" },
      { text: "80", value: "80" },
      { text: "100", value: "100" },
      { text: "140", value: "140" },
      { text: "9999", value: "9999" },
    ]
  }

  const duplicateLicense = async () => {
    console.log("Duplicating the license")
    try {
      const response = await axios.post("/api/" + role + "/license/0", {
        ...license,
        id: 0,
        licno: license.licno + "-2",
      })
      console.log("response: " + JSON.stringify(response.data))
      if (response.data && response.data.id) {
        const new_id = response.data.id
        navigate("../" + new_id)
      }
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
    }
    return Promise.all([cache.invalidateQueries("license")])
  }

  return (
    <>
      {(user.role === "OWNER" && <h1>Edit License</h1>) || (
        <h1>Show License</h1>
      )}
      <p>
        {prevItem > -1 && (
          <span>
            Previous: <LinkedLicense license={licenses![prevItem]} />{" "}
          </span>
        )}
        {nextItem > -1 && (
          <span>
            Next: <LinkedLicense license={licenses![nextItem]} />
          </span>
        )}
        {user.role === "OWNER" && (
          <span className={"col-sm-3"}>
            <button
              type={"button"}
              className={"btn btn-sm btn-info"}
              onClick={async (e) => {
                await duplicateLicense()
              }}
            >
              Duplicate
            </button>
          </span>
        )}
      </p>
      <hr />
      {(user.role === "OWNER" && (
        <Formik
          enableReinitialize
          initialValues={not_null(license) as ProductLicense}
          onSubmit={async (values, { setSubmitting }) => {
            return submitData(values, setSubmitting)
          }}
        >
          {({ isSubmitting, values, setFieldValue, status, setStatus }) => {
            if (
              status &&
              status.resetDates &&
              values &&
              license &&
              values.support_until !== license.support_until
            ) {
              setFieldValue("valid_until", license.valid_until)
              setFieldValue("support_until", license.support_until)
              setStatus({ resetDates: false })
            }
            // console.log("Customer: " + JSON.stringify(values.customer_id))
            // console.log("Company: " + JSON.stringify(values.company))
            if (values.customer_id > -1 && ("" + values.company).length < 1) {
              const customer = customer_list.find((entry: Customer) => {
                // console.log("Examine " + JSON.stringify(entry, null, 4))
                return "" + entry.id === "" + values.customer_id
              })
              // console.log("Found " + JSON.stringify(customer, null, 4))
              if (customer) setFieldValue("company", customer.name)
            }
            if (values.name === "EMG") {
              if (values.version < "5") setFieldValue("version", 7)
            }
            if (values.name === "EMGLOAD") {
              if (values.license_type !== LicenseType.PureRental)
                setFieldValue("license_type", LicenseType.PureRental)
              if (values.mps !== 9999) setFieldValue("mps", 9999)
              if (values.version !== "2") setFieldValue("version", "2")
            }
            if (values.name === "Form2PDF") {
              if (values.version > "4") setFieldValue("version", 4)
            }
            return (
              <Form className={"form"}>
                <SelectNamed
                  label={"Customer"}
                  name={"customer_id"}
                  list={customer_list.map((entry: Customer) => {
                    return {
                      text: entry.name,
                      value: entry.id,
                    }
                  })}
                />

                <TextField label={"Company name"} name={"company"} />
                <TextField label={"License no"} name={"licno"} />
                <SelectNamed
                  label={"Product"}
                  name={"name"}
                  nullText={"Select"}
                  list={[
                    { text: "EMG", value: "EMG" },
                    { text: "EMGLOAD", value: "EMGLOAD" },
                    { text: "Form2PDF", value: "Form2PDF" },
                  ]}
                />
                {values.name !== "EMGLOAD" && (
                  <SelectNamed
                    label={"Version"}
                    name={"version"}
                    nullText={"Select"}
                    list={versions(values.name)}
                  />
                )}
                {values.name === "EMG" && (
                  <SelectNamed
                    label={"Payment model"}
                    name={"license_type"}
                    list={models}
                  />
                )}
                {values.name === "EMG" && (
                  <SelectNamed
                    label={"MPS"}
                    name={"mps"}
                    list={throughputs()}
                    nullText={"Select"}
                  />
                )}
                <TextField
                  label={"Comment"}
                  name={"note"}
                  rows={3}
                  tag={"textarea"}
                />
                <fieldset>
                  <legend>Validity</legend>
                  {values.license_type !== LicenseType.Perpetual &&
                    (values.name === "EMG" || values.name === "EMGLOAD") && (
                      <TextField
                        label={"Valid until"}
                        name={"valid_until"}
                        field_type={"date"}
                      />
                    )}
                  {values.name !== "EMGLOAD" && (
                    <TextField
                      label={"Support until"}
                      name={"support_until"}
                      field_type={"date"}
                    />
                  )}
                  <div className={"row mb-2"}>
                    <div className={"col-sm-3"}>&nbsp;</div>
                    {values.license_type !== LicenseType.Perpetual && (
                      <>
                        <div className={"col-3"}>
                          <button
                            className={"btn btn-sm btn-info"}
                            disabled={invalid_date_format(
                              "" + values.valid_until
                            )}
                            onClick={(e) => {
                              setFieldValue("support_until", values.valid_until)
                              e.preventDefault()
                            }}
                          >
                            Set support until = valid until
                          </button>
                        </div>
                        <div className={"col-1"}>&nbsp;</div>
                      </>
                    )}
                    {id !== "0" && (
                      <>
                        <div className={"col-2"}>
                          <Field
                            className={"form-control-sm"}
                            as="select"
                            name={"extension"}
                          >
                            {extensions.map((obj, index) => {
                              return (
                                <option key={index} value={obj.value}>
                                  {obj.text}
                                </option>
                              )
                            })}
                          </Field>
                        </div>
                        <div className={"col-3"}>
                          <button
                            type={"button"}
                            className={"btn btn-sm btn-info"}
                            disabled={values.extension === undefined}
                            onClick={async (e) => {
                              await extendLicense(values.extension)
                              setStatus({ resetDates: true })
                            }}
                          >
                            Extend
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </fieldset>
                <PrimaryButton
                  text={"Save"}
                  disabled={values.licno === ""}
                  isSubmitting={isSubmitting}
                />
              </Form>
            )
          }}
        </Formik>
      )) || (
        <>
          <div className={"row"}>
            <label className={"col-sm-2"}>Customer</label>
            <div className={"col"}>{customer_list[1].name}</div>
          </div>
          <div className={"row"}>
            <label className={"col-sm-2"}>Company name</label>
            <div className={"col"}>{license.company}</div>
          </div>
          <div className={"row"}>
            <label className={"col-sm-2"}>Product</label>
            <div className={"col"}>{license.name}</div>
          </div>
          {license.name !== "EMGLOAD" && (
            <div className={"row"}>
              <label className={"col-sm-2"}>Version</label>
              <div className={"col"}>{license.version}</div>
            </div>
          )}
          {license.name === "EMG" && (
            <>
              <div className={"row"}>
                <label className={"col-sm-2"}>Payment model</label>
                <div className={"col"}>{license.license_type}</div>
              </div>
              <div className={"row"}>
                <label className={"col-sm-2"}>MPS</label>
                <div className={"col"}>{license.mps}</div>
              </div>
            </>
          )}
          {license.license_type !== LicenseType.Perpetual &&
            (license.name === "EMG" || license.name === "EMGLOAD") && (
              <div className={"row"}>
                <label className={"col-sm-2"}>Valid until</label>
                <div className={"col"}>{license.valid_until}</div>
              </div>
            )}
          {license.name !== "EMGLOAD" && (
            <div className={"row"}>
              <label className={"col-sm-2"}>Support until</label>
              <div className={"col"}>{license.support_until}</div>
            </div>
          )}
        </>
      )}

      <h2>Hosts</h2>
      {user.role === "OWNER" && (
        <Formik
          initialValues={{ hostid: "" }}
          onSubmit={async (values, { setSubmitting }) => {
            return addHost(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form className={"form"}>
              <TextField label={"Hostid"} name={"hostid"} />
              <PrimaryButton text={"Add host"} isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
      {(license.hosts || []).length > 0 && (
        <table className={"table table-sm table-hover"}>
          <thead>
            <tr>
              <th>Hostid</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {license.hosts.map((host: LicenseHost, index: number) => {
              return (
                <tr key={index}>
                  <td>{host.hostid}</td>
                  <td>
                    <button
                      className={"btn btn-sm btn-info"}
                      onClick={() => {
                        setHostId(host.id)
                      }}
                    >
                      Show license file
                    </button>
                  </td>
                  {/*<td>
                      <button
                        className={"btn btn-sm btn-info"}
                        onClick={() => {
                          extendLicenseForHost(host.id)
                        }}
                      >
                        Extend
                      </button>
                    </td>*/}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {selectedHostId > 0 && !host_loading && licdata && (
        <>
          <hr />
          <p>
            {(licdata.hostid || licdata.activation) && (
              <>
                HOSTID={licdata.hostid || ""}
                <br />
              </>
            )}
            COMPANY={licdata.company}
            <br />
            {licdata.telno && (
              <>
                TELNO={licdata.telno}
                <br />
              </>
            )}
            {licdata.serial && (
              <>
                SERIAL={licdata.serial}
                <br />
              </>
            )}
            {licdata.licensedata && (
              <>
                LICENSEDATA={licdata.licensedata}
                <br />
              </>
            )}
            {licdata.activation && (
              <>
                ACTIVATION={licdata.activation}
                <br />
              </>
            )}
            {licdata.license && <>LICENSE={licdata.license}</>}
          </p>
        </>
      )}
    </>
  )
}

export default LicenseEdit
