import React from "react"
import { Link } from "react-router-dom"
import { EmgUser } from "../models/EmgUser"
import routes from "../routes"

interface LinkedEMGUserProps {
  user?: EmgUser
}

const LinkedEMGUser = (props: LinkedEMGUserProps) => {
  const user = props.user
  if (user) {
    return (
      <Link to={routes.admin.root + routes.admin.EmgUsers + "/" + user.userid}>
        {user.username}
      </Link>
    )
  }
  return <>&nbsp;</>
}

export default LinkedEMGUser
