import React from "react"

import { HourlySummary } from "../models/HourlySummary"
import { numberFormatter, percentFormatter } from "./formatters"

const MessageCountSums = (props: {
  entries: Array<HourlySummary>
  spaced?: boolean
}) => {
  const summer = (acc: number, curr: number) => {
    return parseInt("" + acc) + parseInt("" + curr)
  }
  const decimal_summer = (acc: number, curr: number) => {
    return parseFloat("" + acc) + parseFloat("" + curr)
  }
  const sum_totals =
    props.entries.map((e) => e.msg_count).reduce(summer, 0) || 1
  const sum_ok = props.entries.map((e) => e.msg_count_ok).reduce(summer, 0)
  const sum_relayed = props.entries
    .map((e) => e.msg_count_relayed)
    .reduce(summer, 0)
  const sum_failed = props.entries
    .map((e) => HourlySummary.msg_count_failed(e))
    .reduce(summer, 0)
  const sum_charge = props.entries
    .map((e) => e.charge * e.msg_count)
    .reduce(decimal_summer, 0.0)
  return (
    <>
      <td className={"text-right table-success"}>
        <strong>{numberFormatter.format(sum_ok)}</strong>
      </td>
      <td className={"text-right table-success"}>
        <strong>{percentFormatter.format(sum_ok / sum_totals)}</strong>
      </td>
      <td className={"text-right table-warning"}>
        <strong>{numberFormatter.format(sum_relayed)}</strong>
      </td>
      <td className={"text-right table-warning"}>
        <strong>{percentFormatter.format(sum_relayed / sum_totals)}</strong>
      </td>
      <td className={"text-right table-danger"}>
        <strong>{numberFormatter.format(sum_failed)}</strong>
      </td>
      <td className={"text-right table-danger"}>
        <strong>{percentFormatter.format(sum_failed / sum_totals)}</strong>
      </td>
      <td className={"text-right"}>
        <strong>{sum_totals}</strong>
      </td>
      {props.spaced && <td> </td>}
      <td className={"text-right"}>
        <strong>{sum_charge}</strong>
      </td>
    </>
  )
}

export default MessageCountSums
