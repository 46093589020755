import React from "react"
import { Link } from "react-router-dom"

interface AddNewLinkProps {
  path: string
}

const AddNewLink = (props: AddNewLinkProps) => {
  return <Link to={props.path.replace(":id", "0")}>Add new &raquo;</Link>
}

export default AddNewLink
